import { SkillDto } from '../../functions/src/types/skill';
export { SkillType } from '../../functions/src/types/skill';
export interface Skill {
  name: string;
  level: number;
  code?: string;
}

export const fromDto = (dto: SkillDto): Skill => ({
  ...dto,
});

export const toDto = (skill: Skill): SkillDto => ({ ...skill });
