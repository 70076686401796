import { AnyKeyObject } from '.';
import { Timestamp } from '../firebase';

export interface MessageDto {
  id: string;
  sentAt: Timestamp;
  text: string;
  senderId: string;
  isFirstMessage: boolean;
  userIds: string[];
  seen: boolean;
  notifiedRecipient: boolean;
  matchId?: string;
}

export const fromData = (data: AnyKeyObject) => {
  const message: MessageDto = {
    id: data.id,
    sentAt: data.sentAt,
    text: data.text,
    senderId: data.senderId,
    isFirstMessage: data.isFirstMessage,
    userIds: data.userIds,
    seen: data.seen,
    notifiedRecipient: data.notifiedRecipient,
    matchId: data.matchId,
  };
  return message;
};

export const fromFirestore = (doc: any) => {
  const data = doc.data();
  if (!doc || !doc.id || !data)
    throw new Error('Could not parse message entry');

  const matchId = doc.ref.parent.parent?.id;
  return fromData({ ...data, id: doc.id, matchId });
};
