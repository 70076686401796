import {
  auth,
  candidatesCollection,
  chatPresenceCollection,
  DocumentData,
  FieldValue,
  matchesCollection,
  positionsCollection,
  QuerySnapshot,
} from '@/firebase';
import { fromFirestore } from '../../../functions/src/types/match';
import { Match } from '@/types/match';

export const listForCandidate = async (
  candidateId: string,
  section: string,
  lastVisible?: Match
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');

  // TODO: refactor to use Algolia index to avoid complexity to make the Firestore query work
  let query = matchesCollection
    .where('candidate.id', '==', candidateId)
    .where('opponentRemoved', '==', false);

  switch (section) {
    case 'Archive':
      query = query.where(
        `userStatus.archived.true`,
        'array-contains',
        user.uid
      );
      break;
    case 'Likes':
      query = query
        .where(`userStatus.liked.true`, 'array-contains', user.uid)
        .where('startedCommunication', '==', false);
      break;
    default:
      query = query
        .where(`userStatus.archived.false`, 'array-contains', user.uid)
        .where('startedCommunication', '==', false);
      break;
  }
  query = query.orderBy('score.percentage', 'desc').orderBy('id', 'desc');

  if (lastVisible) {
    const doc = await matchesCollection.doc(lastVisible.id).get();
    query = query.startAfter(doc);
  }
  query = query.limit(18);

  const result = await query
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(fromFirestore));

  return result.filter((item) => {
    if (section !== 'Likes') {
      return true;
    }

    return item.userStatus.archived.false.includes(user.uid);
  });
};

export const listForPosition = async (
  positionId: string,
  section: string,
  lastVisible?: Match
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');

  // TODO: refactor to use Algolia index to avoid complexity to make the Firestore query work
  let query = matchesCollection
    .where('position.id', '==', positionId)
    .where('opponentRemoved', '==', false);

  switch (section) {
    case 'Archive':
      query = query.where(
        `userStatus.archived.true`,
        'array-contains',
        user.uid
      );
      break;
    case 'Likes':
      query = query
        .where(`userStatus.liked.true`, 'array-contains', user.uid)
        .where('startedCommunication', '==', false);
      break;
    default:
      query = query
        .where(`userStatus.archived.false`, 'array-contains', user.uid)
        .where('startedCommunication', '==', false);
      break;
  }
  query = query.orderBy('score.percentage', 'desc').orderBy('id', 'desc');

  if (lastVisible) {
    const doc = await matchesCollection.doc(lastVisible.id).get();
    query = query.startAfter(doc);
  }
  query = query.limit(18);

  const result = await query
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(fromFirestore));

  return result.filter((item) => {
    if (section !== 'Likes') {
      return true;
    }

    return item.userStatus.archived.false.includes(user.uid);
  });
};

export const getMatchesWithConversationForCandidate = (candidateId: string) => {
  const user = auth.currentUser;
  return matchesCollection
    .where('candidate.id', '==', candidateId)
    .where('userIds', 'array-contains', user?.uid)
    .where('startedCommunication', '==', true)
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(fromFirestore));
};

export const getMatchesWithConversationForPosition = (positionId: string) => {
  const user = auth.currentUser;
  return matchesCollection
    .where('position.id', '==', positionId)
    .where('userIds', 'array-contains', user?.uid)
    .where('startedCommunication', '==', true)
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(fromFirestore));
};

export const get = (matchId: string) =>
  matchesCollection.doc(matchId).get().then(fromFirestore);

export const markConversationStartForMatch = (matchId: string) =>
  matchesCollection
    .doc(matchId)
    .update({
      startedCommunication: true,
    })
    .then(() => get(matchId));

export const archiveMatch = (matchId: string, archived: boolean) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');

  return matchesCollection
    .doc(matchId)
    .update({
      'userStatus.archived.false': archived
        ? FieldValue.arrayRemove(user.uid)
        : FieldValue.arrayUnion(user.uid),
      'userStatus.archived.true': archived
        ? FieldValue.arrayUnion(user.uid)
        : FieldValue.arrayRemove(user.uid),
    })
    .then(() => get(matchId));
};

export const likeMatch = (matchId: string, liked: boolean) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');

  return matchesCollection
    .doc(matchId)
    .update({
      'userStatus.liked.false': liked
        ? FieldValue.arrayRemove(user.uid)
        : FieldValue.arrayUnion(user.uid),
      'userStatus.liked.true': liked
        ? FieldValue.arrayUnion(user.uid)
        : FieldValue.arrayRemove(user.uid),
    })
    .then(() => get(matchId));
};

export const subscribeToMatchesForCandidate = (
  candidateId: string,
  callback: (snapshot: QuerySnapshot) => void
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');
  return matchesCollection
    .where('candidate.id', '==', candidateId)
    .where('startedCommunication', '==', false)
    .where(`userStatus.archived.false`, 'array-contains', candidateId)
    .onSnapshot(callback);
};
export const subscribeToMatchesForPosition = (
  positionId: string,
  callback: (snapshot: QuerySnapshot) => void
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');
  return matchesCollection
    .where('position.id', '==', positionId)
    .where('startedCommunication', '==', false)
    .where(`userStatus.archived.false`, 'array-contains', user.uid)
    .onSnapshot(callback);
};

export const subscribeToMatchesWithConversationForCandidate = (
  candidateId: string,
  callback: (snapshot: QuerySnapshot) => void
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');
  return matchesCollection
    .where('candidate.id', '==', candidateId)
    .where('startedCommunication', '==', true)
    .where(`userStatus.archived.false`, 'array-contains', user.uid)
    .onSnapshot(callback);
};
export const subscribeToMatchesWithConversationForPosition = (
  positionId: string,
  callback: (snapshot: QuerySnapshot) => void
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');
  return matchesCollection
    .where('position.id', '==', positionId)
    .where('startedCommunication', '==', true)
    .where(`userStatus.archived.false`, 'array-contains', user.uid)
    .onSnapshot(callback);
};

export const markConversationForMatchActive = async (
  matchId: string,
  active: boolean
) => {
  const user = auth.currentUser;
  if (!user) throw Error('User not logged in');
  if (!matchId) throw Error('No match id found');
  await Promise.all([
    matchesCollection.doc(matchId).set(
      {
        unseenMessagesCount: {
          [user.uid]: active ? 0 : undefined,
        },
      },
      { merge: true }
    ),
    chatPresenceCollection.doc(matchId).set(
      {
        activeUserIds: active
          ? FieldValue.arrayUnion(user.uid)
          : FieldValue.arrayRemove(user.uid),
      },
      { merge: true }
    ),
  ]);
};

export const markMatchSeen = (matchId: string) => {
  const user = auth.currentUser;

  if (!user) throw Error('User not logged in');
  if (!matchId) throw Error('No match id found');
  return matchesCollection.doc(matchId).update({
    'userStatus.seen.false': FieldValue.arrayRemove(user.uid),
    'userStatus.seen.true': FieldValue.arrayUnion(user.uid),
  });
};

export const subscribeToMatchesIndicatorCountForPosition = (
  positionId: string,
  callback: (count: number) => void
) => {
  const user = auth.currentUser;

  if (!user) throw Error('User not logged in');

  return positionsCollection
    .doc(positionId)
    .collection('_meta')
    .doc('_unseenMatches')
    .onSnapshot((snapshot) => {
      callback(snapshot.data()?.unseenMatches.length ?? 0);
    });
};

export const subscribeToPositionState = (
  positionId: string,
  callback: (data?: DocumentData) => void
) => {
  const user = auth.currentUser;

  if (!user) throw Error('User not logged in');

  return positionsCollection
    .doc(positionId)
    .collection('_meta')
    .doc('_state')
    .onSnapshot((snapshot) => {
      callback(snapshot.data());
    });
};

export const subscribeToMatchesIndicatorCountForCandidate = (
  candidateId: string,
  callback: (count: number) => void
) => {
  const user = auth.currentUser;

  if (!user) throw Error('User not logged in');

  return candidatesCollection
    .doc(candidateId)
    .collection('_meta')
    .doc('_unseenMatches')
    .onSnapshot((snapshot) => {
      callback(snapshot.data()?.unseenMatches.length ?? 0);
    });
};

export const subscribeToCandidateState = (
  candidateId: string,
  callback: (data?: DocumentData) => void
) => {
  const user = auth.currentUser;

  if (!user) throw Error('User not logged in');

  return candidatesCollection
    .doc(candidateId)
    .collection('_meta')
    .doc('_state')
    .onSnapshot((snapshot) => {
      callback(snapshot.data());
    });
};
