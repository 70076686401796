import * as Education from './education';
import * as Experience from './experience';
import * as User from './user';
import * as Skill from './skill';
import * as Conditions from './conditions';
import { AnyKeyObject } from '.';
export interface CandidateDto extends User.UserDto {
  school: Education.EducationDto[];
  education: Education.EducationDto[];
  languages: Skill.SkillDto[];
  practicalExperience: Experience.ExperienceDto[];
  otherExperience: Experience.ExperienceDto[];
  softwareSkills: Skill.SkillDto[];
  conditions?: Conditions.ConditionsDto;
}

export const fromData = (data: AnyKeyObject) => {
  const user = User.fromData(data);

  const candidate: CandidateDto = {
    ...user,
    school: Education.fromDataSet(data.school),
    education: Education.fromDataSet(data.education),
    languages: data.languages ? data.languages.map(Skill.fromData) : [],
    practicalExperience: Experience.fromDataSet(data.practicalExperience),
    otherExperience: Experience.fromDataSet(data.otherExperience),
    softwareSkills: data.softwareSkills
      ? data.softwareSkills.map(Skill.fromData)
      : [],
    conditions: Conditions.fromData(data.conditions),
  };
  return candidate;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !doc.id || !data) throw new Error('Could not parse candidate');
  return fromData({ ...data, id: doc.id });
};
