import * as MessagesCollection from '@/api/collections/messages';
import { auth } from '@/firebase';
import * as Message from '@/types/message';
import { MessageDto } from 'functions/src/types/message';

const getMessagesForMatch = (matchId: string) =>
  MessagesCollection.list(matchId).then((messages) =>
    messages.map(Message.fromDto)
  );
const subscribeToMessagesForMatch = (
  matchId: string,
  callback: (messages: Message.Message[]) => void
) =>
  MessagesCollection.subscribe(matchId, (messages: MessageDto[]) => {
    callback(messages.map(Message.fromDto));
  });

const sendMessage = (
  matchId: string,
  text: string,
  isFirstMessage: boolean
) => {
  const userId = auth.currentUser?.uid;
  if (!userId || !text) return;

  const message: Partial<Message.Message> = {
    senderId: userId,
    text: text,
    isFirstMessage: isFirstMessage,
  };

  return MessagesCollection.add(matchId, Message.toDto(message)).then(
    Message.fromDto
  );
};

const markMessageSeen = (matchId: string, messageId: string) => {
  return MessagesCollection.update(matchId, messageId, {
    seen: true,
  }).then(Message.fromDto);
};

export const MessagesApi = () => ({
  getMessagesForMatch,
  subscribeToMessagesForMatch,
  sendMessage,
  markMessageSeen,
});
