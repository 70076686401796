<template lang="pug">
.public
  v-app-bar( elevation='0', color='transparent')
    v-spacer
    v-btn.mx-1.text-capitalize(text, color='accent', href="https://stairship.com" target="_blank") Home
    v-btn.mx-1.text-capitalize(text, color='accent', to='/login', v-if='!currentUser') Für Jobsuchende
    v-btn.mx-1.text-capitalize(text, color='accent', to='/register' v-if='!currentUser') Für Unternehmen

    v-btn.mx-1.text-capitalize(outlined, color='accent', to='/b/dashboard', v-if='currentUser && isBusiness') 
      | Dashboard
      v-icon.ml-2 mdi-chevron-right
    v-btn.mx-1.text-capitalize(outlined, color='accent', to='/dashboard', v-if='currentUser && !isBusiness') 
      | Dashboard
      v-icon.ml-2 mdi-chevron-right
  
  
  
  .flex-container
    v-main(style="min-height: calc(100vh - 64px);")
      slot
</template>

<script>
import { mapState } from 'vuex';
import { UserType } from '@/types/user';
import { auth } from '@/firebase';
export default {
  name: 'Public',
  computed: {
    currentUser() {
      return auth?.currentUser;
    },
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn,
      isBusiness: (state) => state.user.currentUser?.type === UserType.BUSINESS,
    }),
  },
};
</script>

<style lang="sass" scoped>
.public
  padding: 0
  max-width: 100% !important
  width: 100% !important
  background: url("~@/assets/img/Home_Header_Desktop.jpg")
  // background-attachment: fixed
  background-size: 100% auto
  background-repeat: no-repeat
  background-position: top center
  position: relative
  margin: 0
  padding: 0
  .publicContainer
    height: 100%
</style>
