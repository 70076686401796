import { AnyKeyObject } from '.';

export interface AddressDto {
  street: string;
  streetNr: string;
  zip: string;
  city: string;
}

export const fromData = (data: AnyKeyObject): AddressDto => {
  return {
    street: data?.street ?? '',
    streetNr: data?.streetNr ?? '',
    zip: data?.zip ?? '',
    city: data?.city ?? '',
  };
};
