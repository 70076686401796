import { Api } from '@/api';
import { StateCandidate, VuexAction } from '@/store/types';
import { Candidate } from '@/types/candidate';
import { Education } from '@/types/education';
import { Conditions } from '@/types/conditions';
import { auth, DocumentData, MergeFields, QuerySnapshot } from '../firebase';
import { Experience } from '@/types/experience';
import { Gender, UserType } from '@/types/user';
import { Commit } from 'vuex';
import * as Match from '@/types/match';
import Vue from 'vue';
import { MatchesApi } from '@/api/modules/matches';
import { flatten, orderBy } from 'lodash';
import { notEmpty } from '@/utilities/Utils';
import * as MatchDto from '../../functions/src/types/match';

interface UpdateCandidateInput {
  updatedCandidate: Candidate;
  mergeFields?: MergeFields;
}

interface FetchMatchesInput {
  section: 'Likes' | 'Archive' | 'Matches';
  reset?: boolean;
}

interface PartialUpdateInput {
  item: Experience[] | Education[] | Conditions;
  key: keyof Candidate;
}

const state: StateCandidate = {
  error: '',
  loading: false,
  matches: [],
  matchesWithConversation: [],
  hasMoreMatches: true,
  matchesIndicatorCount: 0,
  generatingMatches: false,
  candidate: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    photoURL: '',
    type: UserType.CANDIDATE,
    gender: Gender.UNSPECIFIED,
    school: [],
    education: [],
    languages: [],
    practicalExperience: [],
    otherExperience: [],
    softwareSkills: [],
  },
  selectedMatchesSection: 'Matches',
};

const candidateApi = Api().candidate();

const matchesApi = Api().matches();

const showError = (commit: Commit, message: string) => {
  commit('setLoading', false);
  commit('setError', message);
};

const mutations = {
  setLoading(state: StateCandidate, status: boolean) {
    state.loading = status;
  },
  setError(state: StateCandidate, error: string) {
    state.error = error;
  },
  setCurrentCandidate(state: StateCandidate, candidate: Candidate) {
    if (candidate) {
      state.candidate = candidate;
    }
  },
  setMatches(state: StateCandidate, matches: Match.Match[]) {
    state.matches = matches;
  },
  appendMatches(state: StateCandidate, matches: Match.Match[]) {
    state.matches = [...state.matches, ...matches];
  },
  removeMatch(state: StateCandidate, matchId: string) {
    const index = state.matches.findIndex((match) => match.id == matchId);
    if (index === -1) return;
    state.matches.splice(index, 1);
  },
  setMatch(state: StateCandidate, match: Match.Match) {
    const index = state.matches.findIndex((m) => m.id === match.id);
    if (index > -1) {
      Vue.set(state.matches, index, match);
    } else {
      state.matches.push(match);
    }
  },
  setMatchesWithConversation(state: StateCandidate, matches: Match.Match[]) {
    state.matchesWithConversation = matches;
  },
  setHasMoreMatches(state: StateCandidate, hasMoreMatches: boolean) {
    state.hasMoreMatches = hasMoreMatches;
  },
  clearCandidate(state: StateCandidate) {
    state.candidate = {
      id: '',
      firstName: '',
      lastName: '',
      photoURL: '',
      email: '',
      nationality: undefined,
      type: UserType.CANDIDATE,
      gender: Gender.UNSPECIFIED,
      school: [],
      education: [],
      languages: [],
      practicalExperience: [],
      otherExperience: [],
      softwareSkills: [],
    };
  },
  setMatchesCountIndicator(state: StateCandidate, count: number) {
    state.matchesIndicatorCount = count;
  },
  resetMatches(state: StateCandidate) {
    state.matches = [];
    state.hasMoreMatches = true;
  },
  setMatchWithConversation(state: StateCandidate, match: Match.Match) {
    const matchIndex = state.matchesWithConversation.findIndex(
      (item) => item.id === match.id
    );
    if (matchIndex > -1) {
      state.matchesWithConversation.splice(matchIndex, 1, match);
    } else {
      state.matchesWithConversation.push(match);
    }
  },
  removeMatchFromConversations(state: StateCandidate, matchId: string) {
    const index = state.matchesWithConversation.findIndex(
      (match) => match.id == matchId
    );
    if (index === -1) return;
    state.matchesWithConversation.splice(index, 1);
  },
  setGeneratingMatches(state: StateCandidate, generatingMatches: boolean) {
    state.generatingMatches = generatingMatches;
  },
  setSelectedMatchesSection(state: StateCandidate, section: string) {
    state.selectedMatchesSection = section;
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types
const actionWrapper = async (commit: Commit, action: Function) => {
  try {
    commit('setLoading', true);
    await action();
    commit('setLoading', false);
  } catch (error) {
    showError(commit, error.message);
    commit('setLoading', false);
  }
};

const actions = {
  async fetchCurrentCandidate({ dispatch }: VuexAction) {
    dispatch('fetchCandidate', auth.currentUser?.uid);
  },
  async markMatchSeen({ commit, rootState }: VuexAction, matchId: string) {
    const action = async () => {
      const match = state.matches.find((match) => match.id === matchId);
      if (match?.seen) {
        return;
      }
      await Api().matches().markMatchSeen(matchId);

      commit('setMatch', { ...match, seen: true });
    };

    await actionWrapper(commit, action);
  },
  async fetchMatchesWithConversation({ commit }: VuexAction) {
    const action = async () => {
      const candidateId = auth.currentUser?.uid;
      if (!candidateId) return;
      const matches = await Api()
        .matches()
        .getMatchesWithConversationForCandidate(candidateId);

      commit('setMatchesWithConversation', matches);
    };

    await actionWrapper(commit, action);
  },
  async subscribeToMatchesIndicatorCount({ commit }: VuexAction) {
    const callback = (count: number) => {
      commit('setMatchesCountIndicator', count);
    };
    if (!state.candidate.id) return;

    MatchesApi().subscribeToMatchesIndicatorCountForCandidate(
      state.candidate.id,
      callback
    );
  },
  async subscribeToCandidateState({ commit }: VuexAction) {
    const callback = (data?: DocumentData) => {
      if (!data) return;
      commit(
        'setGeneratingMatches',
        data.runningMatchGenerationJobs.length > 0
      );
    };
    if (!state.candidate.id) return;

    MatchesApi().subscribeToCandidateState(state.candidate.id, callback);
  },
  async fetchMatches(
    { commit }: VuexAction,
    { section, reset }: FetchMatchesInput
  ) {
    const action = async () => {
      if (reset) {
        commit('resetMatches');
      }

      if (!state.hasMoreMatches) {
        return;
      }
      const candidateId = auth.currentUser?.uid;
      if (!candidateId) return;
      const matches = await matchesApi.getAllMatchesForCandidate(
        candidateId,
        section,
        state.matches[state.matches.length - 1]
      );

      commit('setHasMoreMatches', matches.length > 0);
      matches.forEach((match) => {
        commit('setMatch', match);
      });
    };
    return actionWrapper(commit, action);
  },
  async fetchMatch({ commit }: VuexAction, matchId: string) {
    const action = async () => {
      const match = await matchesApi.getMatch(matchId);
      commit('setMatch', match);
    };
    return actionWrapper(commit, action);
  },
  async fetchCandidate({ commit }: VuexAction, id: string) {
    const action = async () => {
      const candidate = await candidateApi.getCandidateById(id);
      commit('setCurrentCandidate', candidate);
    };
    return actionWrapper(commit, action);
  },
  async updateCandidate({ commit }: VuexAction, payload: UpdateCandidateInput) {
    const action = async () => {
      const { updatedCandidate, mergeFields } = payload;
      const mergedCandidate = { ...state.candidate, ...updatedCandidate };
      if (!mergedCandidate.id) throw Error('Cannot identify candidate');
      await candidateApi.updateCandidate(mergedCandidate, mergeFields);
      commit('setCurrentCandidate', mergedCandidate);
      commit('setGeneratingMatches', true);
    };

    return actionWrapper(commit, action);
  },
  partialUpdate(
    { dispatch, commit }: VuexAction,
    { item, key }: PartialUpdateInput
  ) {
    return dispatch('updateCandidate', {
      updatedCandidate: { [key]: item },
      mergeFields: [`${key}`],
    }).then(() => {
      commit('setGeneratingMatches', true);
    });
  },
  async markConversationStartForMatch({ commit }: VuexAction, matchId: string) {
    const action = async () => {
      await matchesApi.markConversationStartForMatch(matchId);
    };
    return actionWrapper(commit, action);
  },
  async archiveMatch(
    { commit }: VuexAction,
    { matchId, archive }: { matchId: string; archive: boolean }
  ) {
    const action = async () => {
      const match = await matchesApi.archiveMatch(matchId, archive);
      commit('removeMatchFromConversations', match.id);
      commit('removeMatch', match.id);
    };
    return actionWrapper(commit, action);
  },
  likeMatch(
    { commit }: VuexAction,
    { matchId, liked }: { matchId: string; liked: boolean }
  ) {
    const action = async () => {
      const updatedMatch = await Api().matches().likeMatch(matchId, liked);

      commit('setMatch', updatedMatch);
    };
    actionWrapper(commit, action);
  },
  async subscribeToMatchesWithConversation({ commit }: VuexAction) {
    try {
      const callback = (snapshot: QuerySnapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const match = Match.fromDto(MatchDto.fromFirestore(change.doc));
          commit('setMatchWithConversation', match);
          commit('removeMatch', match.id);
        });
      };
      if (!state.candidate.id) return;

      MatchesApi().subscribeToMatchesWithConversationForCandidate(
        state.candidate.id,
        callback
      );
    } catch (error) {
      console.log(error);
      showError(commit, error.message);
    }
  },
};

const getters = {
  error: () => {
    return state.error;
  },
  loading: () => {
    return state.loading;
  },
  matchesForSection:
    (state: StateCandidate, _getters: any, rootState: any) =>
    (section: string) => {
      let matches: Match.Match[] = [];
      switch (section) {
        case 'Archive':
          matches = state.matches.filter((match) => match.archived);
          break;
        case 'Matches':
          matches = state.matches.filter((match) => !match.archived);
          break;
        case 'Likes':
          matches = state.matches.filter((match) => match.liked);
          break;
        default:
          matches = [];
          break;
      }

      return orderBy(matches, ['score.percentage', 'id'], ['desc', 'desc']);
    },
  matchesWithConversation: (state: StateCandidate) => {
    return (
      state.matchesWithConversation
        ?.filter((match) => !match.archived)
        .sort((a, b) => {
          const sentAtA = a.lastMessage?.sentAt;
          const sentAtB = b.lastMessage?.sentAt;

          if (!sentAtA || !sentAtB) {
            return 0;
          }

          return sentAtB.valueOf() - sentAtA.valueOf();
        }) ?? []
    );
  },
  unreadMessagesCount: (state: StateCandidate) => () => {
    return flatten(
      state.matchesWithConversation.map(
        (match) => match.unseenMessagesCount[`${state.candidate.id}`]
      )
    )
      .filter(notEmpty)
      .reduce((partial_sum, a) => partial_sum + a, 0);
  },
  matchById: (state: StateCandidate) => (matchId: string) => {
    return [...state.matches, ...state.matchesWithConversation].find(
      (match) => match.id === matchId
    );
  },
};

export const candidate = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
