import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
import VueGtm from '@gtm-support/vue2-gtm';
import { auth, RealtimeDatabase, realtimeDatabase } from './firebase';
import VueMeta from 'vue-meta';
import './assets/style/main.sass';
import * as UserCollection from '@/api/collections/users';
// import './assets/style/fonts.scss';
import 'typeface-roboto/index.css';
import VueSmoothScroll from 'vue2-smooth-scroll';

Vue.use(VueSmoothScroll, { duration: 400, updateHistory: false });

Vue.use(VueMeta);
const production = process.env.VUE_APP_ENV === 'production';
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CODE,
  defer: true,
  compatibility: true,
  enabled: production,
  debug: false,
  vueRouter: router,
  trackOnNextTick: false,
});

import Clear from '@/layouts/Clear.vue';
import Secure from '@/layouts/Secure.vue';
import Public from '@/layouts/Public.vue';
import Auth from '@/layouts/Auth.vue';
import Business from '@/layouts/Business.vue';

Vue.component('clearLayout', Clear);
Vue.component('secureLayout', Secure);
Vue.component('publicLayout', Public);
Vue.component('authLayout', Auth);
Vue.component('businessLayout', Business);

Vue.config.productionTip = false;

const emailVerifiedInCurrentToken = async () =>
  auth.currentUser
    ?.getIdTokenResult()
    .then((idTokenResult) => !!idTokenResult.claims.email_verified);

let app: unknown;
auth.onAuthStateChanged(async (user) => {
  if (user && user.emailVerified) {
    await emailVerifiedInCurrentToken().then((verified) => {
      if (verified !== user.emailVerified) {
        // refresh token to make sure firestore knows about latest verfiied status
        return user
          .getIdTokenResult(true)
          .then(() => user.reload())
          .then(async () => {
            // make sure to update user in firestore with latest email
            if (auth.currentUser?.uid && user.email)
              await UserCollection.update({
                id: auth.currentUser?.uid,
                email: user.email,
              });
          });
      }
    });
    store.dispatch('user/fetchUser', user.uid);
    trackUserPresence();
  } else if (user && !user.emailVerified) {
    // router.push('/verify');
  } else {
    store.commit('user/clearUser');
    // router.push('/');
    // router.go(1);
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});

const trackUserPresence = () => {
  // Fetch the current user's ID from Firebase Authentication.
  const uid = auth.currentUser?.uid;

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const userStatusDatabaseRef = realtimeDatabase.ref('/status/' + uid);

  // We'll create two constants which we will write to
  // the Realtime database when this device is offline
  // or online.
  const isOfflineForDatabase = {
    state: 'offline',
    last_changed: RealtimeDatabase.ServerValue.TIMESTAMP,
  };

  const isOnlineForDatabase = {
    state: 'online',
    last_changed: RealtimeDatabase.ServerValue.TIMESTAMP,
  };

  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  realtimeDatabase.ref('.info/connected').on('value', function (snapshot) {
    // If we're not currently connected, don't do anything.
    if (snapshot.val() == false) {
      return;
    }

    // If we are currently connected, then use the 'onDisconnect()'
    // method to add a set which will only trigger once this
    // client has disconnected by closing the app,
    // losing internet, or any other means.
    userStatusDatabaseRef
      .onDisconnect()
      .set(isOfflineForDatabase)
      .then(function () {
        // The promise returned from .onDisconnect().set() will
        // resolve as soon as the server acknowledges the onDisconnect()
        // request, NOT once we've actually disconnected:
        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

        // We can now safely set ourselves as 'online' knowing that the
        // server will mark us as offline once we lose connection.
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
  });
};
