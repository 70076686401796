<template lang="pug">
.flex-container.fill-height
  slot

</template>

<script>
export default {
  name: 'Clear',
  components: {},
};
</script>
<style lang="sass">
@import "@/assets/style/main"
</style>
