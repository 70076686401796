import * as Education from './education';
import * as Experience from './experience';
import * as Conditions from './conditions';
import * as Skill from './skill';
import * as Company from './company';
import { AnyKeyObject } from '.';
import * as User from './user';
import { Timestamp } from '../firebase';

export enum PositionStatus {
  OPEN = 'open',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}
export interface PositionDto {
  id: string;
  name: string;
  tasks?: string[];
  status?: PositionStatus;
  school?: Education.EducationDto[];
  education?: Education.EducationDto[];
  practicalExperience?: Experience.ExperienceDto[];
  otherExperience?: Experience.ExperienceDto[];
  offering?: Conditions.ConditionsDto;
  languages?: Skill.SkillDto[];
  softwareSkills?: Skill.SkillDto[];
  otherRequirements?: string[];
  company?: Company.CompanyDto;
  recruiter?: User.UserDto;
  createdAt?: Timestamp;
  updatedAt: Timestamp;
}

export const fromData = (data: AnyKeyObject) => {
  const position: PositionDto = {
    id: data.id,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    company: data.company ? Company.fromData(data.company) : undefined,
    name: data.name,
    status: data.status,
    school: Education.fromDataSet(data.school),
    education: Education.fromDataSet(data.education),
    practicalExperience: Experience.fromDataSet(data.practicalExperience),
    otherExperience: Experience.fromDataSet(data.otherExperience),
    offering: Conditions.fromData(data.offering),
    softwareSkills: data.softwareSkills
      ? data.softwareSkills.map(Skill.fromData)
      : [],
    languages: data.languages ? data.languages.map(Skill.fromData) : [],
    otherRequirements: data.otherRequirements ?? [],
    tasks: data.tasks ?? [],
    recruiter: data.recruiter ? User.fromData(data.recruiter) : undefined,
  };

  return position;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !doc.id || !data) throw new Error('Could not parse position');
  return fromData({ ...data, id: doc.id });
};
