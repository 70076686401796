import { AnyKeyObject } from '.';

export interface BaseUserDto {
  id: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  email: string;
}

export const fromData = (data: AnyKeyObject) => {
  const user: BaseUserDto = {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    photoURL: data.photoURL,
  };

  return user;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !doc.id || !data) throw new Error('Could not parse base user');

  return fromData({ ...data, id: doc.id });
};
