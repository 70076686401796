import { Education, EducationStatus } from '@/types/education';
import { Experience } from '@/types/experience';
import { Skill } from '@/types/skill';
import { orderBy } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyFromObj = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidEnumValue<Type>(e: Type, v: any): boolean {
  return Object.values(e).indexOf(v) >= 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortArray(array: any[], key: string) {
  return array.sort(function (a, b) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

export const orderEducationItems = (items: Education[]) => {
  const statusSorter = (education: Education) => {
    switch (education.status) {
      case EducationStatus.LAUFEND:
        return 0;
      case EducationStatus.ABGESCHLOSSEN:
        return 1;
      case EducationStatus.ABGEBROCHEN:
        return 2;
    }
  };
  return orderBy(items, [statusSorter, 'graduationYear'], ['asc', 'desc']);
};
export const orderExperienceItems = (items: Experience[]) => {
  return orderBy(items, ['timeEnd', 'timeStart'], ['desc', 'desc']);
};
export const orderSkillItems = (items: Skill[]) => {
  return orderBy(items, ['level', 'name'], ['desc', 'asc']);
};

export const generateArrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
