<template>
  <v-app>
    <component :is="layout" :triggerOnboarding="triggerOnboarding">
      <router-view @startOnboarding="triggerOnboarding = true" />
    </component>
    <confirm-dialog ref="confirm" />
  </v-app>
</template>

<script>
import Vue from 'vue';
const defaultLayout = 'public';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import { auth } from './firebase';
export default Vue.extend({
  name: 'App',
  watch: {
    $route(to, from) {
      if (
        this.isBusiness &&
        !to.query.positionId &&
        to.name !== 'OnboardingBusiness'
      ) {
        this.$router.replace({
          query: {
            positionId:
              from.query.positionId ??
              this.$store.state.business.selectedPositionId,
            ...to.query,
          },
        });
      }

      if (to.query.positionId && this.isLoggedIn) {
        this.$store.dispatch(
          'business/setCurrentPosition',
          to.query.positionId
        );
      }
    },
  },
  components: { ConfirmDialog },
  data: () => ({
    unsubscribe: null,
    triggerOnboarding: false,
    initiatedCandidateStore: false,
  }),
  computed: {
    layout() {
      if (this.$route.meta.layout == 'dynamic') {
        if (auth.currentUser) {
          return this.isBusiness ? 'business-layout' : 'secure-layout';
        }
        return 'public-layout';
      }
      if (
        this.isBusiness &&
        this.$route.meta &&
        this.$route.meta.layout == 'secure'
      ) {
        return 'business-Layout';
      }
      return (
        ((this.$route.meta && this.$route.meta.layout) || defaultLayout) +
        'Layout'
      );
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
    isLoggedIn() {
      return !!auth.currentUser;
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === 'user/setCurrentUser') {
        if (!state.user.isBusiness && !this.initiatedCandidateStore) {
          this.initiatedCandidateStore = true;
          this.$store.dispatch('candidate/subscribeToMatchesIndicatorCount');
          this.$store.dispatch('candidate/subscribeToMatchesWithConversation');
          this.$store.dispatch('candidate/subscribeToCandidateState');
          this.$store.commit('candidate/resetMatches');
        } else if (state.user.isBusiness) {
          this.$store.dispatch('business/fetchPositions');
        }
      }

      if (mutation.type === 'business/setSelectedPositionId') {
        if (state.business.selectedPositionId) {
          this.$store.dispatch('business/subscribeToMatchesWithConversation');
          this.$store.dispatch('business/subscribeToMatchesIndicatorCount');
          this.$store.commit('business/resetMatches');
          this.$store.dispatch('business/subscribeToPositionState');
        }
      }
    });
    this.$root.$confirm = this.$refs.confirm.open;
  },
});
</script>
