import {
  auth,
  candidatesCollection,
  companiesCollection,
  MergeFields,
  serverTimestamp,
  SetOptions,
} from '@/firebase';
import * as Company from '../../../functions/src/types/company';

export const get = (companyId: string) =>
  companiesCollection
    .doc(companyId)
    .get()
    .then(Company.fromFirestore);

export const update = (company: Partial<Company.CompanyDto>) =>
  companiesCollection.doc(company.id).set(
    {
      ...company,
      updatedAt: serverTimestamp,
    },
    { merge: true }
  );

export const create = async (company: Partial<Company.CompanyDto>) => {
  const uid = auth.currentUser?.uid;
  // check if company with the given name already exists
  const result = await companiesCollection
    .where('name', '==', company.name)
    .where('recruiters', 'array-contains', uid)
    .limit(1)
    .get();

  if (result.size > 0) return Company.fromFirestore(result.docs[0]);

  if (uid) company.recruiters = [uid];
  return companiesCollection
    .add({
      ...company,
      createdAt: serverTimestamp,
      updatedAt: serverTimestamp,
    })
    .then((ref) => get(ref.id));
};
