import * as Position from './position';
import * as Message from './message';
import { MatchDto } from '../../functions/src/types/match';
import { AnyKeyObject, DeepPartial } from 'functions/src/types';
import * as BaseUser from './baseUser';
import * as Score from './score';
import * as Candidate from './candidate';
import { Timestamp, auth } from '@/firebase';
import _ from 'lodash';
export interface Match {
  id: string;
  candidate: Candidate.Candidate;
  position: Position.Position;
  startedCommunication: boolean;
  messageCount: number;
  archived: boolean;
  liked: boolean;
  seen: boolean;
  score: Score.Score;
  candidateScore: Score.Score;
  positionScore: Score.Score;
  lastMessage?: Message.Message;
  opponentRemoved?: boolean;
  userIds: string[];
  unseenMessagesCount: { [userId: string]: number };
  updatedAt?: Date;
  createdAt?: Date;
}

export const fromDto = (dto: MatchDto): Match => ({
  ...dto,
  score: Score.fromDto(dto.score),
  candidateScore: Score.fromDto(dto.candidateScore),
  positionScore: Score.fromDto(dto.positionScore),
  candidate: Candidate.fromDto(dto.candidate),
  position: Position.fromDto(dto.position),
  lastMessage: dto.lastMessage ? Message.fromDto(dto.lastMessage) : undefined,
  userIds: dto.userIds ?? [],
  archived:
    !!auth.currentUser &&
    dto.userStatus.archived.true.includes(auth.currentUser.uid),
  liked:
    !!auth.currentUser &&
    dto.userStatus.liked.true.includes(auth.currentUser.uid),
  seen:
    !!auth.currentUser &&
    dto.userStatus.seen.true.includes(auth.currentUser.uid),
  updatedAt: dto.updatedAt?.toDate(),
  createdAt: dto.createdAt?.toDate(),
});

export const toDto = (match: Match): DeepPartial<MatchDto> => ({
  ...match,
  position: Position.toDto(match.position),
  candidate: BaseUser.toDto(match.candidate),
});

const isValidValue = (value: unknown) => {
  return typeof value !== undefined && value !== null;
};

const processObject = <T extends AnyKeyObject>(objectVal: T): T => {
  const payload: AnyKeyObject = {};
  for (const [key, val] of Object.entries(objectVal)) {
    const [field, value] = processValue(key, val);
    if (isValidValue(value)) {
      payload[field] = value;
    }
  }
  return payload as T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processArray = (arrayVal: Array<any>): any => {
  return arrayVal.map((val, index) => {
    if (val instanceof Object) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, value] = processValue(index, val);
      return value;
    }
    return val;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processValue = (field: string | number, value: any) => {
  const requiredKeys = ['_seconds', '_nanoseconds'];
  const isFirestoreTimestamp = _.every(requiredKeys, _.partial(_.has, value));

  if (isFirestoreTimestamp) {
    const timestamp = new Timestamp(value._seconds, value._nanoseconds);
    return [field, timestamp];
  } else if (value instanceof Array) {
    return [field, processArray(value)];
  } else if (value instanceof Object) {
    return [field, processObject(value)];
  }

  return [field, value];
};
