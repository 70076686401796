import { AnyKeyObject } from '.';

export interface CityDto {
  id: string;
  name: string;
}

export const fromData = (data?: AnyKeyObject) => {
  if (!data) return;
  const city: CityDto = {
    id: data.id,
    name: data.name,
  };

  return city;
};
