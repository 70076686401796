import { auth, Timestamp } from '@/firebase';

import { MessageDto } from '../../functions/src/types/message';

export interface Message {
  id: string;
  sentAt: Date;
  text: string;
  senderId: string;
  sentByCurrentUser: boolean;
  isFirstMessage: boolean;
  seen: boolean;
  seenByCurrentUser: boolean;
}

export const fromDto = (dto: MessageDto): Message => {
  const sentByCurrentUser = auth.currentUser?.uid === dto.senderId;
  return {
    ...dto,
    sentAt: dto.sentAt?.toDate(),
    sentByCurrentUser,
    seenByCurrentUser: sentByCurrentUser || !!dto.seen,
  };
};

export const toDto = (message: Partial<Message>): Partial<MessageDto> => ({
  id: message.id,
  text: message.text,
  senderId: message.senderId,
  isFirstMessage: message.isFirstMessage,
  seen: message.seen,
  sentAt: message.sentAt ? Timestamp.fromDate(message.sentAt) : undefined,
});
