import { CityDto } from '../../functions/src/types/city';
export interface City {
  id: string;
  name: string;
}

export const fromDto = (dto: CityDto): City => ({
  ...dto,
});
export const toDto = (city: City): CityDto => ({
  ...city,
});
