import * as CandidatesCollection from '@/api/collections/candidates';
import * as Candidate from '@/types/candidate';
import { MergeFields } from '../../firebase';

const getCandidateById = (uid: string) =>
  CandidatesCollection.get(uid).then(Candidate.fromDto);

const updateCandidate = (
  candidate: Candidate.Candidate,
  mergeFields?: MergeFields
) => CandidatesCollection.update(Candidate.toDto(candidate), mergeFields);

export const CandidateApi = () => ({
  getCandidateById,
  updateCandidate,
});
