import { isValidEnumValue } from '../utils';
import * as Company from './company';
import * as City from './city';
import { AnyKeyObject } from '.';
import firebase from 'firebase/app';
import * as BaseUser from './baseUser';
import { CountryDto } from './country';

export enum UserType {
  CANDIDATE = 'candidate',
  BUSINESS = 'business',
}

export enum Gender {
  MALE = 'Männlich',
  FEMALE = 'Weiblich',
  OTHER = 'Divers',
  UNSPECIFIED = '-',
}

export interface UserDto extends BaseUser.BaseUserDto {
  type: UserType;
  gender: Gender;
  nationality?: CountryDto | null;
  phone?: string;
  birthdate?: firebase.firestore.Timestamp | null;
  company?: Company.CompanyDto | null;
  active?: boolean;
  updatedAt: firebase.firestore.Timestamp;
  createdAt?: firebase.firestore.Timestamp;
  city?: City.CityDto | null;
  anonymous: boolean;
  whitelistedCompanies: string[];
  startedOnboarding?: boolean;
}

export const fromData = (data: AnyKeyObject) => {
  if (!isValidEnumValue(UserType, data.type))
    throw Error('Cannot determine if current user is candidate or employee');

  const baseUser = BaseUser.fromData(data);

  const user: UserDto = {
    ...baseUser,
    phone: data.phone ?? null,
    type: data.type,
    company: data.company ? Company.fromData(data.company) : undefined,
    active: data.active ?? null,
    birthdate: data.birthdate ?? null,
    nationality: data.nationality ?? null,
    city: City.fromData(data.city) ?? null,
    gender: isValidEnumValue(Gender, data.gender)
      ? data.gender
      : Gender.UNSPECIFIED,
    anonymous: !!data.anonymous,
    whitelistedCompanies: data.whitelistedCompanies ?? [],
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    startedOnboarding: !!data.startedOnboarding,
  };

  return user;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !doc.id || !data) throw new Error('Could not parse user');

  return fromData({ ...data, id: doc.id });
};
