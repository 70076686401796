const emailRules = [
  (v: string) => !!v || 'Bitte gib deine E-Mail-Adresse an',
  (v: string) =>
    /.+@.+\..+/.test(v) ||
    'Kontrolliere ob die E-Mail-Adresse richtig formatiert ist.',
];
const codeRules = [
  (v: string) => !!v || 'Pflichtfeld',
  (v: string) =>
    (v && v.length == 6) || 'Der Code muss aus mindesten 6 Zeichen sein',
];
const passwordRules = [
  (v: string) => !!v || 'Bitte gib ein sicheres Passwort ein',
  (v: string) =>
    (v && v.length >= 8) ||
    'Dein Passwort muss mindestens aus 8 Zeichen bestehen',
];

const loginPasswordRules = [
  (v: string) => !!v || 'Bitte gib ein sicheres Passwort ein',
  (v: string) =>
    (v && v.length >= 6) ||
    'Dein Passwort muss mindestens aus 6 Zeichen bestehen',
];

const streetRules = [(v: string) => !!v || 'Bitte gib eine Straße ein'];
const streetNrRules = [(v: string) => !!v || 'Bitte gib eine Hausnummer ein'];
const zipRules = [(v: string) => !!v || 'Bitte gib eine Postleitzahl ein'];
const cityRules = [(v: string) => !!v || 'Bitte gib eine Stadt ein'];

const nameRules = [(v: string) => !!v || 'Bitte gib einen Namen an'];

export default {
  emailRules,
  codeRules,
  passwordRules,
  nameRules,
  streetRules,
  streetNrRules,
  zipRules,
  cityRules,
  loginPasswordRules,
};
