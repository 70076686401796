import * as Education from './education';
import * as Experience from './experience';
import * as Conditions from './conditions';
import * as Skill from './skill';
import * as Company from './company';
import {
  PositionDto,
  PositionStatus,
} from '../../functions/src/types/position';
import * as User from './user';
import { DeepPartial } from 'functions/src/types';

export interface Position {
  id: string;
  name: string;
  tasks?: string[];
  status: PositionStatus;
  school?: Education.Education[];
  education?: Education.Education[];
  practicalExperience?: Experience.Experience[];
  otherExperience?: Experience.Experience[];
  offering?: Conditions.Conditions;
  languages?: Skill.Skill[];
  softwareSkills?: Skill.Skill[];
  otherRequirements?: string[];
  company?: Company.Company;
  recruiter?: User.User;
  createdAt?: Date;
}

export const fromDto = (dto: PositionDto): Position => ({
  ...dto,
  recruiter: dto.recruiter ? User.fromDto(dto.recruiter) : undefined,
  status: dto.status ?? PositionStatus.DRAFT,
  school: dto.school ? dto.school.map(Education.fromDto) : [],
  education: dto.education ? dto.education.map(Education.fromDto) : [],
  practicalExperience: dto.practicalExperience
    ? dto.practicalExperience.map(Experience.fromDto)
    : [],
  otherExperience: dto.otherExperience
    ? dto.otherExperience.map(Experience.fromDto)
    : [],
  offering: dto.offering ? Conditions.fromDto(dto.offering) : undefined,
  softwareSkills: dto.softwareSkills
    ? dto.softwareSkills.map(Skill.fromDto)
    : [],
  languages: dto.languages ? dto.languages.map(Skill.fromDto) : [],
  createdAt: dto.createdAt?.toDate(),
});
export const toDto = (position: Position): DeepPartial<PositionDto> => ({
  ...position,
  recruiter: position.recruiter ? User.toDto(position.recruiter) : undefined,
  school: position.school ? position.school.map(Education.toDto) : [],
  practicalExperience: position.practicalExperience
    ? position.practicalExperience.map(Experience.toDto)
    : [],
  otherExperience: position.otherExperience
    ? position.otherExperience.map(Experience.toDto)
    : [],
  offering: position.offering ? Conditions.toDto(position.offering) : undefined,
  softwareSkills: position.softwareSkills
    ? position.softwareSkills.map(Skill.toDto)
    : [],
  languages: position.languages ? position.languages.map(Skill.toDto) : [],
});
