import { AnyKeyObject } from '.';
import * as Position from './position';
import * as MessageDto from './message';
import * as ScoreDto from './score';
import { Timestamp } from '../firebase';
import * as Candidate from './candidate';

export interface MatchUserStatus {
  liked: { true: string[]; false: string[] };
  archived: { true: string[]; false: string[] };
  seen: { true: string[]; false: string[] };
}

export interface MatchDto {
  id: string;
  candidate: Candidate.CandidateDto;
  position: Position.PositionDto;
  startedCommunication: boolean;
  messageCount: number;
  score: ScoreDto.ScoreDto;
  candidateScore: ScoreDto.ScoreDto;
  positionScore: ScoreDto.ScoreDto;
  lastMessage?: MessageDto.MessageDto;
  opponentRemoved?: boolean;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  userIds: string[];
  unseenMessagesCount: { [userId: string]: number };
  userStatus: MatchUserStatus;
}

export const fromData = (data: AnyKeyObject) => {
  return {
    id: data.id,
    candidate: Candidate.fromData(data.candidate),
    position: Position.fromData(data.position),
    messageCount: data.messageCount ?? 0,
    startedCommunication: data.startedCommunication ?? false,
    score: ScoreDto.fromData(data.score),
    candidateScore: ScoreDto.fromData(data.candidateScore),
    positionScore: ScoreDto.fromData(data.positionScore),
    lastMessage: data.lastMessage
      ? MessageDto.fromData(data.lastMessage)
      : undefined,
    opponentRemoved: data.opponentRemoved ?? false,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    userIds: data.userIds ?? [],
    unseenMessagesCount: data.unseenMessagesCount ?? {},
    userStatus: data.userStatus ?? {},
  };
};

export const fromFirestore = (doc: AnyKeyObject): MatchDto => {
  const data = doc.data();
  if (!doc || !doc.id || !data) throw new Error('Could not parse match');
  return fromData({ ...data, id: doc.id });
};
