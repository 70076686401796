// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyFromObj = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
};

export function isValidEnumValue<Type extends Record<string, unknown>>(
  e: Type,
  v: unknown
): boolean {
  return Object.values(e).indexOf(v) >= 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortArray(array: any[], key: string) {
  return array.sort(function (a, b) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}

export const arrayContainsAny = (array: any[], values: any[]) =>
  array.some((r) => values.includes(r));

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

export const sumOfAllValuesInObject = (obj: any): number => {
  return Object.keys(obj).reduce((sum, key) => {
    if (typeof obj[key] === 'object') {
      return sum + sumOfAllValuesInObject(obj[key]);
    }
    return sum + obj[key];
  }, 0);
};

export const roundNumber = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;
export const getSumOfArray = (array: number[]) => {
  return array.reduce((a, b) => a + b, 0);
};

export const yearToMillis = (year: number) => year * 365 * 24 * 60 * 60 * 1000;

export const truncateText = (
  text: string,
  maxCharacters = 32,
  appendix = '...'
) =>
  text.length > maxCharacters
    ? `${text.substring(0, maxCharacters)}${appendix}`
    : text;
