const navigationItemsBusiness = [
  {
    title: 'Wir suchen',
    subtitle: 'Unsere Anforderungen',
    icon: '$conditions',
    link: 'ConditionsBusiness',
    dashboard: true,
  },
  {
    title: 'Wir bieten',
    subtitle: 'Unser Angebot',
    icon: '$offerings',
    link: 'OfferingsBusiness',
    dashboard: true,
  },
  {
    title: 'Matches',
    subtitle: 'Jobsuchende, die zu uns passen',
    icon: '$matches',
    link: 'MatchesBusiness',
    dashboard: true,
  },
  {
    title: 'Matches',
    subtitle: 'Jobsuchende, die zu uns passen',
    icon: '$matches',
    link: 'MatchBusiness',
    dashboard: false,
    hide: true,
  },
  {
    title: 'Nachrichten',
    subtitle: 'Chats mit Jobsuchenden',
    icon: '$messages',
    link: 'MessagesBusiness',
    dashboard: true,
  },
  {
    title: 'Nachrichten',
    subtitle: 'Chats mit Jobsuchenden',
    icon: '$messages',
    link: 'MessageMatchBusiness',
    dashboard: false,
    hide: true,
  },
  {
    title: 'Einstellungen',
    subtitle: 'E-Mail, Passwort, Logout',
    icon: '$settings',
    link: 'Settings',
    dashboard: true,
  },
  {
    title: 'FAQ Jobsuchende',
    subtitle: 'Alles über Stairship',
    icon: '$faq',
    link: 'Faq',
    dashboard: false,
    hide: true,
  },
  {
    title: 'FAQ Unternehmen',
    subtitle: 'Alles über Stairship',
    icon: '$faq',
    link: 'FaqBusiness',
    dashboard: true,
    hide: true,
  },
];
export default { navigationItemsBusiness };
