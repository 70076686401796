<template lang="pug">
.secure.fill-height
  .flex-container.fill-height
    NavDrawer
    v-main
      InstallAppBanner
      v-container.mb-4.pb-4
        Header
        slot
        //- Onboarding(:showDialog="openOnboarding" @closeDialog="openOnboarding = false")
        PushNotifications
</template>

<script>
import NavDrawer from '@/components/navigation/NavDrawer';
import Header from '@/components/navigation/Header';
import PushNotifications from '@/components/notifications/PushNotifications';
// import Onboarding from '@/components/candidate/onboarding/onboarding.vue';
import InstallAppBanner from '@/components/navigation/InstallAppBanner';
export default {
  name: 'Secure',
  props: ['triggerOnboarding'],
  data() {
    return {
      openOnboarding: true,
    };
  },
  components: {
    NavDrawer,
    Header,
    PushNotifications,
    InstallAppBanner,
  },
  methods: {},
  watch: {
    triggerOnboarding(val) {
      this.openOnboarding = val;
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
.secure
  position: relative
  background: $background
.flex-container
  position: relative
.bottom-navigation
  position: fixed
  bottom: 0
  left: 0
  right: 0
</style>
