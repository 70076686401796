<template lang="pug">
div(v-if="!hideInstallAppBanner")
    v-banner.text-left.px-sm-3(v-if='deferredPrompt || (isIOS && !isInStandaloneMode)' color='accent' :single-line="!mobile" dark)
        v-icon(slot="icon" color="white" size="36") mdi-download-circle
        strong Stairship zum Startbildschirm hinzufügen
        template(v-slot:actions='')
            v-btn(text='' @click='dismiss' style="opacity: 0.7") Schließen
            v-btn(text='' color="white" @click='install') {{isIOS ? 'Hinzufügen' : 'Installieren'}}
    v-bottom-sheet(v-model="dialog" )
        v-sheet(style="border-top-right-radius: 12px; border-top-left-radius: 12px;")
            v-card.mx-auto(flat elevation="0" ) 
                v-toolbar(flat)
                    v-card-title Stairship installieren
                    v-spacer 
                    v-btn(@click="dialog = false" icon)
                        v-icon mdi-close
                v-card-text.px-8()
                    span Installiere diese App auf Deinem Startbildschirm, um unterwegs schnell und einfach darauf zugreifen zu können.
                v-card-actions.pt-0.mt-8(style="background-color: #eee")
                    v-img.mx-auto(max-width="360" src='@/assets/img/ios-install.png')

</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      hideInstallAppBanner: false,
      deferredPrompt: null,
      dialog: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isInStandaloneMode() {
      return 'standalone' in window.navigator && window.navigator.standalone;
    },
    isIOS() {
      return (
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      );
    },
  },
  created() {
    this.hideInstallAppBanner =
      localStorage.getItem('hideInstallAppBanner') || false;
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.hideInstallAppBanner = true;
      localStorage.setItem('hideInstallAppBanner', true);
    },
    async install() {
      if (this.isIOS) {
        this.dialog = true;
      } else if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.banner-wrapper-secure
  margin-top: 55px
  margin-bottom: -55px
.actions
  border-top: 2px solid red
  background-color: #efefef !important
</style>
