<template lang="pug">
div 
    v-snackbar(v-model="success" rounded="pill" color="accent") 
      v-icon.mr-4(left color="white") mdi-check-bold
      | Wir haben Dir eine E-Mail geschickt
    v-dialog(v-model='dialog' max-width="380" persistent)
        template(v-slot:activator='{ on, attrs }')
            v-btn.text-transform-none.mt-5(
                block,
                text='',
                v-bind='attrs' v-on='on'

              ) Passwort vergessen?
        v-card
          div
            v-toolbar(flat color="transparent")
              v-toolbar-title.text-h6.dialog-title.font-weight-bold Passwort vergessen?
              v-spacer
              v-btn(icon @click='closeDialog')
                v-icon mdi-close
            v-divider
          v-card-text.pt-6.px-4
            //- v-alert(type="error" v-if="error") {{error}}
            v-form(v-model='formIsValid' v-on:submit.prevent :key="formKey")
                  v-text-field(v-model='email' :rules='emailRules' dense outlined :label='`${businessRoute ? "Ihre":"Deine"} E-Mail-Adresse`' type="email")
          v-divider
          v-card-actions.px-4
            v-spacer
            v-btn(color='grey' text @click='closeDialog') Abbrechen
            v-btn.white--text(@click='resetPassword' color='accent darken-1' depressed :disabled='loading || !formIsValid') Zurücksetzen
        
</template>
<script>
import Vue from 'vue';
import FormRules from '@/utilities/FormRules';
export default Vue.extend({
  data() {
    return {
      formKey: Math.random(),
      ...FormRules,
      dialog: false,
      success: false,
      email: undefined,
      formIsValid: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
    error() {
      return this.$store.state.user.error;
    },
    businessRoute() {
      return this.$route.path.includes('/b');
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('user/setError', '');
      this.dialog = false;
    },
    resetPassword() {
      this.$store
        .dispatch('user/resetPassword', {
          email: this.email,
        })
        .then(() => {
          this.email = undefined;
          this.closeDialog();
          this.success = true;
        });
    },
  },
});
</script>
