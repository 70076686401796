<template lang="pug">
.navigation
  v-app-bar.d-none.d-sm-block.d-lg-none(color='transparent', :app="tablet", flat, fixed)
    v-app-bar-nav-icon(@click='drawer = !drawer') 

  v-app-bar.d-inline-block.d-sm-none.shadow(color='white', :app="tablet",  fixed)
    router-link(:to='{ name: "DashboardBusiness" }'  tag="div")
      v-img.mt-1(width="180" contain src='@/assets/img/logo/STAIRZ_Logo_Wort-Bildmarke_Header.svg')
    v-spacer
    v-btn(icon='', small, :to='{ name: "FaqBusiness" }')
      v-icon(color='accent') mdi-help-circle
    router-link(:to='{ name: "DashboardBusiness" }'  tag="div")
      v-avatar.ml-2(size='35', v-if='company' :color="company.logoURL ? 'white' : 'accent'")
        Avatar(:src="company.logoURL" :business="true" :small="true" :accent="true")

  v-navigation-drawer.navigation-drawer(
   clipped-left='',
    flat,
    app='',
    v-model='drawer',
    no-border
    v-if="!mobile"
  )
    
    v-list
      v-list-item.logoLink.px-2.removeActiveBackground( :to='{ name: "DashboardBusiness" }')
        v-list-item-title
          v-img.ml-3.mb-8.mt-8(
            src='@/assets/img/logo/STAIRZ_Logo_Wort-Bildmarke_Footer.svg',
            width='100'
          )
    DialogSwitchJobOffer(v-if="isBusiness")
    v-list-item(
      v-for='item in navigationItemsBusiness',
      :key='item.title',
      :to='{ name: item.link }',
      @click="clickedNavigationItem(item)"
      v-if='!item.hide'
    )
      v-list-item-icon
        v-icon {{ item.icon }}
      v-list-item-content
        v-list-item-title {{ item.title }}
          v-avatar(v-if="item.title === 'Matches' && matchCount" class="drawer-count-chip" color="#ff5a5a" size="23") 
            strong {{matchCount}}
          v-avatar(v-if="item.title === 'Nachrichten' && messagesCount" class="drawer-count-chip" color="#ff5a5a" size="23") 
            strong {{messagesCount}}
    template(v-slot:append='')
      .mb-6
        //- DialogSwitchJobOffer(v-if="isBusiness")
        v-list-item(v-if='company', :to='{ name: "DashboardBusiness" }')
          v-list-item-avatar(:color="company.logoURL ? 'white' : 'accent'")
            Avatar(:src="company.logoURL" :business="true" :small="true" :accent="true")
            //-v-img(:src='company.logoURL ? company.logoURL : ""')
              template(v-slot:placeholder)
                v-row.fill-height.ma-0(align='center', justify='center')
                  v-icon(color='white' size="16") mdi-image-outline
          v-list-item-content
            v-list-item-title {{ company.name  }}
          v-list-item-action
            v-btn(icon='', small, :to='{ name: "FaqBusiness" }')
              v-icon(color='accent') mdi-help-circle
  v-bottom-navigation.bottom-navigation.d-flex.d-sm-none( grow exact app color='accent')
    v-btn(v-for='item in navigationItemsBusiness',
      :key='item.title',
      v-if='!item.hide'
      :to='{ name: item.link }',
      @click="clickedNavigationItem(item)"
      active-class="")
      //- span {{item.title}}
      v-icon {{ item.icon }}
      v-avatar(v-if="item.title === 'Matches' && matchCount" class="bottom-navigation-count-chip" color="#ff5a5a" size="18") 
          strong {{matchCount}}
      v-avatar(v-if="item.title === 'Nachrichten' && messagesCount" class="bottom-navigation-count-chip" color="#ff5a5a" size="18") 
          strong {{messagesCount}}
  
</template>

<script>
import navigationItemsBusiness from '@/router/navigationItemsBusiness';
import DialogSwitchJobOffer from '@/components/navigation/DialogSwitchJobOffer';
import Avatar from '@/components/elements/Avatar.vue';
export default {
  components: {
    DialogSwitchJobOffer,
    Avatar,
  },
  data() {
    return {
      drawer: true,
      logo: '@/assets/img/STAIRZ_Logo.png',
      ...navigationItemsBusiness,
    };
  },

  computed: {
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
    company() {
      return this.$store.state.business.company;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    tablet() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    matchCount() {
      return this.$store.state.business.matchesIndicatorCount;
    },
    messagesCount() {
      // return 14;
      return this.$store.getters['business/unreadMessagesCount']();
    },
  },
  methods: {
    clickedNavigationItem(item) {
      const needNavigation = this.$route.name !== item.link;
      if (item.title === 'Matches' && !needNavigation) {
        this.$store.commit('business/setSelectedMatchesSection', 'Matches');
        this.$store.dispatch('business/fetchMatches', {
          section: this.selectedOption,
          reset: true,
        });
      }
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
.drawer-count-chip
  margin-left: 10px
  strong
    font-size: 0.8rem
    color: white

.bottom-navigation-count-chip
  // margin-left: 20px
  margin-top: -18px
  strong
    font-size: 0.6rem
    color: white

.app-bar-logo
  max-height: 25px
.navigation-drawer
  svg
    fill: $accent

.bottom-navigation
  position: fixed !important
  max-width: 100%
  bottom: 0
  right: 0
  left: 0
  z-index: 7 !important

  .v-btn
    min-width: 40px !important
    svg
      fill: $accent
    &.v-btn--active
      background: rgba($accent, 1) !important
      svg
        fill: $secondary

.removeActiveBackground
  &::before
    background-color: #fff
.logoLink.v-list-item--active::before
  opacity: 0
</style>
