import { AnyKeyObject } from '.';

export interface CountryDto {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
}

export const fromData = (data: AnyKeyObject) => {
  if (!data) return;
  return {
    id: data.id,
    name: data.name,
    alpha2: data.alpha2,
    alpha3: data.alpha3,
  };
};

export const fromFirestore = (snapshot: AnyKeyObject) => {
  const data = snapshot.data();
  if (!snapshot || !data) throw new Error('Could not parse country');
  return fromData({ ...data, id: snapshot.id });
};
