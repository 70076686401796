import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { auth } from '../firebase';
import { routes } from './routes';
import { routesBusiness } from './routes.business';
import { store } from '../store';
import { Api } from '@/api';
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    ...routesBusiness,
    // redirect unknown routes to login (instead of 404 page)
    {
      path: '*',
      redirect: { name: 'Login' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach(async (to, from, next) => {
  const requiresBusiness = to.matched.some(
    (x) => x.meta.userRole == 'business'
  );
  const requiresCandidate = to.matched.some(
    (x) => x.meta.userRole == 'candidate'
  );
  const requiresDefault = to.matched.some((x) => x.meta.userRole == 'default');

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (
    requiresAuth &&
    !auth.currentUser &&
    to.path !== '/login' &&
    to.path !== '/b/login'
  ) {
    const path = requiresBusiness ? 'b/login' : '/login';
    const query = {
      ...to.query,
      ...from.query,
      redirect: to.fullPath,
      positionId: undefined,
    };

    next({
      path,
      query,
    });
  } else if (
    requiresAuth &&
    auth.currentUser &&
    !auth.currentUser.emailVerified &&
    !to.path.includes('/verify')
  ) {
    const userApi = Api().user();
    const currentUser = store.state.user.currentUser
      ? store.state.user.currentUser
      : await userApi.getUserById(auth?.currentUser?.uid ?? '');
    const isBusiness = currentUser.type == 'business';

    const path = isBusiness ? '/b/verify' : '/verify';
    next({ path, query: { ...to.query, ...from.query } });
  } else {
    if (auth.currentUser) {
      if (
        to.name === 'register' ||
        to.name === 'Login' ||
        to.name === 'registerBusiness' ||
        to.name === 'LoginBusiness'
      ) {
        const path = requiresBusiness ? 'b/dashboard' : '/dashboard';
        next(path);
      } else {
        await CheckUserType(
          requiresBusiness,
          requiresCandidate,
          requiresDefault,
          next,
          to
        );
      }
    } else {
      next();
    }
  }
});
router.afterEach(async (to, from) => {
  const redirectTo = from.query?.redirect;

  if (
    (from.name == 'Login' || from.name == 'LoginBusiness') &&
    to.name !== 'Login' &&
    to.name !== 'LoginBusiness' &&
    redirectTo &&
    typeof redirectTo === 'string'
  ) {
    router.replace(redirectTo);
  }
});
export default router;

async function CheckUserType(
  requiresBusiness: boolean,
  requiresCandidate: boolean,
  requiresDefault: boolean,
  next: NavigationGuardNext<Vue>,
  to: Route
) {
  const userApi = Api().user();
  const currentUser = store.state.user.currentUser
    ? store.state.user.currentUser
    : await userApi.getUserById(auth?.currentUser?.uid ?? '');
  if (currentUser) {
    const isBusiness = currentUser.type == 'business';
    const isCandidate = currentUser.type == 'candidate';

    if (
      (requiresBusiness && isBusiness) ||
      (requiresCandidate && isCandidate) ||
      requiresDefault
    ) {
      next();
    } else {
      const newRoute = isBusiness
        ? `${to.name}Business`
        : `${to.name?.replace('Business', '')}`;

      next({ name: newRoute, params: to.params, query: to.query });
    }
  } else {
    next();
  }
}
