import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import { dateToISO } from './utilities/DateAndTime';

const region = 'europe-west3';
// firebase init - add your own config here
const firebaseConfig = {
  STAGING: {
    apiKey: 'AIzaSyAB_0kyJ0IX-giFfCfLOoA6st3n4NgjCH4',
    authDomain: 'stairz-staging.firebaseapp.com',
    projectId: 'stairz-staging',
    storageBucket: 'stairz-staging.appspot.com',
    messagingSenderId: '246279201928',
    appId: '1:246279201928:web:c5f23216361c73e278ab3d',
  },
  PRODUCTION: {
    apiKey: 'AIzaSyDtCTnrNQBTai9U6QCHwQUCk5OuqHgeBgs',
    authDomain: 'stairz-production.firebaseapp.com',
    projectId: 'stairz-production',
    storageBucket: 'stairz-production.appspot.com',
    messagingSenderId: '854239328857',
    appId: '1:854239328857:web:e5e700364151c7a0be0f48',
    measurementId: 'G-ZKJ32XZ287',
    databaseURL:
      'https://stairz-production-default-rtdb.europe-west1.firebasedatabase.app/',
  },
};

const production = process.env.VUE_APP_ENV === 'production';

const app = firebase.initializeApp(
  production ? firebaseConfig.PRODUCTION : firebaseConfig.STAGING
);

firebase.analytics();
let messaging: null | firebase.messaging.Messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

// utils
const db = firebase.firestore();
const functions = firebase.app().functions(region);
const realtimeDatabase = firebase.database(app);
db.settings({ ignoreUndefinedProperties: true, merge: true });
const FieldValue = firebase.firestore.FieldValue;
const auth = firebase.auth();
const storage = firebase.storage();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
const Timestamp = firebase.firestore.Timestamp;
const timestampToISOString = (timestamp: firebase.firestore.Timestamp) =>
  dateToISO(timestamp.toDate());
const timestampFromMillis = (millis: number) => Timestamp.fromMillis(millis);
auth.languageCode = 'de';
// collection references
const usersCollection = db.collection('_users');
const candidatesCollection = db.collection('_candidates');
const positionsCollection = db.collection('_positions');
const companiesCollection = db.collection('_companies');
const faqCollection = db.collection('_faqs');
const matchesCollection = db.collection('_matches');
const chatPresenceCollection = db.collection('_chatPresence');
const messagesCollection = (matchId: string) =>
  matchesCollection.doc(matchId).collection('_messages');
const settingsCollection = (userId: string) =>
  usersCollection.doc(userId).collection('_settings');
const settingsNotificationsDoc = (userId: string) =>
  settingsCollection(userId).doc('_notifications');

// storage references
const storageRef = storage.ref();
const userStorageRef = storageRef.child('users');
const companyStorageRef = storageRef.child('company');
// types
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type MergeFields = (firebase.firestore.FieldPath | string)[];
type SetOptions = firebase.firestore.SetOptions;
type DocumentData = firebase.firestore.DocumentData;
const RealtimeDatabase = firebase.database;
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
type QuerySnapshot = firebase.firestore.QuerySnapshot;

// uncomment to use emulators
// if (window.location.hostname === 'localhost') {
//   db.useEmulator('localhost', 8080);
//   storage.useEmulator('localhost', 9199);
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   //@ts-ignore
//   auth.useEmulator('http://localhost:9099', { disableWarnings: true });
//   realtimeDatabase.useEmulator('localhost', 9000);
//   functions.useEmulator('localhost', 5001);
// }

// export utils/refs
export {
  db,
  realtimeDatabase,
  auth,
  messaging,
  storageRef,
  userStorageRef,
  companyStorageRef,
  candidatesCollection,
  positionsCollection,
  companiesCollection,
  chatPresenceCollection,
  settingsNotificationsDoc,
  messagesCollection,
  matchesCollection,
  usersCollection,
  faqCollection,
  DocumentSnapshot,
  serverTimestamp,
  MergeFields,
  SetOptions,
  DocumentData,
  Timestamp,
  FieldValue,
  timestampToISOString,
  RealtimeDatabase,
  EmailAuthProvider,
  production as isProduction,
  timestampFromMillis,
  functions,
  QuerySnapshot,
};
