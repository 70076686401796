import { RouteConfig } from 'vue-router';
import Login from '../views/auth/Login.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      layout: 'auth',
      userRole: 'default',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
    meta: {
      requiresAuth: false,
      layout: 'auth',
      userRole: 'default',
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: () =>
      import(/* webpackChunkName: "verify" */ '../views/auth/Verify.vue'),
    meta: {
      requiresAuth: false,
      layout: 'auth',
      userRole: 'default',
    },
  },
  {
    path: '/einstellungen',
    name: 'Settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'default',
    },
  },
  {
    path: '/impressum',
    name: 'Imprint',
    beforeEnter() {
      window.open('https://stairship.com/impressum', '_blank');
    },
    meta: {
      userRole: 'default',
    },
  },
  {
    path: '/datenschutz',
    name: 'DataPrivacy',
    beforeEnter() {
      window.open('https://stairship.com/datenschutz', '_blank');
    },
    meta: {
      userRole: 'default',
    },
  },
  {
    path: '/agb',
    name: 'Terms',
    beforeEnter() {
      window.open('https://stairship.com/agb-jobsuchende', '_blank');
    },
    meta: {
      userRole: 'default',
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    beforeEnter() {
      window.open('https://stairship.com/faq-jobsuchende', '_blank');
    },
    meta: {
      userRole: 'default',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '../views/candidate/Dashboard.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '../views/candidate/Onboarding.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'clear',
      userRole: 'candidate',
    },
  },
  {
    path: '/matches',
    name: 'Matches',
    component: () =>
      import(
        /* webpackChunkName: "matches" */ '../views/candidate/Matches.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
  {
    path: '/matches/:mid',
    name: 'Match',
    component: () =>
      import(
        /* webpackChunkName: "match" */ '../views/candidate/MatchDetails.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
  {
    path: '/nachrichten/:mid',
    name: 'MessageMatch',
    component: () =>
      import(
        /* webpackChunkName: "MessageMatch" */ '../views/candidate/MatchDetails.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
  {
    path: '/ich-biete',
    name: 'Offerings',
    component: () =>
      import(
        /* webpackChunkName: "offerings" */ '../views/candidate/Offerings.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
  {
    path: '/ich-suche',
    name: 'Conditions',
    component: () =>
      import(
        /* webpackChunkName: "conditions" */ '../views/candidate/LookingFor.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
  {
    path: '/nachrichten',
    name: 'Messages',
    component: () =>
      import(
        /* webpackChunkName: "messages" */ '../views/candidate/Messages.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'candidate',
    },
  },
];
