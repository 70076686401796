import { AnyKeyObject } from '.';

export enum NotificationType {
  PUSH = 'push',
  EMAIL = 'email',
}
export enum NotificationCategory {
  MESSAGES = 'messages',
  MATCHES = 'matches',
  RECOMMENDATIONS = 'recommendations',
}

export interface NotificationSettingsDto {
  fcmTokens?: string[];
  [NotificationType.EMAIL]: NotificationCategory[];
  [NotificationType.PUSH]: NotificationCategory[];
}

export const fromData = (data: AnyKeyObject) => {
  const notificationSettings: NotificationSettingsDto = {
    fcmTokens: data?.fcmTokens,
    [NotificationType.PUSH]: data ? data[NotificationType.PUSH] || [] : [],
    [NotificationType.EMAIL]: data ? data[NotificationType.EMAIL] || [] : [],
  };
  return notificationSettings;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  // if (!doc || !doc.id || !data) throw new Error('Could not parse settings');
  return fromData({ ...data, id: doc.id });
};
