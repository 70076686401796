import * as MatchesCollection from '@/api/collections/matches';
import { DocumentData, QuerySnapshot } from '@/firebase';
import * as Match from '@/types/match';

const getAllMatchesForCandidate = async (
  candidateId: string,
  section: string,
  lastVisible?: Match.Match
) =>
  MatchesCollection.listForCandidate(
    candidateId,
    section,
    lastVisible
  ).then((matches) => matches.map(Match.fromDto));

const getMatchesWithConversationForCandidate = async (candidateId: string) => {
  return MatchesCollection.getMatchesWithConversationForCandidate(
    candidateId
  ).then((matches) => matches.map(Match.fromDto));
};

const getMatchesWithConversationForPosition = async (positionId: string) => {
  return MatchesCollection.getMatchesWithConversationForPosition(
    positionId
  ).then((matches) => matches.map(Match.fromDto));
};

const subscribeToMatchesIndicatorCountForPosition = (
  positionId: string,
  callback: (count: number) => void
) =>
  MatchesCollection.subscribeToMatchesIndicatorCountForPosition(
    positionId,
    callback
  );

const subscribeToPositionState = (
  positionId: string,
  callback: (data?: DocumentData) => void
) => MatchesCollection.subscribeToPositionState(positionId, callback);

const subscribeToMatchesIndicatorCountForCandidate = (
  candidateId: string,
  callback: (count: number) => void
) =>
  MatchesCollection.subscribeToMatchesIndicatorCountForCandidate(
    candidateId,
    callback
  );

const subscribeToCandidateState = (
  candidateId: string,
  callback: (data?: DocumentData) => void
) => MatchesCollection.subscribeToCandidateState(candidateId, callback);

const getAllMatchesForPosition = async (
  positionId: string,
  section: string,
  lastVisible?: Match.Match
) =>
  MatchesCollection.listForPosition(
    positionId,
    section,
    lastVisible
  ).then((matches) => matches.map(Match.fromDto));

const getMatch = async (matchId: string) =>
  MatchesCollection.get(matchId).then(Match.fromDto);

const markConversationStartForMatch = async (matchId: string) => {
  return MatchesCollection.markConversationStartForMatch(matchId).then(
    Match.fromDto
  );
};

const archiveMatch = async (matchId: string, archive: boolean) => {
  return MatchesCollection.archiveMatch(matchId, archive).then(Match.fromDto);
};
const likeMatch = async (matchId: string, liked: boolean) => {
  return MatchesCollection.likeMatch(matchId, liked).then(Match.fromDto);
};

const subscribeToMatchesForCandidate = (
  candidateId: string,
  callback: (snapshot: QuerySnapshot) => void
) => MatchesCollection.subscribeToMatchesForCandidate(candidateId, callback);

const subscribeToMatchesForPosition = (
  positionId: string,
  callback: (snapshot: QuerySnapshot) => void
) => MatchesCollection.subscribeToMatchesForPosition(positionId, callback);

const subscribeToMatchesWithConversationForCandidate = (
  candidateId: string,
  callback: (snapshot: QuerySnapshot) => void
) =>
  MatchesCollection.subscribeToMatchesWithConversationForCandidate(
    candidateId,
    callback
  );

const subscribeToMatchesWithConversationForPosition = (
  positionId: string,
  callback: (snapshot: QuerySnapshot) => void
) =>
  MatchesCollection.subscribeToMatchesWithConversationForPosition(
    positionId,
    callback
  );

const markConversationForMatchActive = (matchId: string, active: boolean) =>
  MatchesCollection.markConversationForMatchActive(matchId, active);

const markMatchSeen = (matchId: string) =>
  MatchesCollection.markMatchSeen(matchId);

export const MatchesApi = () => ({
  getAllMatchesForCandidate,
  getAllMatchesForPosition,
  getMatch,
  markConversationStartForMatch,
  likeMatch,
  archiveMatch,
  subscribeToMatchesWithConversationForCandidate,
  subscribeToMatchesWithConversationForPosition,
  markConversationForMatchActive,
  markMatchSeen,
  subscribeToMatchesIndicatorCountForPosition,
  subscribeToMatchesIndicatorCountForCandidate,
  getMatchesWithConversationForCandidate,
  getMatchesWithConversationForPosition,
  subscribeToMatchesForCandidate,
  subscribeToMatchesForPosition,
  subscribeToPositionState,
  subscribeToCandidateState,
});
