<template lang="pug">
.header.mt-lg-4
  .d-flex.flex-column.align-sm-center.flex-sm-row(v-if='currentRoute != null')
    .headerIcon.d-flex.text-left.text-md-center.mb-0.pb-0
      v-icon.headerIcon(:size='mobile ? "40px" : "3rem"', color='accent') {{ currentRoute.icon }}
    div
      h2 {{ title  ? title :  currentRoute.title }}
      strong {{ subtitle ? subtitle : currentRoute.subtitle }}
  .d-flex.flex-column.align-sm-center.flex-sm-row(v-else-if='currentUser')
    .headerIcon.d-flex.text-left.text-md-center.mb-0.pb-0
      v-avatar(:size='mobile ? "40px" : "3rem"')
        v-img.accent(:src='currentUser.photoURL' @load="imageError = false" @error="imageError = true")
          template(v-slot:default v-if="showImagePlaceholder")
            v-row.fill-height.ma-0(align='center', justify='center')
              v-icon(color='white') mdi-account-circle
    div
      h2 Dashboard
      strong {{ label }}
   
  .status.mt-8.mb-8
    v-chip.statusChip(v-if="showPercentage" color="grey" dark small ) {{percentage}}% Match
    DialogSwitchJobOffer.mt-8.mb-8(v-if="mobile && isBusiness && !isDetailHeader")
    v-divider.divider(v-else)
  
  

</template>
<script>
import Vue from 'vue';
import navigationItems from '@/router/navigationItems';
import navigationItemsBusiness from '@/router/navigationItemsBusiness';
import DialogSwitchJobOffer from '@/components/navigation/DialogSwitchJobOffer';
import { UserType } from '@/types/user';

export default Vue.extend({
  props: ['link', 'title', 'subtitle', 'percentage', 'isDetailHeader'],
  components: { DialogSwitchJobOffer },
  data() {
    return {
      imageError: false,
    };
  },
  computed: {
    currentRoute() {
      const routeName = this.link || this.$route.name;

      const currentPage = navigationItems.navigationItems.find(
        (item) => item.link == routeName
      );
      const currentPageBusiness =
        navigationItemsBusiness.navigationItemsBusiness.find(
          (item) => item.link == routeName
        );
      if (this.link) {
        return this.isBusiness ? currentPage : currentPageBusiness;
      }
      return this.isBusiness ? currentPageBusiness : currentPage;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    label() {
      if (this.currentUser.type === UserType.BUSINESS) {
        return `Schön, dass Sie hier sind, ${this.currentUser.firstName}!`;
      } else {
        return `Schön, dass Du hier bist, ${this.currentUser.firstName}!`;
      }
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
    showPercentage() {
      return !!this.percentage || this.percentage === 0;
    },
    showImagePlaceholder() {
      return (
        this.imageError ||
        this.currentUser.photoURL === '' ||
        !this.currentUser.photoURL
      );
    },
  },
});
</script>
<style lang="sass">
@import "@/assets/style/main"

.header
  .headerIcon
    width: 70px
  h2, strong
    color: $primary
  svg
    fill: $primary
  .status
    position: relative

    .statusChip
      position: absolute
      top: -12px
      left: 16px


// @media #{map-get($display-breakpoints, 'md-and-down')}
//   .statusChip
//       left: 0px !important
</style>
