import { areaCategories } from '@/assets/selections/areas';
import { AreaItemDto } from '../../functions/src/types/area';
export interface AreaCategory {
  id: string;
  name: string;
  items: AreaItem[];
}

export interface AreaItem {
  id: string;
  name: string;
  durationDays?: number;
  areaCategory?: AreaCategory;
}

export const getAreaById = (id: string) => {
  const areas = areaCategories()
    .map((areaCategory) => areaCategory.items)
    .flat(1);
  return areas.find((area) => area.id === id);
};

export const fromDto = (dto: AreaItemDto): AreaItem => ({
  ...dto,
});

export const toDto = (item: AreaItem): AreaItemDto => ({
  ...item,
});
