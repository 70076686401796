import { UserType } from './user';
import { AnyKeyObject } from '.';

export interface FaqDto {
  title: string;
  text: string;
  type: UserType;
}

export const fromData = (data: AnyKeyObject) => {
  const faq: FaqDto = {
    title: data.title,
    text: data.text,
    type: data.type,
  };
  return faq;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !doc.id || !data) throw new Error('Could not parse faq entry');
  return fromData({ ...data, id: doc.id });
};
