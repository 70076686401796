import {
  startOfMonth,
  lastDayOfMonth,
  intlFormat,
  parseISO,
  differenceInYears,
  formatISO,
  differenceInDays,
} from 'date-fns';

export const monthsBetween = (d1: Date, d2: Date) => {
  const firstDate = startOfMonth(d1);
  const lastDate = startOfMonth(d2);
  const year1 = firstDate.getFullYear();
  const year2 = lastDate.getFullYear();
  let month1 = firstDate.getMonth();
  let month2 = lastDate.getMonth();

  if (month1 === 0) {
    //Have to take into account
    month1++;
    month2++;
  }
  return (year2 - year1) * 12 + (month2 - month1) + 1;
};

export const getDurationString = (duration: number) => {
  if (duration == 1) {
    return `${duration} Monat`;
  } else if (duration < 12) {
    return `${duration} Monate`;
  } else {
    const years = Math.floor(duration / 12);
    const months = duration % 12;

    let durationString = '';
    if (years === 1) {
      durationString += `${years} Jahr`;
    } else {
      durationString += `${years} Jahre`;
    }

    if (months === 1) {
      durationString += ` und ${months} Monat`;
    } else if (months > 1) {
      durationString += ` und ${months} Monate`;
    }

    return durationString;
  }
};

export const getFormattedDuration = (date1: Date, date2: Date) => {
  const duration = monthsBetween(date1, date2);
  return getDurationString(duration);
};

export const getPeriodString = (
  start?: string | null,
  end?: string | null,
  addDistance = false,
  monthOnly = false
) => {
  let periodString = '';

  if (start) {
    periodString +=
      (monthOnly ? formatDateMonth(start) : formatDate(start)) + ' bis ';
  } else {
    return '-';
  }
  if (end) {
    periodString += monthOnly ? formatDateMonth(end) : formatDate(end);
  } else {
    periodString += 'heute';
  }
  if (addDistance && start) {
    const firstDate = startOfMonth(new Date(start));
    const lastDate = lastDayOfMonth(end ? new Date(end) : Date.now());
    periodString += ` (${getFormattedDuration(firstDate, lastDate)})`;
  }

  return periodString;
};

export const formatDate = (date: string) => {
  return date
    ? intlFormat(
        parseISO(date),
        {
          localeMatcher: 'lookup',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        },
        { locale: 'de-DE' }
      )
    : '';
};
export const formatDateMonth = (date: string) => {
  return date
    ? intlFormat(
        parseISO(date),
        {
          localeMatcher: 'lookup',
          month: '2-digit',
          year: 'numeric',
        },
        { locale: 'de-DE' }
      )
    : '';
};

export const yearFromDate = (date: string) => {
  return date
    ? intlFormat(
        parseISO(date),
        {
          localeMatcher: 'lookup',
          year: 'numeric',
        },
        { locale: 'de-DE' }
      )
    : '';
};

export const calculateAge = (birthdate: string) => {
  const years = differenceInYears(new Date(), Date.parse(birthdate));
  if (years === 1) {
    return `${years} Jahr`;
  } else {
    return `${years} Jahre`;
  }
};

export const formatVacationDays = (number: number) => {
  if (!number || number < 4) return '-';
  return `${number || 0} Tage`;
};
export const formatDurationYears = (number: number) => {
  if (number < 1) {
    return '-';
  } else if (number === 1) {
    return number + ' Jahr';
  } else {
    return number + ' Jahre';
  }
};

export const getDaysBetweenDates = (start: Date, end: Date) => {
  return differenceInDays(end, start);
};

export const dateToISO = (date: Date | number) => {
  return formatISO(date, {
    representation: 'date',
  });
};

export const getCurrentDateISO = () => {
  return dateToISO(Date.now());
};
