<template lang="pug">    
v-row.pt-4(justify='center' no-gutters='')
        v-col.py-4.text-center.d-flex.justify-center.gap-2.legalLink-wrapper(cols='12')
          router-link.my-2.legalLink(v-for='link in getLinks'  :to="{name: link.link}" color="accent" :key='link.link')
            | {{ link.name }}

        v-col.py-4.text-center(cols='12')
          |© {{ new Date().getFullYear() }} &mdash; 
          strong STAIRZ GmbH 
          | &mdash; Burgmüllerstr. 57 &#183; 40235 Düsseldorf &#183; AG Düsseldorf HRB 93081 &#183; GF: Jan Philipp Schotenroehr
        v-col.pt-4.pb-2.text-center(cols='12')
          v-btn(href="https://www.instagram.com/stairship" target="_blank" icon)
            v-icon(size="30") mdi-instagram
          v-btn(href="https://www.facebook.com/stairship" target="_blank" icon)
            v-icon(size="30") mdi-facebook
          v-btn(href="https://www.youtube.com/@stairship8533" target="_blank" icon)
            v-icon(size="30") mdi-youtube
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Home',
  components: {},
  props: ['type'],
  data() {
    return {
      footerLinks: [
        { name: 'FAQ Jobsuchende', link: 'Faq' },
        { name: 'FAQ Unternehmen', link: 'FaqBusiness' },
        { name: 'AGB Jobsuchende', link: 'Terms' },
        { name: 'AGB Unternehmen', link: 'TermsBusiness' },
        { name: 'Datenschutz', link: 'DataPrivacy' },
        { name: 'Impressum', link: 'Imprint' },
      ],
      footerLinksBusiness: [
        { name: 'FAQ Unternehmen', link: 'FaqBusiness' },
        { name: 'AGB Unternehmen', link: 'TermsBusiness' },
        { name: 'Datenschutz', link: 'DataPrivacy' },
        { name: 'Impressum', link: 'Imprint' },
      ],
      footerLinksCandidate: [
        { name: 'FAQ Jobsuchende', link: 'Faq' },
        { name: 'AGB Jobsuchende', link: 'Terms' },
        { name: 'Datenschutz', link: 'DataPrivacy' },
        { name: 'Impressum', link: 'Imprint' },
      ],
    };
  },
  computed: {
    getLinks() {
      switch (this.type) {
        case 'business':
          return this.footerLinksBusiness;
        case 'candidate':
          return this.footerLinksCandidate;

        default:
          return this.footerLinks;
      }
    },
  },
});
</script>
<style lang="scss">
@import '@/assets/style/main';
.legalLink-wrapper {
  gap: 12px;
  flex-wrap: wrap;
  .legalLink {
    color: $accent !important;
    font-weight: 600;
    text-decoration: none;
    text-wrap: nowrap;
  }
}
</style>
