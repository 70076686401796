<template lang="pug">
    v-img.grey(:src="src" :class="[accent ? 'accent' : 'grey']" contain @load="imageError = false" @error="imageError = true")
      template(v-slot:default v-if="showImagePlaceholder")
        v-row.fill-height.ma-0(align='center', justify='center')
          v-icon(color='white', :size='small ? 16 : business ? 30 : 50') {{business ? 'mdi-image-outline' : 'mdi-account-circle'}}
</template>
<script>
export default {
  props: ['src', 'business', 'small', 'accent'],
  data() {
    return {
      imageError: false,
    };
  },
  computed: {
    showImagePlaceholder() {
      return this.imageError || this.src === '' || !this.src;
    },
  },
};
</script>
