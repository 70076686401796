<template lang="pug">
.switchJobOffer 
    AddPositionDialog(v-if="positions.length == 0" v-on:createdNewPosition="dismissDialog")
    v-dialog(v-model='manageDialog' v-else width='500px' scrollable persistent='')
      template(v-slot:activator='{ on, attrs }')
        v-list-item.px-2.mb-2.accent(v-on='on')
          v-list-item-title.white--text.text-strong {{ currentPositionName || positionSelectTitle}}
          v-list-item-icon
              v-icon(color="white" v-if="positions.length > 0") mdi-menu-down
              v-icon(color="white" v-else) mdi-plus-circle-outline
      v-card.d-flex.flex-column
        div 
          v-toolbar(flat color="transparent")
            v-toolbar-title.text-h6.dialog-title.font-weight-bold Stellen
            v-spacer 
            AddPositionDialog(type="simple" v-on:createdNewPosition="dismissDialog")
          v-divider
        v-card-text.px-0.flex-grow-1.pb-0
          v-list.py-0(two-line='' v-if="positions.length > 0")
              v-list-item-group(v-model='selected' active-class='accent--text' mandatory)
                  template(v-for='(item, index) in positions')
                      v-list-item.flex.align-center(:key='item.name' :value="index")
                          template(v-slot:default='{ active }')
                              v-list-item-avatar(:color="active ? 'accent' : 'grey lighten-4'")
                                v-icon(v-if='!active' color='grey lighten-1')
                                    | mdi-check-outline
                                v-icon(v-else='' color='white')
                                    | mdi-check-bold
                              v-list-item-content
                                  v-list-item-title.text-bold(v-text='item.name')
                                  v-list-item-subtitle(v-text='item.description')
                              //- v-list-item-action
                                //- AddPositionDialog(:position="item" :simple="false")
                              v-list-item-action
                                DeletePositionDialog(:position="item")
                              
                      v-divider(v-if='index < positions.length - 1' :key='index')

          v-list-item(v-else)
              v-list-item-content
                  v-list-item-title.text-bold Noch keine Stellen Anzeige hier
                  v-list-item-subtitle Erstelle jetzt eine neue Stellen Anzeige

        v-divider
        v-card-actions.px-4
          v-spacer
          v-btn(color='grey lighten-1' text='' @click='dismissDialog')
            | abbrechen
          v-btn(color='accent' depressed='' @click='switchPosition' :disabled="positions.length < 1")
            | wechseln



</template>
<script>
import AddPositionDialog from '@/components/business/offering/positionDetails/AddPositionDialog';
import DeletePositionDialog from '@/components/dialogs/DeletePositionDialog.vue';
import { orderBy } from 'lodash';
export default {
  components: {
    AddPositionDialog,
    DeletePositionDialog,
  },
  data() {
    return {
      manageDialog: false,
      addOfferDialog: false,
      selected: 0,
      form: {
        name: '',
      },
    };
  },
  computed: {
    positions() {
      return orderBy(
        this.$store.state.business.positions,
        ['name', 'createdAt'],
        ['ASC', 'DESC']
      );
    },
    positionSelectTitle() {
      if (this.positions.length > 0) {
        return 'Stellen Anzeigen';
      } else {
        return 'Stelle hinzufügen';
      }
    },
    currentPosition() {
      let currentIndex = this.currentIndex;
      const selectedPosition = this.positions[currentIndex];
      return selectedPosition;
    },
    currentPositionName() {
      return this.currentPosition?.name ?? '';
    },
    currentIndex() {
      const currentPositionId =
        this.$store.getters['business/currentPosition']?.id;
      return this.positions.findIndex((p) => p.id === currentPositionId);
    },
  },
  created() {
    this.$store.dispatch('business/fetchPositions').then(() => {
      const selectedPositionId = this.$store.state.business.selectedPositionId;
      this.selected = this.positions.findIndex(
        (position) => position.id === selectedPositionId
      );
    });
  },
  methods: {
    dismissDialog() {
      this.selected = this.currentIndex;

      this.manageDialog = false;
    },
    dismissCreateDialog() {
      this.form.name = '';
      this.addOfferDialog = false;
    },
    onButtonClick() {
      if (this.positions.length > 0) {
        this.manageDialog = true;
      } else {
        this.addOfferDialog = true;
      }
    },
    switchPosition() {
      const selectedPositionId = this.positions[this.selected].id;
      if (!selectedPositionId) return; // TODO: add hint for user to select a position
      // const index = this.positions.findIndex(
      //   (item) => item.id == selectedPositionId
      // );
      this.$store.dispatch('business/setCurrentPosition', selectedPositionId);
      this.dismissDialog();
      this.goToOfferPage();
    },
    goToOfferPage() {
      this.$store.dispatch('business/fetchPositions');
      this.$router.push({
        name: 'ConditionsBusiness',
        query: { positionId: this.positions[this.selected].id },
      });
    },
  },
  watch: {
    // whenever question changes, this function will run
    positions: function (updated, old) {
      if (updated.length === 0 && updated.length != old.length) {
        this.manageDialog = false;
      }
    },
  },
};
</script>
