import { timestampToISOString } from '@/firebase';
import * as Skill from './skill';
import * as User from './user';
import * as Education from './education';
import * as Experience from './experience';
import * as City from './city';
import * as Company from './company';
import * as Conditions from './conditions';

import { CandidateDto } from '../../functions/src/types/candidate';
import { timestampFromDate, timestampFromString } from '@/utilities/Formats';
import { DeepPartial } from 'functions/src/types';

export interface Candidate extends User.User {
  school: Education.Education[];
  education: Education.Education[];
  languages: Skill.Skill[];
  practicalExperience: Experience.Experience[];
  otherExperience: Experience.Experience[];
  softwareSkills: Skill.Skill[];
  conditions?: Conditions.Conditions;
}

export const fromDto = (dto: CandidateDto): Candidate => ({
  ...dto,
  birthdate: dto.birthdate ? timestampToISOString(dto.birthdate) : undefined,
  updatedAt: dto.updatedAt?.toDate(),
  createdAt: dto.createdAt?.toDate(),
  school: dto.school ? dto.school.map(Education.fromDto) : [],
  education: dto.education ? dto.education.map(Education.fromDto) : [],
  languages: dto.languages ? dto.languages.map(Skill.fromDto) : [],
  practicalExperience: dto.practicalExperience
    ? dto.practicalExperience.map(Experience.fromDto)
    : [],
  otherExperience: dto.otherExperience
    ? dto.otherExperience.map(Experience.fromDto)
    : [],
  softwareSkills: dto.softwareSkills
    ? dto.softwareSkills.map(Skill.fromDto)
    : [],
  conditions: dto.conditions ? Conditions.fromDto(dto.conditions) : undefined,
});

export const toDto = (candidate: Candidate): DeepPartial<CandidateDto> => ({
  ...candidate,
  company: candidate.company ? Company.toDto(candidate.company) : undefined,
  birthdate: timestampFromString(candidate.birthdate),
  updatedAt: timestampFromDate(candidate.updatedAt),
  createdAt: timestampFromDate(candidate.createdAt),
  city: candidate.city ? City.toDto(candidate.city) : undefined,
  school: candidate.school.map(Education.toDto),
  education: candidate.education.map(Education.toDto),
  conditions: candidate.conditions
    ? Conditions.toDto(candidate.conditions)
    : undefined,
  languages: candidate.languages.map(Skill.toDto),
  softwareSkills: candidate.softwareSkills.map(Skill.toDto),
  otherExperience: candidate.otherExperience
    ? candidate.otherExperience.map(Experience.toDto)
    : [],
  practicalExperience: candidate.practicalExperience
    ? candidate.practicalExperience.map(Experience.toDto)
    : [],
});
