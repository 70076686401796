import * as UserCollection from '@/api/collections/users';
import * as User from '@/types/user';
import { auth, EmailAuthProvider, userStorageRef } from '@/firebase';
import { Api } from '..';
import router from '@/router';

const getUserById = (userId: string) =>
  UserCollection.get(userId).then(User.fromDto);

const updateUser = (user: Partial<User.User>) =>
  UserCollection.update(User.toDto(user));

const uploadProfileImage = async (userId: string, blob: Blob) => {
  const currentTimestamp = Date.now();

  const imgRef = userStorageRef.child(
    `${userId}/profile-image-${currentTimestamp}.jpg`
  );
  await imgRef.put(blob);
  const photoURL = (await imgRef.getDownloadURL()) as string;
  return photoURL;
};

const removeProfileImage = async (userId: string) => {
  return updateUser({ id: userId, photoURL: '' });
};

const signIn = (email: string, password: string) =>
  auth.signInWithEmailAndPassword(email, password).then((userCredentials) => {
    const uid = userCredentials.user?.uid;
    if (!uid) throw new Error('Could not get user id');
    return getUserById(uid);
  });

const resetPassword = (email: string) => auth.sendPasswordResetEmail(email);

const reauthenticate = (currentPassword: string) => {
  const user = auth.currentUser;
  if (!user?.email) throw Error("Couldn't get current user");
  const credential = EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateWithCredential(credential);
};

const setNewPassword = async (currentPassword: string, newPassword: string) =>
  reauthenticate(currentPassword).then(() =>
    auth.currentUser?.updatePassword(newPassword)
  );

const deleteAccount = (currentPassword: string) =>
  reauthenticate(currentPassword).then(() =>
    auth.currentUser
      ?.delete()
      .then(function () {
        console.log('user deleted');
      })
      .catch(function (error) {
        console.log('error while deleting a user', error);
      })
  );
const updateEmail = (
  currentPassword: string,
  email: string,
  isBusiness: boolean
) => {
  if (!auth.currentUser) return Promise.reject(new Error('User not logged in'));

  return reauthenticate(currentPassword).then(() =>
    auth.currentUser
      ?.updateEmail(email)
      .then(() =>
        sendVerification({
          url: `${
            process.env.VUE_APP_HOST ?? 'https://app.stairship.com'
          }/dashboard`,
          handleCodeInApp: false,
        })
      )
      .then(function () {
        router.push(isBusiness ? '/b/verify?_ec=1' : '/verify?_ec=1'); // add _ec param to not show onboarding after verification
      })
      .catch(function (error) {
        console.log('error while updating email of user', error);
      })
  );
};
const signOut = () => auth.signOut();
const sendVerification = (
  actionCodeSettings = {
    url: `${process.env.VUE_APP_HOST ?? 'https://app.stairship.com'}/dashboard`,
    handleCodeInApp: false,
  }
) => {
  return auth.currentUser?.sendEmailVerification(actionCodeSettings);
};

const createUser = (newUser: User.User, password: string) =>
  auth
    .createUserWithEmailAndPassword(newUser.email, password)
    .then(async ({ user }) => {
      if (!user) throw new Error('Could not create user');
      if (user) {
        await auth.currentUser?.updateProfile({
          displayName: newUser.firstName,
        });
      }
      if (user && user.emailVerified === false) {
        await sendVerification();
      } else {
        console.log('not logged in');
      }

      newUser.id = user.uid;

      if (newUser.type === User.UserType.BUSINESS) {
        if (!newUser.company?.name) throw Error('Company name is missing');
        newUser.company = await Api().company().create(newUser.company);
      }
      return UserCollection.create(user.uid, User.toDto(newUser)).then(
        User.fromDto
      );
    });

const markOnboardingStarted = async (userId: string) =>
  UserCollection.markOnboardingStarted(userId);

export const UserApi = () => ({
  getUserById,
  updateUser,
  signIn,
  signOut,
  resetPassword,
  setNewPassword,
  deleteAccount,
  createUser,
  uploadProfileImage,
  updateEmail,
  removeProfileImage,
  sendVerification,
  markOnboardingStarted,
});
