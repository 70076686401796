<template lang="pug">

v-dialog( v-model='dialog' persistent='' max-width="700" scrollable )
    template(v-slot:activator='{ on, attrs }')
        v-btn.pr-0(color='primary' v-if="type == 'simple'" text='' v-bind='attrs' v-on='on')
            v-icon.mr-2 mdi-plus
            | Hinzufügen
        v-btn.white--text(color="#FF5D00" v-else-if="type == 'outlined'" depressed   v-bind='attrs' v-on='on') 
            | Stelle hinzufügen 
        v-list-item.px-2.mb-2.accent(v-bind='attrs' v-on='on'  v-else )
            v-list-item-title.white--text.text-strong Stelle hinzufügen
            v-list-item-icon
              v-icon(color="white") mdi-plus-circle-outline
    v-card.d-flex.flex-column
        div
          v-toolbar(flat color="transparent" tile)
            v-toolbar-title.text-h6.dialog-title.font-weight-bold  Stelle hinzufügen
            v-spacer 
            v-btn(icon @click='dialog = false')
              v-icon mdi-close
          v-divider
        v-card-text.dialog-card-text.pt-6.flex-grow-1.px-4
          v-row(align="center" justify="center")
            v-col(cols='12')
              v-text-field(label='Jobtitel' dense v-model="form.name" hide-details outlined required='')
        v-divider
        v-card-actions.px-4
            v-spacer
            v-btn(color='grey ' text='' @click='dismissDialog')
                | Abbrechen
            v-btn.white--text(color='accent darken-1' depressed='' @click='startOnboarding' :disabled="form.name === '' || !form.name")
                | Hinzufügen

</template>
<script>
export default {
  props: ['type'],
  data: () => ({
    dialog: false,
    form: {
      name: '',
    },
  }),
  methods: {
    dismissDialog() {
      this.dialog = false;
      this.form.name = '';
    },
    startOnboarding() {
      if (this.form.name === '' || !this.form.name) return;
      this.$emit('createdNewPosition');
      this.$router
        .push({
          name: 'OnboardingBusiness',
          query: { name: this.form.name, positionId: undefined },
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
