import { CandidateApi } from './modules/candidates';
import { StorageApi } from './storage';
import { UserApi } from './modules/users';
import { FaqApi } from './modules/faqs';
import { MatchesApi } from './modules/matches';
import { CompanyApi } from './modules/companies';
import { PositionsApi } from './modules/positions';
import { MessagesApi } from './modules/messages';
import { UserSettingsApi } from './modules/userSettings';

export const Api = () => ({
  user: UserApi,
  userSettings: UserSettingsApi,
  candidate: CandidateApi,
  storage: StorageApi,
  faq: FaqApi,
  matches: MatchesApi,
  company: CompanyApi,
  positions: PositionsApi,
  messages: MessagesApi,
});
