import { DeepPartial } from 'functions/src/types';
import { BaseUserDto } from 'functions/src/types/baseUser';

export interface BaseUser {
  id: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  email: string;
}

export const fromDto = (dto: BaseUserDto): BaseUser => ({
  ...dto,
});

export const toDto = (
  user: Partial<BaseUser> | BaseUser
): DeepPartial<BaseUserDto> => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  photoURL: user.photoURL,
  email: user.email,
});
