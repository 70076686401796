import { Api } from '@/api';
import { DocumentData, MergeFields, QuerySnapshot } from '@/firebase';
import { StateBusiness, VuexAction } from '@/store/types';
import { Company } from '@/types/company';
import { Conditions } from '@/types/conditions';
import { Education } from '@/types/education';
import { Experience } from '@/types/experience';
import * as Match from '@/types/match';
import { Position } from '@/types/position';
import { Commit } from 'vuex';
import Vue from 'vue';
import { MatchesApi } from '@/api/modules/matches';
import router from '@/router';
import { flatten, orderBy } from 'lodash';
import { notEmpty } from '@/utilities/Utils';
import * as MatchDto from '../../functions/src/types/match';

interface UpdatePositionInput {
  updatedPosition: Position;
  mergeFields?: MergeFields;
}

interface FetchMatchesInput {
  section: 'Likes' | 'Archive' | 'Matches';
  reset?: boolean;
}

interface PartialUpdateInput {
  item: Experience[] | Education[] | Conditions | string[];
  key: keyof Position;
}

const state: StateBusiness = {
  error: '',
  loading: false,
  selectedPositionId: undefined,
  positions: [],
  matches: [],
  matchesWithConversation: [],
  hasMoreMatches: true,
  matchesIndicatorCount: 0,
  generatingMatches: false,
  company: {
    name: '',
    id: '',
    logoURL: '',
    website: '',
    about: '',
  },
  unsubscribeCurrentMatchListener: undefined,
  unsubscribeCurrentMatchIndicatorListener: undefined,
  unsubscribeCurrentPositionStateListener: undefined,
  selectedMatchesSection: 'Matches',
};

const showError = (commit: Commit, message: string) => {
  commit('setLoading', false);
  commit('setError', message);
};

const mutations = {
  setLoading(state: StateBusiness, status: boolean) {
    state.loading = status;
  },
  setError(state: StateBusiness, error: string) {
    state.error = error;
  },
  setMatches(state: StateBusiness, matches: Match.Match[]) {
    state.matches = matches;
  },
  appendMatches(state: StateBusiness, matches: Match.Match[]) {
    state.matches = [...state.matches, ...matches];
  },
  removeMatch(state: StateBusiness, matchId: string) {
    const index = state.matches.findIndex((match) => match.id == matchId);
    if (index === -1) return;
    state.matches.splice(index, 1);
  },
  setMatch(state: StateBusiness, match: Match.Match) {
    const index = state.matches.findIndex((m) => m.id === match.id);

    if (index > -1) {
      Vue.set(state.matches, index, match);
    } else {
      state.matches.push(match);
    }
  },

  setPositions(state: StateBusiness, positions: Position[]) {
    state.positions = positions ?? [];
  },
  setPosition(state: StateBusiness, position: Position) {
    const positionIndex = state.positions.findIndex(
      (item) => item.id === position.id
    );
    if (positionIndex > -1) {
      state.positions.splice(positionIndex, 1, position);
    } else {
      state.positions.push(position);
    }
  },
  setSelectedPositionId(state: StateBusiness, positionId: string) {
    state.selectedPositionId = positionId;
  },
  setCompany(state: StateBusiness, company: Company) {
    state.company = company;
  },
  setUnsubscribeCurrentMatchListener(
    state: StateBusiness,
    unsubscribe: () => void
  ) {
    state.unsubscribeCurrentMatchListener = unsubscribe;
  },
  setUnsubscribeCurrentMatchIndicatorListener(
    state: StateBusiness,
    unsubscribe: () => void
  ) {
    state.unsubscribeCurrentMatchIndicatorListener = unsubscribe;
  },
  setUnsubscribeCurrentPositionStateListener(
    state: StateBusiness,
    unsubscribe: () => void
  ) {
    state.unsubscribeCurrentPositionStateListener = unsubscribe;
  },
  setMatchesCountIndicator(state: StateBusiness, count: number) {
    state.matchesIndicatorCount = count;
  },
  resetMatches(state: StateBusiness) {
    state.matches = [];
    state.hasMoreMatches = true;
  },
  setMatchesWithConversation(state: StateBusiness, matches: Match.Match[]) {
    state.matchesWithConversation = matches;
  },
  setHasMoreMatches(state: StateBusiness, hasMoreMatches: boolean) {
    state.hasMoreMatches = hasMoreMatches;
  },
  setMatchWithConversation(state: StateBusiness, match: Match.Match) {
    const matchIndex = state.matchesWithConversation.findIndex(
      (item) => item.id === match.id
    );
    if (matchIndex > -1) {
      state.matchesWithConversation.splice(matchIndex, 1, match);
    } else {
      state.matchesWithConversation.push(match);
    }
  },
  removeMatchFromConversations(state: StateBusiness, matchId: string) {
    const index = state.matchesWithConversation.findIndex(
      (match) => match.id == matchId
    );
    if (index === -1) return;
    state.matchesWithConversation.splice(index, 1);
  },
  setGeneratingMatches(state: StateBusiness, generatingMatches: boolean) {
    state.generatingMatches = generatingMatches;
  },
  setSelectedMatchesSection(state: StateBusiness, section: string) {
    state.selectedMatchesSection = section;
  },
};

const getters = {
  error: () => {
    return state.error;
  },
  loading: () => {
    return state.loading;
  },
  currentPosition: () => {
    return state.positions.find(
      (position) => position.id === state.selectedPositionId
    );
  },
  matchesForSection:
    (state: StateBusiness, _getters: any, rootState: any) =>
    (section: string) => {
      let matches: Match.Match[] = [];
      switch (section) {
        case 'Archive':
          matches = state.matches.filter((match) => match.archived);
          break;
        case 'Matches':
          matches = state.matches.filter((match) => !match.archived);
          break;
        case 'Likes':
          matches = state.matches.filter((match) => match.liked);
          break;
        default:
          matches = [];
          break;
      }

      return orderBy(matches, ['score.percentage', 'id'], ['desc', 'desc']);
    },
  unreadMessagesCount:
    (state: StateBusiness, _getters: any, rootState: any) => () => {
      return flatten(
        state.matchesWithConversation
          .filter((match) => match.position.id === state.selectedPositionId)
          .map(
            (match) =>
              match.unseenMessagesCount[`${rootState.user.currentUser.id}`]
          )
      )
        .filter(notEmpty)
        .reduce((partial_sum, a) => partial_sum + a, 0);
    },
  matchesWithConversation: (state: StateBusiness) => {
    return (
      state.matchesWithConversation
        ?.filter((match) => !match.archived)
        .sort((a, b) => {
          const sentAtA = a.lastMessage?.sentAt;
          const sentAtB = b.lastMessage?.sentAt;

          if (!sentAtA || !sentAtB) {
            return 0;
          }

          return sentAtB.valueOf() - sentAtA.valueOf();
        }) ?? []
    );
  },
  matchById: (state: StateBusiness) => (matchId: string) => {
    return [...state.matches, ...state.matchesWithConversation].find(
      (match) => match.id === matchId
    );
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types
const actionWrapper = async (commit: Commit, action: Function) => {
  try {
    commit('setLoading', true);
    await action();
    commit('setLoading', false);
  } catch (error) {
    showError(commit, error.message);
    commit('setLoading', false);
  }
};

const actions = {
  async fetchMatchesWithConversation({ commit }: VuexAction) {
    const action = async () => {
      if (!state.selectedPositionId) return;
      const matches = await Api()
        .matches()
        .getMatchesWithConversationForPosition(state.selectedPositionId);

      commit('setMatchesWithConversation', matches);
    };

    await actionWrapper(commit, action);
  },
  async markMatchSeen({ commit }: VuexAction, matchId: string) {
    const action = async () => {
      const match = state.matches.find((match) => match.id === matchId);

      if (match?.seen) {
        return;
      }
      await Api().matches().markMatchSeen(matchId);
      commit('setMatch', { ...match, seen: true });
    };

    await actionWrapper(commit, action);
  },
  async subscribeToMatchesIndicatorCount({ commit }: VuexAction) {
    // unsubscribe from previous listener
    if (state.unsubscribeCurrentMatchIndicatorListener) {
      state.unsubscribeCurrentMatchIndicatorListener();
    }

    const callback = (count: number) => {
      commit('setMatchesCountIndicator', count);
    };
    if (!state.selectedPositionId) return;

    const unsubscribe =
      MatchesApi().subscribeToMatchesIndicatorCountForPosition(
        state.selectedPositionId,
        callback
      );

    commit('setUnsubscribeCurrentMatchIndicatorListener', unsubscribe);
  },
  async subscribeToPositionState({ commit }: VuexAction) {
    // unsubscribe from previous listener
    if (state.unsubscribeCurrentPositionStateListener) {
      state.unsubscribeCurrentPositionStateListener();
    }

    const callback = (data?: DocumentData) => {
      if (!data) return;
      commit(
        'setGeneratingMatches',
        data.runningMatchGenerationJobs.length > 0
      );
    };
    if (!state.selectedPositionId) return;

    const unsubscribe = MatchesApi().subscribeToPositionState(
      state.selectedPositionId,
      callback
    );

    commit('setUnsubscribeCurrentPositionStateListener', unsubscribe);
  },
  async fetchPositions({ commit, dispatch, rootState }: VuexAction) {
    const action = async () => {
      const companyId = rootState.user.currentUser.company.id;
      const positions = await Api()
        .positions()
        .getPositionsByCompanyId(companyId);
      dispatch('fetchCompany');
      commit('setPositions', positions);
      if (positions.length > 0 && !state.selectedPositionId) {
        if (router.currentRoute.query.positionId) {
          commit('setSelectedPositionId', router.currentRoute.query.positionId);
        } else {
          commit('setSelectedPositionId', positions[0].id);
        }
      }
    };

    await actionWrapper(commit, action);
  },
  async fetchMatches(
    vuexAction: VuexAction,
    { section, reset }: FetchMatchesInput
  ) {
    const { commit } = vuexAction;
    const action = async () => {
      if (!state.selectedPositionId) return;

      if (reset) {
        commit('resetMatches');
      }
      if (!state.hasMoreMatches) {
        return;
      }
      const matches = await Api()
        .matches()
        .getAllMatchesForPosition(
          state.selectedPositionId,
          section,
          state.matches[state.matches.length - 1]
        );
      commit('setHasMoreMatches', matches.length > 0);
      matches.forEach((match) => {
        commit('setMatch', match);
      });
      // commit('setMaxPages', pageCount);
    };
    return actionWrapper(commit, action);
  },
  async subscribeToMatchesWithConversation({ commit }: VuexAction) {
    try {
      // unsubscribe from previous listener
      if (state.unsubscribeCurrentMatchListener) {
        state.unsubscribeCurrentMatchListener();
      }

      const callback = (snapshot: QuerySnapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const match = Match.fromDto(MatchDto.fromFirestore(change.doc));
          commit('setMatchWithConversation', match);
          commit('removeMatch', match.id);
        });
      };
      if (!state.selectedPositionId) return;

      const unsubscribe =
        MatchesApi().subscribeToMatchesWithConversationForPosition(
          state.selectedPositionId,
          callback
        );
      commit('setUnsubscribeCurrentMatchListener', unsubscribe);
    } catch (error) {
      showError(commit, error.message);
    }
  },
  async fetchMatch({ commit }: VuexAction, matchId: string) {
    const action = async () => {
      const match = await Api().matches().getMatch(matchId);

      // const candidateOfMatch = await Api()
      //   .candidate()
      //   .getCandidateById(match.candidate.id);
      // match.candidate = candidateOfMatch;
      commit('setMatch', match);
    };
    return actionWrapper(commit, action);
  },
  async createPosition({ commit }: VuexAction, position: Position) {
    const action = async () => {
      const addedPosition = await Api().positions().createPosition(position);
      commit('setPosition', addedPosition);
      commit('setSelectedPositionId', addedPosition.id);
      commit('setGeneratingMatches', true);
    };

    return actionWrapper(commit, action);
  },
  async updatePosition({ commit }: VuexAction, payload: UpdatePositionInput) {
    const action = async () => {
      const { updatedPosition, mergeFields } = payload;

      if (!getters.currentPosition()) throw Error('Cannot identify position');

      const mergedPosition = {
        ...getters.currentPosition(),
        ...updatedPosition,
      };

      await Api().positions().updatePosition(mergedPosition, mergeFields);

      commit('setGeneratingMatches', true);
      commit('setPosition', mergedPosition);
    };

    return actionWrapper(commit, action);
  },
  async deletePosition({ commit, dispatch }: VuexAction, id: string) {
    const action = async () => {
      await Api().positions().deletePosition(id);
      commit('setSelectedPositionId', undefined);
      router.replace({
        query: {
          positionId: undefined,
        },
      });
      dispatch('fetchPositions');
    };
    return actionWrapper(commit, action);
  },
  setCurrentPosition({ commit }: VuexAction, id: string) {
    commit('setSelectedPositionId', id);
  },
  partialUpdate(
    { dispatch, commit }: VuexAction,
    updates: PartialUpdateInput[]
  ) {
    const updatedItem = updates.reduce(
      (acc, curr) => ((acc[`${curr.key}`] = curr.item), acc),
      {} as { [key: string]: any }
    );

    return dispatch('updatePosition', {
      updatedPosition: updatedItem,
      mergeFields: updates.map((e) => e.key),
    }).then(() => {
      commit('setGeneratingMatches', true);
    });
  },
  async likeMatch(
    { commit }: VuexAction,
    { matchId, liked }: { matchId: string; liked: boolean }
  ) {
    const updatedMatch = await Api().matches().likeMatch(matchId, liked);

    commit('setMatch', updatedMatch);
  },
  async fetchCompany({ commit, rootState }: VuexAction) {
    const action = async () => {
      const companyId = rootState.user.currentUser.company.id;
      const company = await Api().company().get(companyId);

      commit('setCompany', company);
    };
    actionWrapper(commit, action);
  },
  async updateCompany({ commit, rootState }: VuexAction, company: Company) {
    commit('setLoading', true);
    try {
      await Api().company().update(company);

      commit(
        'user/setCurrentUser',
        { ...rootState.user.currentUser, company },
        { root: true }
      );

      commit('setCompany', company);
      commit('setGeneratingMatches', true);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', error.message);
    }
  },
  async uploadCompanyLogo(
    { commit, dispatch, rootState }: VuexAction,
    { blob }: { blob: Blob }
  ) {
    const companyId = rootState.user.currentUser.company.id;

    const url = await Api().company().uploadCompanyLogo(companyId, blob);
    return url;
  },
  async markConversationStartForMatch({ commit }: VuexAction, matchId: string) {
    const action = async () => {
      await Api().matches().markConversationStartForMatch(matchId);
    };
    return actionWrapper(commit, action);
  },
  async archiveMatch(
    { commit }: VuexAction,
    { matchId, archive }: { matchId: string; archive: boolean }
  ) {
    const action = async () => {
      const match = await Api().matches().archiveMatch(matchId, archive);
      commit('removeMatchFromConversations', match.id);
      commit('removeMatch', match.id);
    };
    return actionWrapper(commit, action);
  },
};

export const business = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
