import { AnyKeyObject } from '.';
import * as Address from './address';

export interface CompanyDto {
  id: string;
  name: string;
  logoURL?: string;
  website?: string;
  about?: string;
  address?: Address.AddressDto;
  recruiters?: string[];
}

export const fromData = (data: AnyKeyObject): CompanyDto => {
  return {
    id: data.id,
    name: data.name,
    logoURL: data.logoURL ?? null,
    website: data.website ?? null,
    about: data.about ?? null,
    address: Address.fromData(data.address),
    recruiters: data.recruiters ? data.recruiters : [],
  };
};

export const fromFirestore = (snapshot: AnyKeyObject): CompanyDto => {
  const data = snapshot.data();
  return fromData({ ...data, id: snapshot.id });
};
