<template lang="pug">
.secure.fill-height
  .flex-container.fill-height
    NavDrawerBusiness
    v-main
      InstallAppBanner
      v-container.mb-4.pb-4(style="position: relative;")
        Header
        slot
        PushNotifications
</template>

<script>
import NavDrawerBusiness from '@/components/navigation/NavDrawerBusiness';
import Header from '@/components/navigation/Header';
import PushNotifications from '@/components/notifications/PushNotifications';
import InstallAppBanner from '@/components/navigation/InstallAppBanner';
export default {
  name: 'Business',
  components: {
    NavDrawerBusiness,
    Header,
    PushNotifications,
    InstallAppBanner,
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
.secure
  position: relative
  background: $background
.flex-container
  position: relative
.bottom-navigation
  position: fixed
  bottom: 0
  left: 0
  right: 0
</style>
