<template lang="pug">
.auth 
  .flex-container
    v-main.main
      .fill-height.pt-md-12.auth-wrapper
        v-container.py-md-8.auth-container
          v-row
            v-col(cols='12', lg='6') 
              a(href="https://stairship.com")
                img(src='@/assets/img/logo/STAIRZ_Logo_Wort-Bildmarke.svg')
            v-col.form-container(cols='12', lg='6')
              v-card.elevation-12.mx-auto(color='white' style="overflow: hidden; max-width: 640px")
                div
                  v-tabs.tabs-wrapper(grow  color="accent"  v-if="!verifyRoute")
                    v-tab(:to="loginRoute ? '/login': '/register'" :ripple="false"  :active="!businessRoute")
                        span.d-none.d-sm-flex Für Jobsuchende
                        span.d-flex.d-sm-none Jobsuchende
                    v-tab(:to="loginRoute ? '/b/login': '/b/register'"  :ripple="false" :active="businessRoute")
                      span.d-none.d-sm-flex Für Unternehmen
                      span.d-flex.d-sm-none Unternehmen
                slot
        .contact(style="margin-top: auto")
          v-container.py-8
            v-divider
            Footer(:type="businessRoute ? 'business' : 'candidate'")
</template>

<script>
import Footer from '@/components/navigation/Footer.vue';
export default {
  name: 'Auth',
  components: {
    Footer,
  },
  computed: {
    loginRoute() {
      return this.$route.path.includes('/login');
    },
    businessRoute() {
      return this.$route.path.includes('/b');
    },
    verifyRoute() {
      return this.$route.path.includes('/verify');
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/style/main'
.auth
  padding: 0
  max-width: 100% !important
  width: 100% !important
  background: url("~@/assets/img/Home_Header_Desktop.jpg")
  // background-attachment: fixed
  background-size: 100% auto
  background-repeat: no-repeat
  background-position: top center
  position: relative
  margin: 0
  padding: 0
  .publicContainer
    height: 100%

  .main
    min-height: 100vh
    .auth-wrapper
      img
        width: 50%
        max-width: 280px
        margin: 60px auto
        display: block
        @media only screen and (min-width: 1264px)
          margin-top: 26vh
      .form-container
        @media only screen and (min-width: 1264px)
          min-height:70vh
          display: flex
          align-items: center
        .tabs-wrapper
          border-bottom: 1px solid $light-gray
          .v-tab
            &:hover, &:active, &:focus
              &::before
                opacity: 0

          .v-tab--active
            background: transparent !important

      .v-card
        width: 100%
        box-shadow: $shadow

      .v-btn
        text-transform: none !important
</style>
