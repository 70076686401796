import { Api } from '@/api';
import { StateFaqs, VuexAction } from '@/store/types';
import { Faq } from '@/types/faq';
import { Commit } from 'vuex';

const state: StateFaqs = {
  error: '',
  loading: false,
  faqs: [],
};

const faqApi = Api().faq();

const showError = (commit: Commit, message: string) => {
  commit('setLoading', false);
  commit('setError', message);
};

const mutations = {
  setLoading(state: StateFaqs, status: boolean) {
    state.loading = status;
  },
  setError(state: StateFaqs, error: string) {
    state.error = error;
  },
  setFaqs(state: StateFaqs, faqs: Faq[]) {
    if (faqs) {
      state.faqs = faqs;
    }
  },
};

const actions = {
  async fetchFaqs({ commit }: VuexAction) {
    commit('setLoading', true);
    try {
      const faqs = await faqApi.getAllFaqs();
      commit('setFaqs', faqs);
      commit('setLoading', false);
    } catch (error) {
      showError(commit, error.message);
    }
  },
};

const getters = {
  error: () => {
    return state.error;
  },
  loading: () => {
    return state.loading;
  },
  candidateFaq: () => {
    return state.faqs?.filter((item) => item.type == 'candidate');
  },
  businessFaq: () => {
    return state.faqs?.filter((item) => item.type == 'business');
  },
};

export const faq = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
