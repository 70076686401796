import { AnyKeyObject } from '.';

export enum SkillType {
  LANGUAGES = 'languages',
  SOFTWARE = 'softwareSkills',
}
export interface SkillDto {
  name: string;
  level: number;
  code?: string;
}

export const fromData = (data: AnyKeyObject) => {
  const skill: SkillDto = {
    name: data.name,
    level: data.level,
    code: data.code,
  };
  return skill;
};
