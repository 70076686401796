import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
Vue.use(Vuetify);

import { icons } from './icons';
import { primary, accent, secondary } from './colors';

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary,
        accent,
        secondary,
        error: '#b71c1c',
      },
    },
  },
  // iconfont: 'mdi',
  icons: {
    iconfont: 'mdi',
    values: icons,
  },
});
