import { Api } from '@/api';
import { messagesCollection } from '@/firebase';
import { StateMessages, VuexAction } from '@/store/types';
import { Message } from '@/types/message';
import Vue from 'vue';
import { Commit } from 'vuex';

const state: StateMessages = {
  error: '',
  loading: false,
  messagesByMatch: {},
  unsubscribe: undefined,
};

const messagesApi = Api().messages();

const showError = (commit: Commit, message: string) => {
  commit('setLoading', false);
  commit('setError', message);
};

const mutations = {
  setLoading(state: StateMessages, status: boolean) {
    state.loading = status;
  },
  setError(state: StateMessages, error: string) {
    state.error = error;
  },
  setMessages(
    state: StateMessages,
    { matchId, messages }: { matchId: string; messages: Message[] }
  ) {
    if (state.messagesByMatch[matchId]) {
      messages.map((message) => {
        const index = state.messagesByMatch[matchId].findIndex(
          (i) => i.id === message.id
        );

        if (index === -1) {
          state.messagesByMatch[matchId].push(message);
        }
      });
    } else {
      Vue.set(state.messagesByMatch, matchId, messages);
    }
  },
  setUnsubscribe(state: StateMessages, unsubscribe: () => void) {
    state.unsubscribe = unsubscribe;
  },
};

const actions = {
  async subscribeToMessagesForMatch(
    { commit }: VuexAction,
    { matchId }: { matchId: string }
  ) {
    commit('setLoading', true);
    try {
      // unsubscribe from previous match
      if (state.unsubscribe) {
        state.unsubscribe();
      }

      const callback = (messages: Message[]) => {
        commit('setMessages', {
          matchId,
          messages: messages.filter((m) => !!m.sentAt),
        });
      };
      const unsubscribe = messagesApi.subscribeToMessagesForMatch(
        matchId,
        callback
      );
      commit('setUnsubscribe', unsubscribe);
    } catch (error) {
      showError(commit, error.message);
    }
  },
  async sendMessage(
    { commit, rootState, dispatch }: VuexAction,
    {
      matchId,
      text,
      isFirstMessage,
      companyId,
    }: {
      matchId: string;
      text: string;
      isFirstMessage: boolean;
      companyId: string;
    }
  ) {
    commit('setLoading', true);
    try {
      const isCandidate = !rootState.user.isBusiness;
      const { anonymous, whitelistedCompanies } = rootState.user.currentUser;
      if (
        anonymous &&
        isCandidate &&
        !whitelistedCompanies.includes(companyId)
      ) {
        await dispatch('user/addWhitelistedCompany', companyId, { root: true });
      }
      await messagesApi.sendMessage(matchId, text, isFirstMessage);
      commit('setLoading', false);
    } catch (error) {
      showError(commit, error.message);
    }
  },
  async markMessageSeen(
    _: VuexAction,
    { matchId, message }: { matchId: string; message: Message }
  ) {
    if (message.seenByCurrentUser) {
      return;
    }

    await messagesApi.markMessageSeen(matchId, message.id);
  },
};

const getters = {
  error: () => {
    return state.error;
  },
  loading: () => {
    return state.loading;
  },
};

export const messages = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
