import { ErrorMessage } from './../types/errorMessage';
import * as fb from '../firebase';
import { Api } from '@/api';
import router from '@/router';
import { StateAuth as StateUser, VuexAction } from '@/store/types';
import * as User from '@/types/user';
import { UserType } from '@/types/user';
import {
  NotificationCategory,
  NotificationType,
  NotificationSettings,
} from '@/types/notificationSettings';
import { messaging } from '../firebase';

const state: StateUser = {
  error: '',
  loading: false,
  isLoggedIn: false,
  isBusiness: false,
  currentUser: null,
  notificationSettings: null,
};
const userApi = Api().user();
const userSettingsApi = Api().userSettings();

const mutations = {
  setLoading(state: StateUser, status: boolean) {
    state.loading = status;
  },
  setError(state: StateUser, error: string) {
    state.error = error;
  },
  setCurrentUser(state: StateUser, user: User.User) {
    if (user) {
      state.isLoggedIn = user.id ? true : false;
      state.isBusiness = user.type == User.UserType.BUSINESS;
      state.currentUser = user;
      state.loading = false;
    }
  },

  clearUser(state: StateUser) {
    state.isLoggedIn = false;
    state.currentUser = null;
  },
  setNotificationSettings(state: StateUser, settings: NotificationSettings) {
    state.notificationSettings = settings;
  },
};
const authErrorMapping = (error: ErrorMessage | any) => {
  const { code, message }: ErrorMessage = error;
  if (code == null) {
    return null;
  }

  let errorMessage = '';

  switch (code) {
    case 'auth/invalid-email':
      errorMessage = 'Deine E-Mail-Adresse scheint falsch formatiert zu sein.';
      break;
    case 'auth/email-already-exists':
      errorMessage =
        'Die angegebene E-Mail-Adresse ist bereits registriert. Melde dich jetzt an oder setze dein Passwort zurück. <a style="color:white;" href="/login">Zum Login</a>';
      break;
    case 'auth/email-already-in-use':
      errorMessage =
        'Die angegebene E-Mail-Adresse ist bereits registriert. Melde dich jetzt an oder setze dein Passwort zurück. <a style="color:white;" href="/login">Zum Login</a>';
      break;
    case 'auth/wrong-password':
      // errorMessage = 'Das Passwort ist falsch.';
      errorMessage =
        'E-Mail oder Passwort wurde nicht richtig eingegeben. Versuche es noch einmal.';
      break;
    case 'auth/user-not-found':
      errorMessage =
        'E-Mail oder Passwort wurde nicht richtig eingegeben. Versuche es noch einmal.';
      break;
    case 'auth/user-disabled':
      errorMessage =
        'Der Benutzer mit dieser E-Mail-Adresse wurde deaktiviert.';
      break;
    case 'auth/too-many-requests':
      errorMessage = 'Zu viele Anfragen. Versuche es später noch einmal.';
      break;
    case 'auth/timeout':
      errorMessage =
        'Das hat leider zu lange gedauert. Versuche es später noch einmal.';
      break;
    default:
      errorMessage =
        message ?? 'Etwas ist schief gelaufen. Versuche es erneut.';
      break;
  }
  return errorMessage;
};
const actions = {
  async login(
    { commit }: VuexAction,
    { email, password }: { email: string; password: string }
  ) {
    commit('setError', '');
    commit('setLoading', true);
    if (password && email) {
      try {
        const user = await userApi.signIn(email, password);
        commit('setLoading', false);

        const redirectTo = router.currentRoute.query?.redirect;
        commit('setError', '');
        if (
          (router.currentRoute.name == 'Login' ||
            router.currentRoute.name == 'LoginBusiness') &&
          redirectTo &&
          typeof redirectTo === 'string'
        ) {
          router.replace(redirectTo);
        } else {
          router.push({
            name:
              user.type === UserType.BUSINESS
                ? 'DashboardBusiness'
                : 'Dashboard',
          });
        }
      } catch (error) {
        commit('setLoading', false);
        commit('setError', authErrorMapping(error));
      }
    }
  },
  async logout({ commit, dispatch, rootState }: VuexAction) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      await dispatch('removeFcmToken').catch(console.log);
      if (messaging) {
        await messaging.deleteToken().catch(console.log);
      }

      // make sure to set path before clearing user
      const path = rootState.user.isBusiness ? '/b/login' : '/login';

      await fb.auth.signOut();

      commit('clearUser');
      commit('setLoading', false);

      router.push({ path, query: {} });

      location.reload();
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
  async resetPassword({ commit }: VuexAction, { email }: { email: string }) {
    commit('setError', '');
    commit('setLoading', true);
    if (email) {
      try {
        await userApi.resetPassword(email);
        // Falls wir einen Account für diese E-Mail finden, schicken wir dir eine E-Mail, mit der du dein Passwort zurücksetzen kannst.
        commit(
          'setError',
          'Wir haben dir eine E-Mail geschickt mit der du dein Passwort zurücksetzten kannst'
        );
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', authErrorMapping(error));
      }
    }
  },
  async resendVerification({ commit }: VuexAction) {
    commit('setError', '');
    commit('setLoading', true);

    try {
      await userApi.sendVerification();

      commit(
        'setError',
        'Wir haben dir eine E-Mail geschickt mit der du dein Konto bestätigen kannst'
      );
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
  setNewPassword(
    { commit }: VuexAction,
    { current, updated }: { current: string; updated: string }
  ) {
    return new Promise((resolve, reject) => {
      commit('setError', '');
      commit('setLoading', true);
      if (current && updated) {
        userApi
          .setNewPassword(current, updated)
          .then(() => {
            commit('setLoading', false);
            resolve({ status: 200 });
          })
          .catch((error) => {
            commit('setLoading', false);
            reject(error);
            commit('setError', authErrorMapping(error));
          });
      } else {
        commit('setError', 'Bitte gib dein aktuelles und neues Passwort ein');
        reject('error');
      }
    });
  },
  async deleteAccount(
    { commit, dispatch }: VuexAction,
    currentPassword: string
  ) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      if (!currentPassword) throw Error('Need current password');
      await userApi.deleteAccount(currentPassword);
      dispatch('logout');
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
  updateEmail(
    { commit, rootState }: VuexAction,
    { currentPassword, newEmail }: { currentPassword: string; newEmail: string }
  ) {
    commit('setError', '');
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      if (!currentPassword) throw Error('Need current password');
      if (!newEmail) throw Error('Need email');
      userApi
        .updateEmail(currentPassword, newEmail, rootState.user.isBusiness)
        .then(() => {
          commit('setLoading', false);

          resolve({ status: 200 });
        })
        .catch((error) => {
          reject(error);
          commit('setLoading', false);
          commit('setError', authErrorMapping(error));
        });
    });
  },
  async register(
    { commit }: VuexAction,
    { newUser, password }: { newUser: User.User; password: string }
  ) {
    commit('setError', '');
    commit('setLoading', true);

    try {
      const user = await Api().user().createUser(newUser, password);
      commit('setCurrentUser', user);
      commit('setLoading', false);
      router.push(user.type === UserType.BUSINESS ? '/b/verify' : '/verify');
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
  async fetchUser({ commit, dispatch }: VuexAction, id: string) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      const user = await userApi.getUserById(id);

      if (user.type === User.UserType.CANDIDATE) {
        await dispatch('candidate/fetchCandidate', user.id, { root: true });
      }

      commit('setCurrentUser', user);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },

  async updateUser({ commit }: VuexAction, user: User.User) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      if (!User.isValid(user)) throw Error('User not valid');

      await userApi.updateUser(user);

      commit('setCurrentUser', user);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
  async uploadProfileImage(
    { commit, dispatch }: VuexAction,
    { userId, blob }: { userId: string; blob: Blob }
  ) {
    const url = await userApi.uploadProfileImage(userId, blob);

    return url;
  },
  async getNotificationSettings({ commit }: VuexAction) {
    commit('setLoading', true);
    try {
      const settings = await userSettingsApi.fetchSettings();

      commit('setNotificationSettings', settings);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
    }
  },
  async setNotification(
    { commit }: VuexAction,
    {
      type,
      category,
      enabled,
    }: {
      enabled: boolean;
      category: NotificationCategory;
      type: NotificationType;
    }
  ) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      const settings = await userSettingsApi.updateNotificationSetting(
        type,
        category,
        enabled
      );

      commit('setNotificationSettings', settings);
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },

  async addFcmToken(
    { commit }: VuexAction,
    {
      token,
    }: {
      token: string;
    }
  ) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      await userSettingsApi.addFcmToken(token);

      commit('setNotificationSettings', {
        ...state.notificationSettings,
        fcmToken: token,
      });
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },

  removeFcmToken({ commit }: VuexAction) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      if (messaging) {
        return messaging
          .getToken()
          .then((token) => userSettingsApi.removeFcmToken(token))
          .then(() => messaging?.deleteToken())
          .catch((e) => console.log({ error: e }))
          .finally(() => {
            commit('setNotificationSettings', {
              ...state.notificationSettings,
              fcmToken: undefined,
            });
            commit('setLoading', false);
          });
      }
    } catch (error) {
      console.log({ error });
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
  async activateAnonymous({ commit }: VuexAction, active: boolean) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      await userSettingsApi.setAnonymous(active);
      commit('setCurrentUser', { ...state.currentUser, anonymous: active });
      commit('setLoading', false);
    } catch (error) {
      console.log({ error });
      commit('setLoading', false);
    }
  },
  async addWhitelistedCompany({ commit }: VuexAction, companyId: string) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      await userSettingsApi.addWhitelistedCompany(companyId).then(() => {
        const currentUser = state.currentUser;
        if (!currentUser) return;
        return commit('setCurrentUser', {
          ...currentUser,
          whitelistedCompanies: [
            ...(currentUser.whitelistedCompanies ?? []),
            companyId,
          ],
        });
      });
      commit('setLoading', false);
    } catch (error) {
      console.log({ error });
      commit('setLoading', false);
    }
  },
  async markOnboardingStarted({ commit }: VuexAction) {
    commit('setError', '');
    commit('setLoading', true);
    try {
      if (!state.currentUser) throw Error('Cannot identify user');
      await userApi.markOnboardingStarted(state.currentUser?.id);

      commit('setCurrentUser', {
        ...state.currentUser,
        startedOnboarding: true,
      });
      commit('setLoading', false);
    } catch (error) {
      commit('setLoading', false);
      commit('setError', authErrorMapping(error));
    }
  },
};
const getters = {
  error: () => {
    return state.error;
  },
  loading: () => {
    return state.loading;
  },
  showOnboardingMessage: () => {
    return !state.currentUser?.startedOnboarding;
  },
};

export const user = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
