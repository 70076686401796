import * as Area from './area';
import { Country } from './country';
import {
  EducationDto,
  EducationStatus,
  EducationType,
} from '../../functions/src/types/education';
export {
  EducationStatus,
  EducationType,
} from '../../functions/src/types/education';
export interface Education {
  averageGrade?: number;
  country?: Country | null;
  graduation?: string[];
  graduationYear?: number;
  title?: string;
  type: EducationType;
  status?: EducationStatus;
  level?: string[];
  areas?: Area.AreaItem[];
}
export const fromDto = (dto: EducationDto): Education => ({
  ...dto,
  areas: dto.areas ? dto.areas.map(Area.toDto) : [],
});
export const toDto = (education: Education): EducationDto => ({
  ...education,
  areas: education.areas?.map(Area.toDto),
});

export const showAttribute = (
  item: Education,
  key: string,
  editMode = false
) => {
  const statusSelected = Array.isArray(item.status)
    ? item.status?.length > 0
    : !!item.status;

  switch (key) {
    case 'graduationYear':
      return (
        statusSelected && !item.status?.includes(EducationStatus.ABGEBROCHEN)
      );
    case 'averageGrade':
      return (
        statusSelected && !!item.status?.includes(EducationStatus.ABGESCHLOSSEN)
      );
    case 'graduation':
      return (
        statusSelected &&
        (!!item.status?.includes(EducationStatus.LAUFEND) ||
          !!item.status?.includes(EducationStatus.ABGESCHLOSSEN))
      );
    case 'level':
      return editMode ? item.type === EducationType.SCHOOL : false;
    case 'areas':
      return statusSelected && item.type !== EducationType.SCHOOL;
    case 'title':
      return editMode && item.type === EducationType.EDUCATION;
    case 'country':
      return statusSelected;
    default:
      return true;
  }
};

export const getLabelForTitle = (item: Education) => {
  switch (item.type) {
    case EducationType.EDUCATION:
      return 'Ausbildung / Studium';
    case EducationType.SCHOOL:
      return 'Schule';
    default:
      return '';
  }
};
