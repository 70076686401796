import { FieldValue, serverTimestamp, usersCollection } from '@/firebase';
import { DeepPartial } from 'functions/src/types';
import * as User from '../../../functions/src/types/user';

export const list = () =>
  usersCollection
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(User.fromFirestore));

export const get = (userId: string) =>
  usersCollection
    .doc(userId)
    .get()
    .then((d) => User.fromFirestore(d));

export const update = (user: DeepPartial<User.UserDto>) =>
  usersCollection.doc(user.id).set(
    {
      ...user,
      updatedAt: serverTimestamp,
    },
    { merge: true }
  );

export const addToWhitelistedCopmanies = (userId: string, companyId: string) =>
  usersCollection.doc(userId).set(
    {
      whitelistedCompanies: FieldValue.arrayUnion(companyId),
      updatedAt: serverTimestamp,
    },
    { merge: true }
  );
export const create = (userId: string, user: DeepPartial<User.UserDto>) =>
  usersCollection
    .doc(userId)
    .set({
      ...user,
      id: userId,
      startedOnboarding: false,
      createdAt: serverTimestamp,
      updatedAt: serverTimestamp,
    })
    .then(() => get(userId));

export const markOnboardingStarted = (userId: string) =>
  usersCollection.doc(userId).set(
    {
      startedOnboarding: true,
      updatedAt: serverTimestamp,
    },
    { merge: true }
  );
