import { UserDto, UserType, Gender } from '../../functions/src/types/user';
import { timestampToISOString } from '@/firebase';
import * as City from './city';
import * as Company from './company';
import { timestampFromDate, timestampFromString } from '@/utilities/Formats';
import { DeepPartial } from 'functions/src/types';
import * as BaseUser from './baseUser';
import * as Country from './country';

export * from '../../functions/src/types/user';
export interface User extends BaseUser.BaseUser {
  phone?: string;
  birthdate?: string;
  type: UserType;
  company?: Company.Company | null;
  active?: boolean;
  updatedAt?: Date;
  createdAt?: Date;
  gender: Gender;
  nationality?: Country.Country | null;
  city?: City.City | null;
  anonymous?: boolean;
  whitelistedCompanies?: string[];
  startedOnboarding?: boolean;
}

// TODO: need type guard - extend with further checks
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isValid = (user: User) => {
  return true;
};

export const fromDto = (dto: UserDto): User => ({
  ...dto,
  ...BaseUser.fromDto(dto),
  birthdate: dto.birthdate ? timestampToISOString(dto.birthdate) : undefined,
  updatedAt: dto.updatedAt?.toDate(),
  createdAt: dto.createdAt?.toDate(),
});

export const toDto = (user: Partial<User> | User): DeepPartial<UserDto> => ({
  ...BaseUser.toDto(user),
  company: user.company ? Company.toDto(user.company) : null,
  birthdate: timestampFromString(user.birthdate),
  updatedAt: timestampFromDate(user.updatedAt),
  createdAt: timestampFromDate(user.createdAt),
  city: user.city ? City.toDto(user.city) : null,
  phone: user.phone,
  type: user.type,
  active: user.active,
  gender: user.gender,
  nationality: user.nationality ? Country.toDto(user.nationality) : null,
  anonymous: user.anonymous,
  startedOnboarding: user.startedOnboarding,
});
