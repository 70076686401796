import {
  MergeFields,
  positionsCollection,
  serverTimestamp,
  SetOptions,
} from '@/firebase';
import { DeepPartial } from 'functions/src/types';
import * as Position from '../../../functions/src/types/position';

export const listForCompany = (companyId: string) =>
  positionsCollection
    .where('company.id', '==', companyId)
    .orderBy('name', 'asc')
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(Position.fromFirestore));

export const get = (positionId: string) =>
  positionsCollection.doc(positionId).get().then(Position.fromFirestore);
export const update = (
  position: DeepPartial<Position.PositionDto>,
  mergeFields?: MergeFields
) => {
  const setOptions: SetOptions = {
    mergeFields: mergeFields ? [...mergeFields, 'updatedAt'] : undefined, // always merge 'updatedAt'
    merge: !mergeFields ? true : undefined,
  };

  return positionsCollection.doc(position.id).set(
    {
      ...position,
      updatedAt: serverTimestamp,
    },
    setOptions
  );
};

export const create = (position: DeepPartial<Position.PositionDto>) =>
  positionsCollection
    .add({
      ...position,
      createdAt: serverTimestamp,
      updatedAt: serverTimestamp,
    })
    .then((ref) => get(ref.id));

export const remove = (positionId: string) =>
  positionsCollection.doc(positionId).delete();
