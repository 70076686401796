import { Timestamp } from '@/firebase';

export const formatCurrency = (number: number) => {
  if (!number) return '-';
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);
};

export const roundNumber = (num: number) => Math.round(num);

export const timestampFromString = (dateString?: string | null) =>
  dateString ? Timestamp.fromDate(new Date(dateString)) : null;

export const timestampFromDate = (date?: Date) =>
  date ? Timestamp.fromDate(date) : undefined;
