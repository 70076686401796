import settingsIcon from '@/components/icons/settingsIcon.vue';
import faqIcon from '@/components/icons/faqIcon.vue';
import conditionsIcon from '@/components/icons/conditionsIcon.vue';
import matchesIcon from '@/components/icons/matchesIcon.vue';
import offeringsIcon from '@/components/icons/offeringsIcon.vue';
import messagesIcon from '@/components/icons/messagesIcon.vue';
import deleteIcon from '@/components/icons/deleteIcon.vue';
import countryIcon from '@/components/icons/countryIcon.vue';
import industryIcon from '@/components/icons/industryIcon.vue';
import likeIcon from '@/components/icons/likeIcon.vue';
import messageIcon from '@/components/icons/messageIcon.vue';
import areaIcon from '@/components/icons/areaIcon.vue';
import calendarIcon from '@/components/icons/calendarIcon.vue';
import gradeAverageIcon from '@/components/icons/gradeAverageIcon.vue';
import graduationIcon from '@/components/icons/graduationIcon.vue';
import levelIcon from '@/components/icons/levelIcon.vue';
import timeIcon from '@/components/icons/timeIcon.vue';
import tickIcon from '@/components/icons/tickIcon.vue';
import editIcon from '@/components/icons/editIcon.vue';
import levelSelectedIcon from '@/components/icons/levelSelectedIcon.vue';
import levelEmptyIcon from '@/components/icons/levelEmptyIcon.vue';
import goBackIcon from '@/components/icons/goBackIcon.vue';
import leftArrowIcon from '@/components/icons/leftArrowIcon.vue';
import rightArrowIcon from '@/components/icons/rightArrowIcon.vue';

export const icons = {
  settings: {
    component: settingsIcon,
  },
  matches: {
    component: matchesIcon,
  },
  conditions: {
    component: conditionsIcon,
  },
  conditionsPrimary: {
    component: conditionsIcon,
  },
  faq: {
    component: faqIcon,
  },
  messages: {
    component: messagesIcon,
  },
  offerings: {
    component: offeringsIcon,
  },
  // delete: {
  //   component: deleteIcon,
  // },
  area: {
    component: areaIcon,
  },
  calendar: {
    component: calendarIcon,
  },
  country: {
    component: countryIcon,
  },
  industry: {
    component: industryIcon,
  },
  like: {
    component: likeIcon,
  },
  message: {
    component: messageIcon,
  },
  averageGrade: {
    component: gradeAverageIcon,
  },
  graduation: {
    component: graduationIcon,
  },
  level: {
    component: levelIcon,
  },
  time: {
    component: timeIcon,
  },
  tick: {
    component: tickIcon,
  },
  edit: {
    component: editIcon,
  },
  levelSelected: {
    component: levelSelectedIcon,
  },
  levelEmpty: {
    component: levelEmptyIcon,
  },
  goBack: {
    component: goBackIcon,
  },
  leftArrow: {
    component: leftArrowIcon,
  },
  rightArrow: {
    component: rightArrowIcon,
  },
};
