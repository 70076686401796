import { CompanyDto } from '../../functions/src/types/company';

export interface Company {
  id: string;
  name: string;
  logoURL?: string;
  website?: string;
  about?: string;
}

export const fromDto = (dto: CompanyDto): Company => ({
  ...dto,
});

export const toDto = (company: Partial<Company>): Partial<CompanyDto> => ({
  ...company,
});
