import * as Country from './country';
import * as Area from './area';
import { AnyKeyObject } from '.';
import { notEmpty } from '../utils';
import { isOnly, isValid } from '../utils/validation';

export enum EducationType {
  SCHOOL = 'school',
  EDUCATION = 'education',
}

export enum EducationStatus {
  LAUFEND = 'laufend',
  ABGESCHLOSSEN = 'abgeschlossen',
  ABGEBROCHEN = 'abgebrochen',
}

export interface EducationDto {
  averageGrade?: number;
  country?: Country.CountryDto | null;
  graduation?: string[];
  graduationYear?: number;
  title?: string;
  type: EducationType;
  status?: EducationStatus | EducationStatus[];
  level?: string[];
  areas?: Area.AreaItemDto[];
}

const fromData = (data: AnyKeyObject) => {
  const education: EducationDto = {
    averageGrade: data.averageGrade ?? null,
    country: Country.fromData(data.country) ?? null,
    graduation: data.graduation ?? null,
    graduationYear: data.graduationYear ?? null,
    title: data.title ?? null,
    type: data.type,
    status: data.status ?? null,
    level: data.level ?? null,
    areas: data.areas ? data.areas.map(Area.fromData).filter(notEmpty) : [],
  };

  return education;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !data) throw new Error('Could not parse education');

  return fromData({ ...data, id: doc.id });
};

export const fromDataSet = (dataSet: AnyKeyObject[]) => {
  return dataSet?.map(fromData).filter(isValidEducation) ?? [];
};

const isValidEducation = (item: AnyKeyObject) =>
  Object.keys(item)
    .filter((k) => k !== 'type')
    .some((k) => {
      if (Array.isArray(item[k])) {
        return item[k].length > 0;
      } else {
        return item[k];
      }
    });

export const educationHasRequiredData = (education: EducationDto) => {
  const { type, status, graduation, areas } = education;

  if (!isValid(status)) return false;

  if (isOnly(status, EducationStatus.ABGEBROCHEN)) {
    return true;
  }

  const hasArea = type === EducationType.SCHOOL || isValid(areas);

  const hasGraduation = isValid(graduation);

  return hasGraduation && hasArea;
};
