import { AnyKeyObject } from '.';

import * as Area from './area';
import * as City from './city';
import firebase from 'firebase/app';
import { notEmpty } from '../utils';
import { allValid } from '../utils/validation';
export interface ConditionsDto {
  jobType: string[];
  startDate?: firebase.firestore.Timestamp | null;
  workingTime: string[];
  duration: string[];
  nationality?: string;
  city?: City.CityDto | null;
  level: string[];
  desiredSalary?: number;
  vacationDays?: number;
  benefits?: string[];
  areas: Area.AreaItemDto[];
}

export const fromData = (data: AnyKeyObject) => {
  const conditions: ConditionsDto = {
    jobType: data?.jobType ?? [],
    startDate: data?.startDate ?? null,
    workingTime: data?.workingTime ?? [],
    duration: data?.duration ?? [],
    nationality: data?.nationality ?? null,
    city: data?.city ? City.fromData(data?.city) : null,
    level: data?.level ?? [],
    desiredSalary: data?.desiredSalary ?? null,
    vacationDays: data?.vacationDays ?? null,
    benefits: data?.benefits ?? [],
    areas:
      data?.areas
        ?.map((area: AnyKeyObject) => Area.fromData(area))
        .filter(notEmpty) ?? [],
  };
  return conditions;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !data) throw new Error('Could not parse conditions');

  return fromData({ ...data, id: doc.id });
};

export const conditionsHasRequiredData = (conditions?: ConditionsDto) => {
  if (!conditions) return;
  const { jobType, workingTime, duration, city, areas, level } = conditions;

  return allValid([jobType, workingTime, duration, city, areas, level]);
};
