const navigationItems = [
  {
    title: 'Ich suche',
    subtitle: 'Meine Jobwünsche',
    icon: '$conditions',
    link: 'Conditions',
    dashboard: true,
  },
  {
    title: 'Ich biete',
    subtitle: 'Meine Qualifikationen',
    icon: '$offerings',
    link: 'Offerings',
    dashboard: true,
  },
  {
    title: 'Matches',
    subtitle: 'Jobs, die zu mir passen',
    icon: '$matches',
    link: 'Matches',
    dashboard: true,
  },
  {
    title: 'Matches',
    subtitle: 'Jobs, die zu mir passen',
    icon: '$matches',
    link: 'Match',
    dashboard: false,
    hide: true,
  },
  {
    title: 'Nachrichten',
    subtitle: 'Chats mit Unternehmen',
    icon: '$messages',
    link: 'Messages',
    dashboard: true,
  },
  {
    title: 'Nachrichten',
    subtitle: 'Chats mit Unternehmen',
    icon: '$messages',
    link: 'MessageMatch',
    dashboard: false,
    hide: true,
  },
  {
    title: 'Einstellungen',
    subtitle: 'E-Mail, Passwort, Logout',
    icon: '$settings',
    link: 'Settings',
    dashboard: true,
  },
  {
    title: 'FAQ Jobsuchende',
    subtitle: 'Alles über Stairship',
    icon: '$faq',
    link: 'Faq',
    dashboard: true,
    hide: true,
  },
  {
    title: 'FAQ Unternehmen',
    subtitle: 'Alles über Stairship',
    icon: '$faq',
    link: 'FaqBusiness',
    dashboard: false,
    hide: true,
  },
];
export default { navigationItems };
