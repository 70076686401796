<template lang="pug">
v-dialog(v-model='dialog'  max-width="390" persistent='' @keydown.esc="abortAction")
  v-card
    div
      v-toolbar(flat color="transparent" tile)
        v-toolbar-title.text-h6.dialog-title.font-weight-bold {{ title }}
        v-spacer
        v-btn.clear-icon(icon @click='disagree')
          v-icon mdi-close
        
      v-divider
    
    v-card-text.px-4.mt-4
        p {{ message }}
        
    v-divider
    v-card-actions.px-4.pb-4.d-flex.flex-column
      //- v-spacer
      v-btn.ml-0.mt-2(color='success' block depressed='' @click='disagree')
        | {{ options.btnDisagree }}
      v-btn.ml-0.mt-2(color='error' block depressed='' @click='agree')
        | {{ options.btnAgree }}
</template>
<script>
export default {
  // props: ['dialog'],
  components: {},
  data() {
    return {
      dialog: false,
      title: '',
      message: '',
      options: {
        btnDisagree: 'Abbrechen',
        btnAgree: 'Ok',
      },
      agreeCallback: null,
      disagreeCallback: null,
    };
  },
  computed: {},
  methods: {
    open({
      title,
      message,
      options = {
        btnDisagree: 'Disagree',
        btnAgree: 'Agree',
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      agree = () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      disagree = () => {},
    }) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = options;
      this.agreeCallback = agree;
      this.disagreeCallback = disagree;
    },
    disagree() {
      this.dialog = false;
      this.disagreeCallback();
    },
    agree() {
      this.dialog = false;
      this.agreeCallback();
    },
  },
};
</script>

<style lang="sass" scoped></style>
