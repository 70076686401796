import {
  candidatesCollection,
  MergeFields,
  serverTimestamp,
  SetOptions,
} from '@/firebase';
import { DeepPartial } from 'functions/src/types';
import * as Candidate from '../../../functions/src/types/candidate';

export const list = () =>
  candidatesCollection
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(Candidate.fromFirestore));

export const get = (candidateId: string) =>
  candidatesCollection
    .doc(candidateId)
    .get()
    .then(Candidate.fromFirestore);

export const update = (
  candidate: DeepPartial<Candidate.CandidateDto>,
  mergeFields?: MergeFields
) => {
  const setOptions: SetOptions = {
    mergeFields: mergeFields ? [...mergeFields, 'updatedAt'] : undefined, // always merge 'updatedAt'
    merge: !mergeFields ? true : undefined,
  };

  return candidatesCollection.doc(candidate.id).set(
    {
      ...candidate,
      updatedAt: serverTimestamp,
    },
    setOptions
  );
};
