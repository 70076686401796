import * as Position from '@/types/position';
import * as PositionsCollection from '@/api/collections/positions';
import { MergeFields } from '@/firebase';

const getPositionsByCompanyId = (companyId: string) =>
  PositionsCollection.listForCompany(companyId).then((positions) =>
    positions.map(Position.fromDto)
  );

const createPosition = (position: Position.Position) =>
  PositionsCollection.create(Position.toDto(position)).then(Position.fromDto);

const deletePosition = (id: string) => PositionsCollection.remove(id);

const updatePosition = (
  position: Position.Position,
  mergeFields?: MergeFields
) => PositionsCollection.update(Position.toDto(position), mergeFields);

const getPositionByID = (id: string) =>
  PositionsCollection.get(id).then(Position.fromDto);

export const PositionsApi = () => ({
  getPositionsByCompanyId,
  updatePosition,
  createPosition,
  deletePosition,
  getPositionByID,
});
