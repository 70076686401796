<template lang="pug">

.pushs
  //- code {{fcmToken}}
  a(:href="notification && notification.click_action ? notification.click_action : '#'")
    v-snackbar(v-model='snackbar' color="accent" top right) 
      strong(v-if="notification && notification.title") {{ notification.title }}
      br
      span(v-if="notification && notification.body") {{ notification.body }}
      //-template(v-slot:action='{ attrs }')
          v-btn(color='white' icon='' v-bind='attrs' @click='snackbar = false')
              v-icon mdi-close

</template>

<script>
import { auth, messaging } from '@/firebase';

export default {
  name: 'PushNotifications',
  data() {
    return {
      fcmToken: '',
      snackbar: false,
      notification: undefined,
    };
  },
  methods: {
    onSnackbarClick() {
      alert('clicked snackbar');
    },
    async checkPushPermission() {
      const pushPermission = await window.navigator.permissions.query({
        name: 'push',
        userVisibleOnly: true,
      });
      return pushPermission.state === 'granted';
    },

    requestToken() {
      if (messaging) {
        messaging
          .getToken(messaging, {
            vapidKey: `${process.env.VUE_APP_FCM_CERT_KEY_PAIR}`,
          })
          .then((currentToken) => {
            if (currentToken) {
              this.fcmToken = currentToken;
              this.$store.dispatch(`user/addFcmToken`, {
                token: currentToken,
              });
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
          });
      }
    },
  },
  async mounted() {
    this.requestToken();
    if (messaging) {
      messaging.onMessage((payload) => {
        if (!auth.currentUser) return;
        this.snackbar = true;
        this.notification = payload.data;
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
