import { timestampToISOString } from '@/firebase';
import * as Area from './area';
import * as Country from './country';
import {
  ExperienceDto,
  ExperienceType,
} from '../../functions/src/types/experience';

import { timestampFromString } from '@/utilities/Formats';
import { map } from 'lodash';

export { ExperienceType } from '../../functions/src/types/experience';
export interface Experience {
  activity?: string;
  areas: Area.AreaItem[];
  countries?: Country.Country[];
  level?: string[];
  timeStart?: string | null;
  timeEnd?: string | null;
  title?: string;
  type?: ExperienceType;
  durationYears?: number;
  allOtherCountries?: boolean;
}

export const fromDto = (dto: ExperienceDto): Experience => ({
  ...dto,
  areas: dto.areas ? dto.areas.map(Area.toDto) : [],
  countries: dto.countries ? dto.countries.map(Country.fromDto) : [],
  timeStart: dto.timeStart ? timestampToISOString(dto.timeStart) : undefined,
  timeEnd: dto.timeEnd ? timestampToISOString(dto.timeEnd) : undefined,
  level: dto.level ?? [],
});
export const toDto = (experience: Experience): ExperienceDto => ({
  ...experience,
  countries: experience.countries?.map(Country.toDto),
  areas: experience.areas?.map(Area.toDto),
  timeEnd: timestampFromString(experience.timeEnd),
  timeStart: timestampFromString(experience.timeStart),
});

export const showAttribute = (
  item: Experience,
  key: string,
  editMode = false,
  isBusiness = false
) => {
  switch (key) {
    case 'title':
      return editMode;
    case 'activity':
      return item.type === ExperienceType.PRACTICAL_EXPERIENCE;
    case 'level':
      return item.type === ExperienceType.PRACTICAL_EXPERIENCE;
    case 'areas':
      return item.type === ExperienceType.PRACTICAL_EXPERIENCE;
    case 'duration':
      return item.type === ExperienceType.PRACTICAL_EXPERIENCE;
    case 'country':
      return !isBusiness || item.type === ExperienceType.OTHER_EXPERIENCE;
    case 'otherCountries':
      return item.type === ExperienceType.OTHER_EXPERIENCE;
    default:
      return true;
  }
};

export const getLabelForTitle = (item: Experience) => {
  switch (item.type) {
    case ExperienceType.PRACTICAL_EXPERIENCE:
      return 'Jobtitel';
    case ExperienceType.OTHER_EXPERIENCE:
      return 'Art';
    default:
      return '';
  }
};
