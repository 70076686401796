import { ScoreDto } from 'functions/src/types/score';

export interface Score {
  percentage: number;
  has: number;
  max: number;
}

export const fromDto = (dto: ScoreDto): Score => ({
  has: dto.has ?? 0,
  max: dto.max ?? 0,
  percentage: dto.percentage ?? 0,
});
