import { FaqDto } from '../../functions/src/types/faq';
import { UserType } from './user';

export interface Faq {
  title: string;
  text: string;
  type: UserType;
}

export const fromDto = (dto: FaqDto): Faq => ({
  title: dto.title,
  text: dto.text,
  type: dto.type,
});

export const toDto = (faq: Faq): FaqDto => ({
  title: faq.title,
  text: faq.text,
  type: faq.type,
});
