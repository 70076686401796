export const allValid = (values: unknown[]) => values.every(isValid);
export const anyValid = (values: unknown[]) => values.some(isValid);

export const isValid = (value: unknown) =>
  Array.isArray(value) ? value.length > 0 : !!value;

export const isOnly = (value: unknown, requiredValue: unknown) =>
  Array.isArray(value)
    ? value.length === 1 && value.includes(requiredValue)
    : value === requiredValue;

export const isEmpty = (arr?: unknown[]) => !arr || arr.length === 0;
