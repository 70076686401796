import { AnyKeyObject } from '.';

export interface ScoreDto {
  percentage: number;
  has: number;
  max: number;
}

export const fromData = (data: AnyKeyObject) => {
  const score: ScoreDto = {
    percentage: data?.percentage ?? 0,
    has: data?.has ?? 0,
    max: data?.max ?? 0,
  };
  return score;
};
