<template lang="pug">
    v-dialog(v-model='dialog' width='600px' persistent='')
      template(v-slot:activator='{ on, attrs }')
        v-icon(color='red darken-1' v-on='on')
            | mdi-delete-outline
      v-card
        div
          v-toolbar(flat color="transparent" tile)
            v-toolbar-title.text-h6.dialog-title.font-weight-bold  Stelle "{{position.name}}" löschen
           
          v-divider
        
        v-card-text.px-4.mt-4
            p Möchten Sie die Stelle wirklich löschen? Diese Aktion ist 
              b unwiderruflich. 
            
        v-divider
        v-card-actions.px-4
          v-spacer
          v-btn(color='grey lighten-1' text='' @click='dialog = false')
            | Abbrechen
          v-btn.activtBtn(color='error' depressed='' @click='deletePosition')
            | Löschen
</template>
<script>
export default {
  props: ['position'],
  components: {},
  data() {
    return {
      dialog: false,
      selected: 0,
      current: 0,
    };
  },
  computed: {},
  methods: {
    deletePosition() {
      const id = this.position.id;
      this.$store.dispatch('business/deletePosition', id).then(() => {
        this.dialog = false;
      });
    },
  },
};
</script>

<style lang="sass" scoped>


.theme--light.v-icon:focus::after
  opacity: 0 !important
</style>
