import { AreaCategoryDto } from '../../types/area';

export const rawAreaCategories: AreaCategoryDto[] = [
  {
    id: 'gFQFTwNseVaryjT3oMcN',
    name: 'Wirtschaft allgemein',
    items: [
      {
        id: 'QGIFsOciCxl7X5C4UeJy',
        name: 'BWL, VWL, Wirtschaftswissenschaften',
      },
      {
        id: 'MzKr7fPyOE4pYQwKCqHA',
        name: 'Allgemeine kaufmännische Berufe',
      },
      {
        id: 'TfR2ezeinSHZywOB2pZN',
        name: 'Vorstand, Geschäftsführung',
      },
      {
        id: 'Dn5FVc8JG3aBJdwhHTOk',
        name: 'Assistenz der Geschäftsführung',
      },
      {
        id: '3G7obAFedysSpwDHVzuk',
        name: 'Office-Management',
      },
      {
        id: 'tYgRQbfBLwNf7BAdMBJb',
        name: 'Sekretariat',
      },
      {
        id: 'HRvJfEEhy9BzCq9NbiYC',
        name: 'Fremdsprachenkorrespondenz',
      },
      {
        id: 'VQQzaVPktflS5PsQhH7r',
        name: 'Empfang',
      },
      {
        id: 'LZLaCAvaIA7IaZciqdtj',
        name: 'Datenerfassung',
      },
      {
        id: 'cpiSCvqMw9C9lqDevIEz',
        name: 'Forschung & Lehre',
      },
      {
        id: 'ufwsEBJdgA85ibrcQlsR',
        name: 'Assistenz',
      },
      {
        id: 'ArZu01AOJB6DTZUMacpV',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'sCYo31arYYqGZ8AYtUyk',
    name: 'Marketing & Werbung',
    items: [
      {
        id: 'yHTQsWibSPREnhSZG6tz',
        name: 'Marketing',
      },
      {
        id: 'FVht6qyru6xemKBpy7Ht',
        name: 'Werbung',
      },
      {
        id: 'hZu56YXNFLOVT17UCjK8',
        name: 'Intern (Unternehmen)',
      },
      {
        id: 'j1pz9BK8TtlaGYpKR90Y',
        name: 'Extern (Agentur)',
      },
      {
        id: 'nlLSSgqftqYMnITcfxrV',
        name: 'Business Development',
      },
      {
        id: 'FJkTkbBEmxQhnoliYz1h',
        name: 'Strategisches Marketing',
      },
      {
        id: 'UNWO51NOxB0wzoLO4bQc',
        name: 'Handels-Marketing',
      },
      {
        id: '5SFJhPA6iAvHghkJyoTv',
        name: 'CRM, Direktmarketing',
      },
      {
        id: 'tNuFOCWDpRVOzgYxbhW0',
        name: 'Online-Marketing',
      },
      {
        id: 'Lcpolqr7zd17MKt9afh0',
        name: 'SEM & SEO',
      },
      {
        id: 'NUHr33T9hIQL6W3LZlv2',
        name: 'Produktmanagement',
      },
      {
        id: '22iF8gpfd01A9RTOC2CM',
        name: 'Event-Marketing',
      },
      {
        id: 'bfbujWn97790Y7CmnfGi',
        name: 'Mediaplanung, Einkauf',
      },
      {
        id: 'yCinaB7mwNXQAbknarrK',
        name: 'Vertriebsmarketing',
      },
      {
        id: 'TtBtkNDgFlzWu0r4OD9n',
        name: 'Marktforschung, Marktanalyse',
      },
      {
        id: 'tZBLPuKFkjX1frWC5TWr',
        name: 'PR, Kommunikation',
      },
      {
        id: '10LTG2OZUYqKEptZzSQ7',
        name: 'Art Direction',
      },
      {
        id: 'Mh5rnVTGgWChexnWlsW9',
        name: 'Bearbeitung & Veröffentlichung',
      },
      {
        id: 'wsbW54iw0a9ZIVnGYBRQ',
        name: 'Programm & Produktion',
      },
      {
        id: 'eg4ohdr5FApYCTYeKaP8',
        name: 'Text',
      },
      {
        id: '82EFD7nL6jCOymSDFrku',
        name: 'Werbefotografie',
      },
      {
        id: 'fdG5Qgu9yUPN5sQG0313',
        name: 'Forschung & Lehre',
      },
      {
        id: 'D2gUmTMUcx0IXGjbhDxC',
        name: 'Assistenz',
      },
      {
        id: 'KExcjVPMoPQmD4V8SjrZ',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'ouffvAiLiyb7yEgquxwO',
    name: 'Vertrieb, Verkauf & Handel',
    items: [
      {
        id: 'ai5AYoqQylfqLsvYhi59',
        name: 'Vertrieb',
      },
      {
        id: 'LVaaAlXSbjdrUVi32qby',
        name: 'Verkauf',
      },
      {
        id: 'ajGVF3E5aiesDNqPzT2z',
        name: 'Groß- & Außenhandel',
      },
      {
        id: 'hKkoSOo2ZeqFNIt3Ekvn',
        name: 'Einzelhandel',
      },
      {
        id: 'jhTfRFYlJdIw7ts8AfYF',
        name: 'Onlinehandel, E-Commerce',
      },
      {
        id: 'yi7kiH3TA6LQOXP8cq13',
        name: 'Business Development',
      },
      {
        id: 'mFfOzi5SBvGF0n02AV7j',
        name: 'Key Account Management',
      },
      {
        id: 'Gnglq1UtL4falL8Cbb3p',
        name: 'Außendienst, Verkaufsberatung',
      },
      {
        id: 'wlOjFpVqe1roPipDEue1',
        name: 'Innendienst',
      },
      {
        id: 'ToNcVGATo5X3pDKYk4js',
        name: 'Sachbearbeitung',
      },
      {
        id: '1cGJY4DfZdT15RoHWC3o',
        name: 'Kundenservice',
      },
      {
        id: '42Tywf5xriXKp58ceMwT',
        name: 'Pre-Sales',
      },
      {
        id: 'Qi1T2nyaWs5haz0bD0mu',
        name: 'Telesales',
      },
      {
        id: 'r4evXX6myGbaDTPjWT3W',
        name: 'Mode',
      },
      {
        id: 'F4izh4o9tB4BiYuV5oRM',
        name: 'Möbel',
      },
      {
        id: 'RzRj1qCk2Q3wZgZMgNsS',
        name: 'Lebensmittel',
      },
      {
        id: 'ZhxDenj3TgL1oGqGGfDx',
        name: 'Pharma',
      },
      {
        id: 'tRVztN7xo5GEK0q8Aluc',
        name: 'Drogerie',
      },
      {
        id: '1SaaAYQRxl437z3q65kz',
        name: 'Foto',
      },
      {
        id: '8qeY0UF6XfxxQUOGmP8A',
        name: 'Automobil & Zulieferer',
      },
      {
        id: '8UZvFRHqCpRciSLDtizU',
        name: 'Elektrogeräte',
      },
      {
        id: '6WslvtFM7WSzemPYMrvG',
        name: 'Bücher',
      },
      {
        id: 'JiqIcBvAHD3HJLmgP1nR',
        name: 'Forschung & Lehre',
      },
      {
        id: 'pymXJu9rV76vKRwxWZM9',
        name: 'Assistenz',
      },
      {
        id: '5bdg4P9dAeotTmKIvJRK',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'UCsPRJEPoiy1YaH7fPpk',
    name: 'Einkauf, Lager & Logistik',
    items: [
      {
        id: '1BYbJC3X45Mr37zDCfNx',
        name: 'Einkauf',
      },
      {
        id: '23b0kv7WBWQXIHGeqcm6',
        name: 'Lager',
      },
      {
        id: 'o89NhJwOLdVH8tpwFoqB',
        name: 'Logistik',
      },
      {
        id: 'WnSGETnAU6hhZWdwsKC0',
        name: 'Intern (Unternehmen)',
      },
      {
        id: 'F6sUVWeiRyYLHIBK128M',
        name: 'Extern (Spedition)',
      },
      {
        id: 'KX98rjAwfXl0wlWGRLCV',
        name: 'Beschaffungslogistik',
      },
      {
        id: '1xP94Ex4LGUWK78lV7Qw',
        name: 'Supply-Chain-Management',
      },
      {
        id: '9Iwl5py6Ngcck6PMiZO6',
        name: 'Disposition',
      },
      {
        id: 'ACFccTT0hdLh3QXe5nTD',
        name: 'Distributions- & Transportlogistik',
      },
      {
        id: 'q1c5218qkgahwCN1a2m9',
        name: 'Prozessmanagement',
      },
      {
        id: 'JA8ZtqMG5JJ6uO8mX1x8',
        name: 'Produktionslogistik',
      },
      {
        id: 'xrBkHbQNoijNrSXXTnov',
        name: 'Fuhrparkmanagement',
      },
      {
        id: 'QFJMHf98GJPgMCXq4Ci0',
        name: 'Güterumschlag',
      },
      {
        id: 'q7vFTQV0x4X6LwjpTAWZ',
        name: 'Lagerarbeit',
      },
      {
        id: 'bqNdsDnUwgEWzCNNbzsD',
        name: 'Hafen',
      },
      {
        id: 'DioerR0ss42rdpu9g41F',
        name: 'Entsorgungslogistik',
      },
      {
        id: 'U5kAIzuFKWGvCch1VxVm',
        name: 'Forschung & Lehre',
      },
      {
        id: 'nN9F2DpdBCQgGKkPKGp2',
        name: 'Assistenz',
      },
      {
        id: '0TQp8ajHDfeSC87BVC7V',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'ZT42mtTBjmGwOKnuFLfJ',
    name: 'Beförderung & Transport',
    items: [
      {
        id: '2JJdJVHuthOGTZAikSmA',
        name: 'Beförderung',
      },
      {
        id: 'udShdtwzBKrEKlshwXnw',
        name: 'Transport',
      },
      {
        id: 'oDqsyE7oX29Pg7tgoarU',
        name: 'Taxi',
      },
      {
        id: 'j2dbcEbhu0vuPYYudOnt',
        name: 'Lkw',
      },
      {
        id: 'yzkRHXP6K6sWJlodGrgE',
        name: 'Bus',
      },
      {
        id: 'pJIF46mMiVqZO3I4BdRj',
        name: 'Bahn',
      },
      {
        id: '2pr7pVIP8xhXLjCmB1gu',
        name: 'Flugzeug',
      },
      {
        id: 'byYjsGA03IeZDb4x9HlN',
        name: 'Schiff',
      },
      {
        id: 'cCxXF3hkg0aKuoABjyrW',
        name: 'Post- & Paketdienste',
      },
      {
        id: 's4IOFyqiRuKUJzYJOy9h',
        name: 'Zustellung',
      },
      {
        id: 'MqggsV4dWPp9QffBbB8E',
        name: 'Kurierdienst',
      },
      {
        id: '4If2siMTUUB7Vq8W6lC0',
        name: 'Umzüge',
      },
      {
        id: 'dYwAWxEuQ9AFEu4eb0V8',
        name: 'Pilot/in',
      },
      {
        id: 't1r2GBgvA1AW80cTN8MJ',
        name: 'Fluglotse/-lotsin',
      },
      {
        id: 'WE5pus7mI7iOnbyW4RRS',
        name: 'Flugbegleitung',
      },
      {
        id: 'iAk7GiEE7GGMP0oqII9J',
        name: 'Bodenpersonal',
      },
      {
        id: '7nqnRvJi9ELGY2G4Qo5x',
        name: 'Schiffsführung',
      },
      {
        id: 'kIEWBxvqCyaVRlGxjzib',
        name: 'Schiffsmannschaft',
      },
      {
        id: 'PUil7vCcjIFzYBQ5V6Ot',
        name: 'Tanken',
      },
      {
        id: 'fyscxzblTjJ0ah8cxVbV',
        name: 'Forschung & Lehre',
      },
      {
        id: 'CXOUZxwV77hpkQk37Ztq',
        name: 'Management & Verwaltung',
      },
      {
        id: '8nxXoPhw4yMFRXOer9Lu',
        name: 'Assistenz',
      },
      {
        id: 'K4gJXfxdKTWXZj0Ng4JI',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'WPf64MoqC30GmJwITTVu',
    name: 'Personalwesen & Recruiting',
    items: [
      {
        id: 'YcHZ2skzyhoAoR3BlnzZ',
        name: 'Personalwesen',
      },
      {
        id: 'CMf57vKFiYOs4MyYRSj9',
        name: 'Recruiting',
      },
      {
        id: 'Hgiwz2ABmEq2z2iQIIri',
        name: 'Personalbetreuung',
      },
      {
        id: 'VRG2UoMmmDotdWqcKr9O',
        name: 'Personalberatung',
      },
      {
        id: '6ISDXWd7GaUj0WIMOoOR',
        name: 'Personalentwicklung & Weiterbildung',
      },
      {
        id: 'LqypjbqJ2QAbWpqxvrvd',
        name: 'Personalmarketing',
      },
      {
        id: 'R3uRMriOKySqkN1WcgnV',
        name: 'Compensation & Benefits',
      },
      {
        id: 'ZjmntBe9ChQPKdg8ZiVu',
        name: 'Lohn & Gehalt',
      },
      {
        id: 'aXiYiIKYdsxgOg6z8JBf',
        name: 'Sachbearbeitung',
      },
      {
        id: 'eRhurikCsABIMWzDvYku',
        name: 'Forschung & Lehre',
      },
      {
        id: 'fsSXL2k0fJcwsTWGagmd',
        name: 'Assistenz',
      },
      {
        id: 'K5dj7szgmvYiGcD1lSpG',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'UCPn8CJjbzFc2G3s7rOP',
    name: 'Finanzen, Prüfung & Steuern',
    items: [
      {
        id: '6mDgC5LoGDAOecxtlicQ',
        name: 'Finanzen',
      },
      {
        id: 'lE2AHG5u9vH0rAU3wCiZ',
        name: 'Steuern',
      },
      {
        id: '2hmz71IFc1mhUiGXhB6t',
        name: 'Wirtschaftsprüfung',
      },
      {
        id: 'zjtZLJUIsWf09Ms47RtW',
        name: 'Steuerberatung',
      },
      {
        id: '3rmVcnHbLvxGhn1Mq5pQ',
        name: 'Wirtschaftsprüfer/in',
      },
      {
        id: 'KdEiiot2p3b3klJcJiHC',
        name: 'Rechtsanwalt/-anwältin',
      },
      {
        id: 'gHdvPKx5PGBfprCo7GyH',
        name: 'Steuerberater/in',
      },
      {
        id: 'PxAgpHNvA7qvlVL1H3OQ',
        name: 'Assistent/in Prüfung/Steuern',
      },
      {
        id: 'khiM8iOUOyapkmk2605U',
        name: 'StFA, StAssistenz usw.',
      },
      {
        id: 'ATnFZEOeOzkzrWGzX6rZ',
        name: 'Office-Management',
      },
      {
        id: '4dbbIczTqxxsM0GN9dy7',
        name: 'Sekretariat',
      },
      {
        id: 'y6zGw7TbSL0NdSByEwze',
        name: 'Rechnungswesen',
      },
      {
        id: 'h0J74UmY1FsY572Tsm2v',
        name: 'Finanz-, Bilanzbuchhaltung',
      },
      {
        id: 'XE9BwiyDZl72H88iUqVx',
        name: 'Debitoren-, Kreditorenbuchhaltung',
      },
      {
        id: 'TmiHdAX1WDXa3MUe2eAQ',
        name: 'Anlagenbuchhaltung',
      },
      {
        id: '0XM2wfNEwkjY4kkHbCdN',
        name: 'Lohn- & Gehaltsbuchhaltung',
      },
      {
        id: 'DMgyE2QYrFapeeoQGPzc',
        name: 'Kostenrechnung',
      },
      {
        id: 'k5ysa53tjbRSLj9NfzBS',
        name: 'Cash Management, Treasury',
      },
      {
        id: 'R232mEWq8Qwr5T9rjEvN',
        name: 'Controlling, Revision',
      },
      {
        id: '8l18nhVG2DOKM074oQj6',
        name: 'Analyse & Berichterstattung',
      },
      {
        id: 'mZln57hGLd2fM9RZGVV6',
        name: 'Budgetierung',
      },
      {
        id: 'yZwLn5SRJ5degUdK5yEA',
        name: 'Strategie & Planung',
      },
      {
        id: 'DEVa5ZBdMUgzcvagb6by',
        name: 'Compliance, Sicherheit',
      },
      {
        id: 'm7rWz3iEXWuRXe8z6tVj',
        name: 'Kapitalgesellschaften',
      },
      {
        id: 'PrqCnBPnVIcpkCKyW5Lc',
        name: 'Personengesellschaften',
      },
      {
        id: 'DkFYJClJoPX16PSa82iW',
        name: 'Industrie',
      },
      {
        id: 'V70yMDrQw0nrNwgMBnQk',
        name: 'Dienstleistungen',
      },
      {
        id: 'WyyKHfbYWcFTgqMwVBEm',
        name: 'Freie Berufe',
      },
      {
        id: 'qA8BMREalZoQvJhi3sqT',
        name: 'Abschlussprüfung & Erstellung',
      },
      {
        id: '9Pi1UZW9UuYbVCA2V84B',
        name: 'Betriebswirtschaftliche Beratung',
      },
      {
        id: 'TwW6NHF9n4PjBwBPII7k',
        name: 'Gründungsberatung',
      },
      {
        id: '5DDOAElqyL39JDDGIKQB',
        name: 'Unternehmensnachfolge',
      },
      {
        id: '7KjDJ0dKX54K2ligKPPB',
        name: 'Umwandlungsrecht',
      },
      {
        id: '2uZpKQnKCLyyGaR38JVR',
        name: 'Mergers & Acquisitions',
      },
      {
        id: 'aXzFWiAX88NAvULQ99MN',
        name: 'Due Diligence',
      },
      {
        id: '6z7zw2G23lj9afJf2YjN',
        name: 'Sanierung',
      },
      {
        id: 'RaVxO4hrDd5g6N5yRBDB',
        name: 'Testamentsvollstreckung',
      },
      {
        id: 'goQhiDId6AQn44NH9rKH',
        name: 'Wirtschaftsmediation',
      },
      {
        id: 'Sbvig1Mug1TLzFXp1Ey1',
        name: 'Steuerdeklaration, -planung',
      },
      {
        id: 'n7yPS27DU6izj6M6v8v7',
        name: 'Steuerrecht allgemein',
      },
      {
        id: 'ggNk8hn7gy02iu1wtu3A',
        name: 'Ertragsteuerrecht',
      },

      {
        id: 'bjQK2HEDfTcDKaFIb8Hn',
        name: 'Verfahrensrecht',
      },
      {
        id: 'xvpAohDLD2OjMvy2UZ42',
        name: 'Bewertungsrecht',
      },
      {
        id: 'ffE5lPSnnU77lJ3vWaKw',
        name: 'Erbschaft- & Schenkungsteuerrecht',
      },
      {
        id: 'Ohk6sm96PpQaimorWz9T',
        name: 'Gemeinnützigkeitsrecht',
      },
      {
        id: 'crZ2mrX6SyLC4n0dBrZ7',
        name: 'Außensteuerrecht',
      },
      {
        id: 'B6tMQMQqG4AOhiKAXksu',
        name: 'Internationales Steuerrecht',
      },
      {
        id: '6UM1uiUxkQ2HrDgVH70L',
        name: 'Forschung & Lehre',
      },
      {
        id: 'R5mNe2IFTNt9M68gDXns',
        name: 'Assistenz',
      },
      {
        id: 'V09LRBUHCCNgYWSedT7j',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: '2p2HTcdyJG29S56uxVA8',
    name: 'Banking, Finanzdienstleistung & Versicherung',
    items: [
      {
        id: 'sI25Sd9Tu7ryguyBU7hI',
        name: 'Banking & Finanzdienstleistung',
      },
      {
        id: 'ljSzV55cZONaiVGudo9f',
        name: 'Versicherung',
      },
      {
        id: 'Gs3fZmKw60txGs72hfEY',
        name: 'Zahlungsverkehr',
      },
      {
        id: 'VYXKd8SmrqZBHvR5py0q',
        name: 'Privatkundengeschäft',
      },
      {
        id: 'ZztEM4dCfON3FR7VI4Jc',
        name: 'Firmenkundengeschäft',
      },
      {
        id: 'QP8sMdLiFJ0lLvQi128a',
        name: 'Kredite',
      },
      {
        id: 'pzEOw0l6J87vbFTOPeWy',
        name: 'Kreditanalyse',
      },
      {
        id: 'oJODNaYDEVXRxjaANPZX',
        name: 'Hypotheken',
      },
      {
        id: 'b3PqMBBRiCto5zFRtmTu',
        name: 'Account & Relationship Management',
      },
      {
        id: '41TSO6tkTL68jfWRCcbo',
        name: 'Anlageberatung, Vermögensberatung',
      },
      {
        id: 'OfGLn3gQrUZpEn5fkDAR',
        name: 'Analyse',
      },
      {
        id: '8ozrejwIFUs3pcMIM0JN',
        name: 'Börsenhandel',
      },
      {
        id: '3hAhOcBYVMk0tjYn2ynK',
        name: 'Asset-/Fonds-Management',
      },
      {
        id: 'tzRyqhlawwEOiZecj2MG',
        name: 'Unternehmensfinanzierung',
      },
      {
        id: 'mX9wg4Y1Q6Hlc3LPxbCw',
        name: 'Investment Banking',
      },
      {
        id: '914YoyNCo2Plg50JsvJb',
        name: 'Mergers & Acquisitions',
      },
      {
        id: 'CAx1IBT90S5hX4MfiQ33',
        name: 'Vertragsmanagement',
      },
      {
        id: 'URrrBZKvvnF12rkxB8yi',
        name: 'Maklergeschäft',
      },
      {
        id: 'M9shPWJubnMYoDnJhu5u',
        name: 'Versicherungsvertrieb',
      },
      {
        id: '6ZKK9an6F7Yw85Agnxs1',
        name: 'Anlagepolitik, Fondsverwaltung',
      },
      {
        id: 'Kn71v4beLkKnjPl6Z0EF',
        name: 'Bewertung',
      },
      {
        id: 'OBN6NtrRZFwo4lkWSdDq',
        name: 'Schadensregulierung',
      },
      {
        id: '9xX2IsV4d9L9vfgb0HHO',
        name: 'Vers.-Mathematik, Produktentwicklung, Statistik',
      },
      {
        id: 'HKOgxR7YWcZemYWa5AAU',
        name: 'Compliance, Sicherheit',
      },
      {
        id: 'OrlJsljSWQvYRZjUcqnC',
        name: 'Volkswirtschaftliche Analyse',
      },
      {
        id: 'VC0MTPEJPWokhoGtMgbi',
        name: 'Sachbearbeitung',
      },
      {
        id: '5LcKCObgk28LQYKC1rFe',
        name: 'Forschung & Lehre',
      },
      {
        id: 'Id83za1TcOOYh6m6HYrU',
        name: 'Assistenz',
      },
      {
        id: 'dMmnJdgdveBH33PisTv3',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'Gnsi0u2mWmnzwDAYYXwb',
    name: 'Immobilien & Eigentum',
    items: [
      {
        id: 'OdWJaqa6cv8HzzTPrS94',
        name: 'Immobilien',
      },
      {
        id: 'EeRse2ohSu7t290zMvim',
        name: 'Maklergeschäft',
      },
      {
        id: 'a05ja5CruK5Paj5qSWBm',
        name: 'Immobilienvermietung',
      },
      {
        id: 'hOR8RKRugoW2JsihrR01',
        name: 'Immobilienverkauf',
      },
      {
        id: 'fHzPnS9HrU38CdvNMZBJ',
        name: 'Immobilienverwaltung',
      },
      {
        id: 'sfCvBigecdUDIVLPjMGM',
        name: 'WEG-Verwaltung',
      },
      {
        id: 'gu6XLj4MQTTHb41r8Mwx',
        name: 'Bewertung',
      },
      {
        id: 'Ehjcb0wLv89RoQgAyPhg',
        name: 'Recherche, Analyse',
      },
      {
        id: '6tGgCVJkxb0wPDKYYSLx',
        name: 'Projektentwicklung',
      },
      {
        id: 'MN9xGAdfoqPLKcXzbG0U',
        name: 'Property Management',
      },
      {
        id: 'fdsZ6ORKPWLYrVu7rIbx',
        name: 'Centermanagement',
      },
      {
        id: '9yz1Ylc63YSSmXkWTVdm',
        name: 'Finanzmanagement',
      },
      {
        id: 'gTnyFP8jptXpthRoOiWi',
        name: 'Fondsmanagement',
      },
      {
        id: 'rIvEcFHPusl5jmJG0F0Y',
        name: 'Facility Management',
      },
      {
        id: 'E3gO8K3pw2774OwRKh7U',
        name: 'Hausmeister/in',
      },
      {
        id: 'MGoyHHKs4HI3w50Rf70Y',
        name: 'Schornstein',
      },
      {
        id: '85gTjL4FadNilBys9D03',
        name: 'Reinigung',
      },
      {
        id: 'O0tpBXyttFgiwAqiQFXa',
        name: 'Entsorgungsdienste, Müllabfuhr',
      },
      {
        id: 'l95tcDmtLdYmDKIGQFXj',
        name: 'Wachdienste & Personenschutz',
      },
      {
        id: 'u41Bt1HyCcqbsK62yE0D',
        name: 'Forschung & Lehre',
      },
      {
        id: 'pJOHHmnWinVDKy2y1Zzo',
        name: 'Management & Verwaltung',
      },
      {
        id: '7D9QjSNpinwTUdJ5MW69',
        name: 'Assistenz',
      },
      {
        id: '9WT0oAtY9PPPf7dhvntF',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'nrTH90kNC31MunmHRgRW',
    name: 'Unternehmensberatung & Coaching',
    items: [
      {
        id: 'S0GfyAgnHnDUuHWbLdX0',
        name: 'Unternehmensberatung',
      },
      {
        id: 'QradNpUf9SqmSe54dGLY',
        name: 'Coaching',
      },
      {
        id: 'Exj0QCzm4XJTcyPqCwjD',
        name: 'Strategie & Planung',
      },
      {
        id: 'AJq3ob9eYxfIIYNceDLt',
        name: 'Unternehmensentwicklung',
      },
      {
        id: 'uaASugLoexpWmaYLTrVh',
        name: 'Organisation',
      },
      {
        id: 'hmz9DWYbgdp3OHyn6JJC',
        name: 'Change Management',
      },
      {
        id: 'uBVTyUFyL4fkI11MCSok',
        name: 'Mergers & Acquisitions',
      },
      {
        id: 'OgLJG1dOJhSQAvxQqrWh',
        name: 'Private Equity',
      },
      {
        id: 'IE1F5x5R9nu0FxGlhkzg',
        name: 'Kosten & Finanzierung',
      },
      {
        id: 'oREbJzvqPRGhjaBWeOYo',
        name: 'Risikomanagement',
      },
      {
        id: 'U0WhuWMSy8ETNup8LokM',
        name: 'Marketing & Vertrieb',
      },
      {
        id: 'QrGuO8wGW4vpzsmOO0Y6',
        name: 'IT-Beratung',
      },
      {
        id: 'ODNPB3x9QGtMWwtd6Nd6',
        name: 'Personalstrategie',
      },
      {
        id: 'SZGZKLDBa3wcuPyczVij',
        name: 'Ingenieurberatung',
      },
      {
        id: 'plx7Nz6ORawhYLD1lAj4',
        name: 'Produktion',
      },
      {
        id: 'wFWa2ko8P9ogtMUhKN3P',
        name: 'Umwelt & Nachhaltigkeit',
      },
      {
        id: 'uTzL4Lsc7Ru8BjozbYIY',
        name: 'Automobil & Zulieferer',
      },
      {
        id: 'd0KG2SQLSFfzup2N1Fpe',
        name: 'Technologie',
      },
      {
        id: '8uuAIKPueJ7K66lwzrM1',
        name: 'Energie, Strom, Öl & Gas',
      },
      {
        id: 'Z1bRJgmryAErUAifxrxO',
        name: 'Konsumgüter',
      },
      {
        id: 'YvK8hKzJI6rifXhZImRm',
        name: 'Banking',
      },
      {
        id: 'LEssZOA5G1WHlEsYMEy6',
        name: 'Versicherungen',
      },
      {
        id: 'xEdl8JnOyUStkTSUV1j4',
        name: 'Gesundheitswesen',
      },
      {
        id: 'ec3Rl0xeP1mj3rztiJ2y',
        name: 'Pharma',
      },
      {
        id: 'SuK1tkdIltOgwK7nxTMc',
        name: 'Öffentlicher Sektor',
      },
      {
        id: '8LJMBQhyFX6nkYwP3Lqe',
        name: 'Transport & Logistik',
      },
      {
        id: 'bkvTvFp6FIBpliuDKmtu',
        name: 'Reisen & Tourismus',
      },
      {
        id: 'fhDk2ko6QbODWEuke8OL',
        name: 'Forschung & Lehre',
      },
      {
        id: 'yOHeey3DeYsqnTKGblOJ',
        name: 'Assistenz',
      },
      {
        id: 'Bpz8yVPWxnDCqVeE2VtU',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'VxhPbf36femr5QFakF7G',
    name: 'IT & Informatik',
    items: [
      {
        id: 'GE4jp76J3fVehQzG9K52',
        name: 'Informatik',
      },
      {
        id: 'Gm89EVuMJOGW6AZmilO5',
        name: 'Wirtschaftsinformatik',
      },
      {
        id: '8McP4q8LXqzfRz2MjGuE',
        name: 'Anwendungsadministration',
      },
      {
        id: '0SPhyYMuhRiU51jVmp9H',
        name: 'Consulting, Engineering',
      },
      {
        id: 'QqauOfjdIj0QDKyqVsmB',
        name: 'Datenbankentwicklung, Data Warehouse, Business Intelligence',
      },
      {
        id: 'FbqmfAiYXFDKeHDIvMVH',
        name: 'Datenbankadministration',
      },
      {
        id: 'JDm9DrHwPQAYQicEQDLP',
        name: 'Embedded Systems, Firmware-Entwicklung',
      },
      {
        id: 'I6eZk4CnZs40XyHvArBU',
        name: 'Hardware-Entwicklung',
      },
      {
        id: 'MUvtyIbIty7zWD7rZDy1',
        name: 'Helpdesk',
      },
      {
        id: 'L2DkEfY308WUDDa5CvCK',
        name: 'IT-Support',
      },
      {
        id: 'f1GBt18OjJOaUexDxlBK',
        name: 'Projektmanagement',
      },
      {
        id: 'l9U9VyW2PKOSvMCLvz1U',
        name: 'Prozessmanagement',
      },
      {
        id: 'diyZEt9mcBa5hsmz9EDI',
        name: 'Qualitätssicherung, Qualitätsprüfung',
      },
      {
        id: 'vSakToS4s8OF1GEFEIj6',
        name: 'SAP/ERP-Beratung, Entwicklung',
      },
      {
        id: 'fofMM93MQ2e4JyRcJBw0',
        name: 'Sicherheit',
      },
      {
        id: '16i8em1vmIiO4QVx78tW',
        name: 'Programmierung, Entwicklung',
      },
      {
        id: 'EIkxSzO6l2CvD219PiNQ',
        name: 'System-, Netzwerkadministration',
      },
      {
        id: 'dAlY5HLUQKkhdNiqI3ZK',
        name: 'Technische Dokumentation',
      },
      {
        id: '8WFVf3qPfWOKVFGkPGo2',
        name: 'Training',
      },
      {
        id: 'zBe8WSwTAn1VyCWOb5y1',
        name: 'Forschung & Lehre',
      },
      {
        id: 'XH1W0k8SZCksl0utyDSb',
        name: 'Management & Verwaltung',
      },
      {
        id: '5VgDkFUQztEotIeEEUru',
        name: 'Assistenz',
      },
      {
        id: 'nTnMD8SfKy4IXTU0WXE1',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'qc2ubtOjIsLiJ3NP7MnG',
    name: 'Recht & Justiz',
    items: [
      {
        id: 'owjqmMPxmrVnXpcwUgTy',
        name: 'Rechtswissenschaften',
      },
      {
        id: 'wrbr6NLkXsIphU5Qmjc4',
        name: 'Wirtschaftsrecht',
      },
      {
        id: 'nghzXrSN6PFl5nDC0aL4',
        name: 'Rechtsanwalt/-anwältin',
      },
      {
        id: 'AIFEb0LeEpPmCz5XYRuX',
        name: 'Notar/in',
      },
      {
        id: 'uscuLI62tAkLFXRGJtTc',
        name: 'ReFA, NotarFA, ReNo, Rechtsfachwirt/in',
      },
      {
        id: 'C21UiLzcVkCWbAH0gkWN',
        name: 'Office-Management',
      },
      {
        id: 'anQ3M8ET6aDLhRJ1bmLx',
        name: 'Sekretariat',
      },
      {
        id: 'A5ntwtbzUyYkglKv34Ag',
        name: 'Richterschaft',
      },
      {
        id: 'UJSbUbXXqiMAH36ttUF7',
        name: 'Staatsanwaltschaft',
      },
      {
        id: 'xfPl31Ado4xg1Ty8HbnZ',
        name: 'Justizfachmitarbeit, Rechtspflege',
      },
      {
        id: 'N0o6rIA2XnwjlUTy1jnN',
        name: 'Sonstiger öffentlicher Dienst',
      },
      {
        id: 'YDA3FCmZ0fJT2PuFCmLx',
        name: 'Rechtsabteilung, Justiziariat',
      },
      {
        id: 'i0S4E8EEjzw9Q3Ehm0zq',
        name: 'Arbeitsrecht',
      },
      {
        id: 'W7X9NqVoGu5N3n4gC1EK',
        name: 'Asylrecht',
      },
      {
        id: 'rpZtlNERNvgmKOHrPQec',
        name: 'Bank- & Kapitalmarktrecht',
      },
      {
        id: 'PlZzhHewZyPd2Do3eX8m',
        name: 'Bau- & Immobilienrecht',
      },
      {
        id: 'aVPUzEp8pCSpffYqsHMe',
        name: 'Beamtenrecht',
      },
      {
        id: '3W5v4M9CWZJHDqPFhILF',
        name: 'Compliance- & Wirtschaftsstrafrecht',
      },
      {
        id: 't11OYLkwAy0lfpoCWjOW',
        name: 'Energierecht',
      },
      {
        id: '1qnwqlBOouhr5TZgTodq',
        name: 'EU-Recht',
      },
      {
        id: 'ZUCjhn8sLIv7azHaMfbM',
        name: 'Familien- & Erbrecht',
      },
      {
        id: '41ZV1RbRpGpufiwwlIU8',
        name: 'Gesellschaftsrecht',
      },
      {
        id: 'pnOhLG0Qv9Rwcfdzmln6',
        name: 'Gewerblicher Rechtsschutz',
      },
      {
        id: 'z1dMi1cUFBmIksVWM2Pi',
        name: 'Handels- & Wirtschaftsrecht',
      },
      {
        id: 'zFeY8pH4Lho402UA5I81',
        name: 'Insolvenzrecht',
      },
      {
        id: 'TOk7VaOJjhOLQCcJIOS8',
        name: 'Internationales Recht',
      },
      {
        id: 'KZzqU0TxwUANrtkPwDhe',
        name: 'IT- & Datenschutzrecht',
      },
      {
        id: 'rMzJdz9s6OOYkZ2rV2nh',
        name: 'Litigation & Arbitration',
      },
      {
        id: 'Ke1wjffyFSsr2LvbLPzA',
        name: 'Mergers & Acquisitions',
      },
      {
        id: 'lYq5UVV75FdZn6ppPiIN',
        name: 'Öffentliches Wirtschaftsrecht',
      },
      {
        id: 'CN8Fs7L4rMjV6711YLFM',
        name: 'Patentrecht',
      },
      {
        id: '0P3eO4aCBspTwkQBENVa',
        name: 'Steuerrecht',
      },
      {
        id: '9P0jMLrDBbBItdcMWmS1',
        name: 'Strafrecht',
      },
      {
        id: 'OqthzHAtAr8aEElCZrKV',
        name: 'Umweltrecht',
      },
      {
        id: '17Waje170EmJ8GpzUaEA',
        name: 'Urheberrecht',
      },
      {
        id: 'nppkZ7letdrZeVeM7z9i',
        name: 'Vereins- & Stiftungsrecht',
      },
      {
        id: 'qJPlM0FSRfE7Y0BZv6cW',
        name: 'Verfassungsrecht',
      },
      {
        id: 'kMTBThWt8XCNIpgQfyDm',
        name: 'Vergaberecht',
      },
      {
        id: 'cooHPaY6SdGekPfbg6zJ',
        name: 'Verkehrsrecht',
      },
      {
        id: '3CvissHZRd3x8j2ncttl',
        name: 'Versicherungsrecht',
      },
      {
        id: 'xnh6kSmXjPlamzko4yX6',
        name: 'Verwaltungs- & Sozialrecht',
      },
      {
        id: 'vxAmm36Ki3W3D2ccjzbP',
        name: 'Völkerrecht',
      },
      {
        id: 'lZHmjzkacYsYBIIcttxB',
        name: 'Wettbewerbs- & Kartellrecht',
      },
      {
        id: '3MRowPoEqRQwj2dbJ5Jb',
        name: 'Mediation',
      },
      {
        id: '5T2ydq0k1rlH5YHCYiFU',
        name: 'Forschung & Lehre',
      },
      {
        id: 'mzhLYgV5oMT0kQYYL1xt',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: '93b8Wg1KEVgIEo9H5WFO',
    name: 'Medizin, Pharmazie & Psychologie',
    items: [
      {
        id: '17ZHllyoAIDM3PIROI1w',
        name: 'Humanmedizin',
      },
      {
        id: 'vDtQG1QM5vtzogRsI2q3',
        name: 'Tiermedizin',
      },
      {
        id: 'B8CFlze1THEy1hKUAq1k',
        name: 'Zahnmedizin',
      },
      {
        id: 'L7UPv2MGPk530o8wd7UO',
        name: 'Pharmazie',
      },
      {
        id: 'ZqeVNeoJvTywkDijqtgH',
        name: 'Psychologie',
      },
      {
        id: 'xhIK1moqzm6lZlePEgLR',
        name: 'Psychotherapie',
      },
      {
        id: 'd80sbW9zMNJuG4CP8EJd',
        name: 'Arzt/Ärztin',
      },
      {
        id: 'm7dvTHOWk1L3G8D5YXeV',
        name: 'Tierarzt/-ärztin',
      },
      {
        id: 'aENzH0qFauHrDYf8ye2R',
        name: 'Zahnarzt/-ärztin',
      },
      {
        id: 'tyggimzIDwjg3gk8KfPq',
        name: 'Pharmazeut/in',
      },
      {
        id: 'bav3taeXen51vtnZNkn4',
        name: 'Praxis',
      },
      {
        id: 'tuqoe7DqbrS90DfwpP2o',
        name: 'Uniklinik, Klinik',
      },
      {
        id: 'MY4OfSOTgsnMarvkgdVH',
        name: 'Labor',
      },
      {
        id: 'CYjKyEW9I0Mh7MA2zgWn',
        name: 'Klinische & med. Forschung',
      },
      {
        id: '9id5Ragnj895a9bp4Rt0',
        name: 'Office-Management',
      },
      {
        id: 'Ss78gQTlKEEzIbkWzoQH',
        name: 'Sekretariat',
      },

      {
        id: 'B012639WC1H16SinLYoZ',
        name: 'Allgemeinmedizin',
      },
      {
        id: '8tjcCuR5yjSTvgg8JvlJ',
        name: 'Anästhesiologie, Anästhesie',
      },
      {
        id: 'RXkPwtxQP8uGrXNwSbHB',
        name: 'Arbeitsmedizin',
      },
      {
        id: 'FCsyCOiGuIaUfjWtkNMG',
        name: 'Augenheilkunde',
      },
      {
        id: 'FaaABM9izLacibtegFq2',
        name: 'Chirurgie',
      },
      {
        id: 'BXtH5Btu4lvyDx6pYivx',
        name: 'Dermatologie, Geschlechtskrankheiten',
      },
      {
        id: '707KizC5WxW5C8ERoOWp',
        name: 'Endoskopie',
      },
      {
        id: 'AihyTKj6WJKxzgd1RPmf',
        name: 'Frauenheilkunde, Geburtshilfe',
      },
      {
        id: '3x7fPpfCKyDr7T8HEsPX',
        name: 'Geriatrie, Gerontologie',
      },
      {
        id: 'plQSjJRcG5rWRPEqbqvJ',
        name: 'Hals-Nasen-Ohrenheilkunde',
      },
      {
        id: 'lmZWY8gp9sjW2omOPVYh',
        name: 'Hygienemedizin, Umweltmedizin',
      },
      {
        id: 'lmttJfBm1oSwBRvUjLLA',
        name: 'Innere Medizin',
      },
      {
        id: '6dTQLe3UsQZhvHJvPAYQ',
        name: 'Intensivmedizin',
      },
      {
        id: 'BS3Mx6OvP7YJcgswETO8',
        name: 'Kardiologie',
      },
      {
        id: '5rBIxcyC6FmeHZpkxg4H',
        name: 'Kiefer- & Mundchirurgie, Gesichtschirurgie',
      },
      { id: 'c3fBKAbVgpAL3EYSMcOS', name: 'Kinder- & Jugendpsychiatrie' },
      {
        id: 'MFQkio2g38Mhs9b3PlaZ',
        name: 'Naturheilkunde & Alternativmedizin',
      },
      {
        id: 'HXraSUxTbf4k6NcBv5bF',
        name: 'Nephrologie',
      },
      {
        id: 'UJLE7x0RRkgSpaot5g6F',
        name: 'Neurochirurgie, Neurologie, Neuropathologie',
      },
      {
        id: 'aDxo5MEKcpbaEi3Nuc9J',
        name: 'Onkologie',
      },
      {
        id: 'uOu9xHPnMwzlTS9odJVQ',
        name: 'Orthopädie, Unfallchirurgie',
      },
      {
        id: 'I3XFhdI7qpt1wPd2UbvX',
        name: 'Pädiatrie',
      },
      {
        id: 'W6RTZyRsEZE6lRSIQf9U',
        name: 'Palliativ & Hospiz',
      },
      {
        id: 'jAWj5HorXBykkJI365GW',
        name: 'Pathologie',
      },
      {
        id: '1TweXATfn7UKG9xSZk62',
        name: 'Psychiatrie',
      },
      {
        id: 'YzxlRDEZT8hUNDgaFAtQ',
        name: 'Radiologie',
      },
      {
        id: '3F1vKrtM5BULyQApoews',
        name: 'Urologie',
      },
      {
        id: 'oDBndAI6CBoFZD2AUN6N',
        name: 'Virologie',
      },
      {
        id: 'gyvo7GKD3Uv1D0Dd0FvH',
        name: 'Forschung & Lehre',
      },
      {
        id: 'R2eSyo1MAAyEsGrxwOfe',
        name: 'Management & Verwaltung',
      },
      {
        id: '3EuzufVxIFlBSWuQ5quV',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'LGhNRMNrxy1vPwSWhwDI',
    name: 'Gesundheitsberufe & Pflege',
    items: [
      {
        id: 'bd576PQTMkh6XgmoH7QZ',
        name: 'Gesundheit',
      },
      {
        id: 'D0dJGpSUTMS8UMXJuwzP',
        name: 'Pflege',
      },
      {
        id: 'iprBdYX8Oa1nUyLcMOkN',
        name: 'PTA, MTA, BTA, CTA, PKA, MFA usw.',
      },
      {
        id: 'rwRnR32zMIXgSZjlrxqV',
        name: 'Sprechstundenassistenz',
      },
      {
        id: 'dZPko5p1GGReLDLqqldg',
        name: 'Office-Management',
      },
      {
        id: 'JKS25E6JgvANhKfHKv8R',
        name: 'Sekretariat',
      },
      {
        id: 'TExSym90RCEanNYJgA0y',
        name: 'Ernährung',
      },
      {
        id: 'iFYEKGxcD91ko9CXn3Gt',
        name: 'Osteopathie, Chiropraktik',
      },
      {
        id: 'GXRAj2zFN0lXHprBm3B0',
        name: 'Heilpraktik',
      },
      {
        id: 'zwZkltiPMxloVaIrCOre',
        name: 'Physiotherapie, Massage',
      },
      {
        id: 'HaIOk1l40dYXHVvtpq0o',
        name: 'Rehabilitation',
      },
      {
        id: '1z9ZmV91ozrQjxqEvEQO',
        name: 'Logopädie',
      },
      {
        id: 'fbThcvfAnKsGNnerpIEj',
        name: 'Ergotherapie',
      },
      {
        id: 'VXHFqb4n0XwcVkezJUHI',
        name: 'Augenoptik, Optometrie',
      },
      {
        id: '7AZRdCiGvWoGs3irPnUV',
        name: 'Akustik',
      },
      {
        id: 'P7RonpkxesSe97AcGBhT',
        name: 'Zahntechnik',
      },
      {
        id: 'JNhrvDTxpO6il6mB8yK6',
        name: 'Kardiotechnik',
      },
      {
        id: 'JYNjA60yjH0mmvcBmITj',
        name: 'Orthopädietechnik',
      },
      {
        id: 'JWBtLWhOG2iPPSLHykRa',
        name: 'Podologie',
      },
      {
        id: 'nufYP3VQXCxdYJyRLgu1',
        name: 'Hygiene, Desinfektion',
      },
      {
        id: 'BKzNwLJBnUjvciQ6O3Sx',
        name: 'Hebamme, Entbindung',
      },
      {
        id: 'v1N4WuPoWM6Gk5wOc410',
        name: 'Betreuung',
      },
      {
        id: 'RiiryuAtsJ8abMwTjRpq',
        name: 'Heilerziehungspflege',
      },
      {
        id: 'aDq5i9N1dzo2RmWjypdF',

        name: 'Sanitätsdienst, amb. Dienst, Rettungsdienst',
      },
      {
        id: '6RHfJFy9mhbFJty8r3iX',
        name: 'Forschung & Lehre',
      },
      {
        id: '7e8xyCPWtIRQytkLkktx',
        name: 'Management & Verwaltung',
      },
      {
        id: 'RTGFCMlLAOROynYMELvJ',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'arFmOvIXwLtPmS1kAjwz',
    name: 'Naturwissenschaften & Mathematik',
    items: [
      {
        id: 'Mc53ieMxsWLVYP1ApuXQ',
        name: 'Biologie',
      },
      {
        id: 'aJRQgEUcWMjkTSlVLRNP',
        name: 'Chemie',
      },
      {
        id: 'vEg87ahsQ7q53AssNBJg',
        name: 'Physik',
      },
      {
        id: 'ND4MfvMSvgWhabz4OWK9',
        name: 'Mathematik & Statistik',
      },
      {
        id: 'EvZGFwKXlqhGbiQw8pzZ',
        name: 'Astronomie',
      },
      {
        id: 'v0y27pBbjrUNG1Gzfjrv',
        name: 'Biotechnologie & Genetik',
      },
      {
        id: '63YzDMB5y85LnaBtwcbi',
        name: 'Nanowissenschaften',
      },
      {
        id: 'KghbbCaO9RBr4P63vvLs',
        name: 'Geowissenschaften',
      },
      {
        id: 'JSzO24EVh8mta3kkx9Of',
        name: 'Lebensmitteltechnologie',
      },
      {
        id: 'v3Z9CLTUjyagO32frnnS',
        name: 'Lebensmittelkontrolle',
      },
      {
        id: 'BOo0UFxai3W16Cxzfkil',
        name: 'Materialwissenschaften',
      },
      {
        id: 'jJVhChdyeKZqsWLXG292',
        name: 'Fachmitarbeit, Labor',
      },
      {
        id: 'I3gAhD6Fpp0XGBxVX58J',
        name: 'Forschung & Lehre',
      },
      {
        id: 'sPF5TcZ8RWprqLd5NT7Q',
        name: 'Management & Verwaltung',
      },
      {
        id: '5tn30IM5JZLOByuAcmm9',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'G2qkE9s7CHmDSgc8sfDj',
    name: 'Geisteswissenschaften & Sprachen',
    items: [
      {
        id: 'XCC2G9QUgAUCud5y8Y7w',
        name: 'Soziologie',
      },
      {
        id: 'DpYbO5xAOjwEGI23fiuT',
        name: 'Politikwissenschaften',
      },
      {
        id: 'JszQ597a4WeCFhW6MV1v',
        name: 'Geschichte',
      },
      {
        id: 'z3ads4w0VfCXQEwMkhDZ',
        name: 'Regionalstudien',
      },
      {
        id: '5RFu17MbSlhCTG3wdG0R',
        name: 'Philosophie',
      },
      {
        id: 'QZxSfNBAUVeygVLGByRQ',
        name: 'Rhetorik',
      },
      {
        id: 'C4jwWMr3sIRDNFkNxXkj',
        name: 'Archäologie',
      },
      {
        id: 'c7h5Z5hnRfwb5hPihNDL',
        name: 'Ethnologie, Anthropologie',
      },
      {
        id: 'QvQBuUADHF4XOLqd7ALb',
        name: 'Theologie & Religion',
      },
      {
        id: 'ooyATTgBpgWQfEMdiI4G',
        name: 'Linguistik, Sprachwissenschaften',
      },
      {
        id: 'fwcKx34QtAx7rEQMpl4h',
        name: 'Übersetzen & Dolmetschen',
      },
      {
        id: 'dEEr2zLKKLZrxdbskhQY',
        name: 'Literaturwissenschaften',
      },
      {
        id: 'Glko5SrcVQZvbkkyS7o8',
        name: 'Deutsch, Germanistik',
      },
      {
        id: 'a7W7T5bB4e1VHunK7vUs',
        name: 'Englisch, Anglistik, Amerikanistik',
      },
      {
        id: 'fvXWtcNnxwLK0raWDu2E',
        name: 'Romanistik',
      },
      {
        id: 'l2VM4OPjXOlEKZF1UwpR',
        name: 'Französisch',
      },
      {
        id: 'Be91uJt0Iky1gIyd9XLr',
        name: 'Italienisch',
      },
      {
        id: 'fBlIRfud7txc5JYGatZc',
        name: 'Spanisch',
      },
      {
        id: 'yPGaJ4lyRxuI1RJMKNKE',
        name: 'Portugiesisch',
      },
      {
        id: 'H5huSaECmU54SXZn7Dnm',
        name: 'Griechisch',
      },
      {
        id: 'e47f6IM8bVBzXnVyUZ7o',
        name: 'Slawistik',
      },
      {
        id: 'P46s72I6T50fy8piNxgY',
        name: 'Polnisch',
      },
      {
        id: 'dopb1BRgnxZisUsyBNOP',
        name: 'Russisch',
      },
      {
        id: 'LNuQliQbiRnJDjLwrIap',
        name: 'Orientalistik',
      },
      {
        id: '84nLP1hXLgMJhk2AHDBz',
        name: 'Ostasienwissenschaften',
      },
      {
        id: '2qrYj9nQSaLCGePDA01F',
        name: 'Chinesisch, Sinologie',
      },
      {
        id: '3HdwciIhlqXK97GqPWM3',
        name: 'Japanisch, Japanologie',
      },
      {
        id: 'a1L9BG9asv3V6huTG3J2',
        name: 'Alte Sprachen',
      },
      {
        id: 'Yt2Apimh1oG5EnPYtrIQ',
        name: 'Sonstige Sprachen',
      },
      {
        id: 'sqlsCl5POj0XHm1oyC8f',
        name: 'Forschung & Lehre',
      },
      {
        id: '7OZdJZPFTpAiQJuqwYiG',
        name: 'Management & Verwaltung',
      },
      {
        id: 'LKoUbVKACf0RBpAtyXCr',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'jjdwYmWAZDyScQn87Bvv',
    name: 'Produktion, Bau & Handwerk',
    items: [
      {
        id: '5PVtBWBYrDClTNofm7ac',
        name: 'Produktion',
      },
      {
        id: 'U39UWZGyTMgMWqK39mUF',
        name: 'Bau',
      },
      {
        id: 'fW6IPWPgxuM0VnR9pbcb',
        name: 'Handwerk',
      },
      {
        id: 'EbnEOfTOlqQRA8MZZRnP',
        name: 'Technik, Servicetechnik',
      },
      {
        id: 'AAvAjssDt8b66INGfcJ8',
        name: 'Automobil & Zulieferer',
      },
      {
        id: '29dvOO3j42pC7y4QInXe',
        name: 'Maschinenbau',
      },
      {
        id: 'lnc6wxp1t7Vm7oi7i5kz',
        name: 'Chemieproduktion',
      },
      {
        id: 'NLGH5t1E8AZDqr6UeJqJ',
        name: 'Pharmaproduktion',
      },
      {
        id: 'FPlPVToE3u3BXGAMwZ0z',
        name: 'Lebensmittel, Ernährung',
      },
      {
        id: 'SNCg37hBaBDcnByDyRGl',
        name: 'Elektrik, Elektronik',
      },
      {
        id: 'MPekDyC9pbfE9Ms7ti1i',
        name: 'Metall',
      },
      {
        id: 'klt6qnc9pIUaCKnAIoxM',
        name: 'Energie, Strom, Öl & Gas',
      },
      {
        id: 'G4QVzSKiV22240jpIkmh',
        name: 'Kunststoff, Holz',
      },
      {
        id: 'FThtTz9HucJZb8FY2xiW',
        name: 'Papier, Packmittel',
      },
      {
        id: '6YbvFmC3cU13Nfiai3BR',
        name: 'Textil, Leder',
      },
      {
        id: 'y1zWQSoVbBtu2RQHIhLT',
        name: 'Glas, Keramik, Porzellan',
      },
      {
        id: 'XXc5XdyteiJa5B30floi',
        name: 'Stein, Beton',
      },
      {
        id: 'WTU8IY2c8VOKiHXHWPGM',
        name: 'Schiffs-, Boots- & Flugzeugbau',
      },
      {
        id: 'RVH18lwk6RLwxQ1uJ4iw',
        name: 'Bergbau',
      },
      {
        id: 'LOlZ93amjuTsd7dlvOA0',
        name: 'Maschinen- & Anlagenführung',
      },
      {
        id: 'hfyudH67lhWMG9DynRfN',
        name: 'Anlagentechnik',
      },
      {
        id: 'arjrETZyW2jLT4sxYpyN',
        name: 'Zerspanungsmechanik',
      },
      {
        id: 'GfJw0TESQTO4AjBwGvS9',
        name: 'Schweißtechnik',
      },
      {
        id: 'GWFn3xFCmffL6kQofmp2',
        name: 'Sintererzeugnisse',
      },
      {
        id: 'Z4lVwzSPzyrueiAHGmk9',
        name: 'Schlosserei',
      },
      {
        id: 'ou5AgP4OWpLwOXP89mTB',
        name: 'Montage, Aufbau',
      },
      {
        id: 'jOW1vVWWwG2beLSJK8Qs',
        name: 'Mechanik, Mechatronik',
      },
      {
        id: '04LbZFrBn9XkUOh1WWGU',
        name: 'Halbleiter- & Mikrosystemtechnik',
      },
      {
        id: 'XK1pn7JdibH0cFXaCTzJ',
        name: 'Gießerei',
      },
      {
        id: 'PbVOOgvl0vnq7izh9v5r',
        name: 'Kesselbau',
      },
      {
        id: 'duu9SsUgBWhlm8m4Tpo3',
        name: 'Materialprüfung',
      },
      {
        id: 'jumzkY8K8RzXwOmJXzJY',
        name: 'Arbeitssicherheit',
      },
      {
        id: 'DUAbq3USiq32dRgnOg6I',
        name: 'Qualitätskontrolle',
      },
      {
        id: 'jGHa0ebTeyszUZ3tThZI',
        name: 'Industrielabor',
      },
      {
        id: 'eg2iHzusn8s8CVGQovI1',
        name: 'Techn. Dokumentation & Zeichnen',
      },
      {
        id: 'kV3PBv0bXrQeClrBQMyi',
        name: 'Vermessung & Geomatik',
      },
      {
        id: 'BcY2npYr9nKOCfZpucOU',
        name: 'Hochbau, Tiefbau',
      },
      {
        id: 'bwfpMZ54KdzMsJ0ARvNx',
        name: 'Trockenbau',
      },
      {
        id: 'wOmjLF9p9eEFw6IfOFw2',
        name: 'Bauzeichnen',
      },
      {
        id: 'y7qCvABNQlEQp7v2CLll',
        name: 'Baustoffprüfung',
      },
      {
        id: 'CnaYIczw0zORgjl1fRCn',
        name: 'Kreislauf & Abfall',
      },

      {
        id: 'QAziRsEHkZUUDNp3HR1N',
        name: 'Bodenverlegung, Estrich',
      },

      {
        id: 'NzxWARqp07SUii3LFVZy',
        name: 'Fenster & Rollläden',
      },
      {
        id: 'JKs4cPTEuzgEbv2EpM9v',
        name: 'Stuck',
      },

      {
        id: '6cLwwPfYZjRCE52cxGNs',
        name: 'Maurerei, Betonbau',
      },
      {
        id: 'fK6yYtujR7QwVDzHZbZB',
        name: 'Zimmerei, Dachdeckerei',
      },
      {
        id: 't8WOFid2Mwhyi7fwQbCy',
        name: 'Sanitär, Heizung & Klima',
      },
      { id: 'pZ0z0MA8vfgAOj3roqiY', name: 'Innenausbau & Isolierung' },
      {
        id: 'k3EGgKOb0inH5b5gv2K1',
        name: 'Maler- & Lackiererei',
      },
      {
        id: 'qAWOi4SE4JUpL5EzgTdo',
        name: 'Tischlerei & Schreinerei',
      },
      {
        id: 'iNSfd84RLdmrqvHaqoc6',
        name: 'Parkett',
      },
      {
        id: 'BpivV97o3wSJEy7gPTEn',
        name: 'Fliesen',
      },
      {
        id: 'j8VcVyWIz7iJG7v7mCiK',
        name: 'Kfz-Handwerk',
      },
      {
        id: 'n5A589hlpIOFaJmD0Yfn',
        name: 'Bäcker/in, Konditor/in',
      },
      {
        id: 'pUTvOV3hy2zd5kMivtog',
        name: 'Fleischer/in, Metzger/in',
      },
      {
        id: '3ugqkbpJCm5Fgkp78c6m',
        name: 'Brauen & Brennen',
      },
      {
        id: 'ggI3iSdwzUVqVaTGQ7b7',
        name: 'Schneidern & Schustern',
      },
      {
        id: '4nAmLpfesMArxDhDMpN8',
        name: 'Polstern',
      },
      {
        id: '6idnL3emqtRlS9PehmTV',
        name: 'Drucken & Buchbinden',
      },
      {
        id: 'yZJ1lmitqrh87JQEPW5T',
        name: 'Schmuck, Uhren',
      },
      {
        id: 'nYHcwgHghssWNcVmwFgo',
        name: 'Instrumentenbau',
      },
      {
        id: 'Lnnhm0oCkqge5FdUfseo',
        name: 'Textilreinigung',
      },
      {
        id: 'gvpnoFA7iwZNpbZl8YSa',
        name: 'Messe- & Bühnenbau',
      },
      {
        id: 'jGYeMoyBJzRVQbeNK3e0',
        name: 'Forschung & Lehre',
      },
      {
        id: 'NBJDr8dlAsDzqTX63u8g',
        name: 'Management & Verwaltung',
      },
      {
        id: 'knCv9uwClYB5dsTKJVXa',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: '5ksv82SdXwtFu6caMx8f',
    name: 'Ingenieur- & Wirtschaftsingenieurwesen',
    items: [
      {
        id: 'tgeg8qHM3gFWYO5qu7hj',
        name: 'Ingenieurwesen',
      },
      {
        id: 'mMrrflVLLdlNoGaorhsJ',
        name: 'Wirtschaftsingenieurwesen',
      },
      {
        id: '4f1wJNGxeEMCYiEtgD7z',
        name: 'Vertrieb, Inbetriebnahme',
      },
      {
        id: 'etnGNtxPdXpNcQuRmtJJ',
        name: 'Projektmanagement',
      },
      {
        id: 'Zzr9PtTIBaU7fvRURlsM',
        name: 'Prozessmanagement',
      },
      {
        id: '4QsXDntrrd7BsHvjlNmV',
        name: 'Qualitätsmanagement',
      },
      {
        id: 'r5scq5auG2wuP8mvKDod',
        name: 'Forschung & Entwicklung',
      },
      {
        id: 'LPSrKr34jt8miJLScAfi',
        name: 'Maschinenbau',
      },
      {
        id: 'HCjFYmeFrGMu9895xPIm',
        name: 'Anlagenbau',
      },
      {
        id: 'YnFEGdD7J0hPLmN4TEen',
        name: 'Produktions- & Fertigungstechnik',
      },
      {
        id: 'TWssipG8mUuX6GrnSM2s',
        name: 'Verfahrenstechnik',
      },
      {
        id: 'vdZFRsL0LtMD6BebCPkr',
        name: 'Automatisierungstechnik',
      },
      {
        id: 'r3xM4GKpQkgpnGKO9kky',
        name: 'Konstruktion, Visualisierung',
      },
      {
        id: 'I2FmD9Rw8RZZZApDVN5R',
        name: 'Elektrotechnik, Elektronik',
      },
      {
        id: '1smv42nfinfKpC1aGk1P',
        name: 'Veranstaltungstechnik',
      },
      {
        id: 'BdiYr8b9DORoXxVhZ87D',
        name: 'Mechatronik, Systemtechnik',
      },
      {
        id: 't2aPXEwVI66s9qNNshNT',
        name: 'Mechanik',
      },
      {
        id: '00ejbh12uh86LhH3pWps',
        name: 'Optische Technologien',
      },
      {
        id: 'RV6ZrsunLQgsNFYsG7mm',
        name: 'Medizintechnik',
      },
      {
        id: '3qYyH0bUk9n0dHFZpdlc',
        name: 'Chemieingenieurwesen',
      },
      {
        id: 'DPdRp1uROrHWIZazSKjW',
        name: 'Pharmaingenieurwesen',
      },
      {
        id: '5rVXEoKnI5eRryHUaXM8',
        name: 'Bioingenieurwesen',
      },
      {
        id: 'j3eWp3D93lI81YkTsNc3',
        name: 'Lebensmitteltechnologie, Brauwesen',
      },
      {
        id: 'MzvDNhfXJnOC8F4UfM2q',
        name: 'Energie, Strom, Öl & Gas',
      },
      {
        id: 'Xtgg72iOv6c64wT1AIgb',
        name: 'Bergbau',
      },
      {
        id: 'FczDtW1PI1A5t9sLHIXG',
        name: 'Wasser- & Abfalltechnik',
      },
      {
        id: 'VbkwKEFPKNBxFwvmWR9m',
        name: 'Umwelttechnik',
      },
      {
        id: 'rrCd4TbjhpnzHpPL12BF',
        name: 'Bauingenieurwesen, Hoch- & Tiefbau',
      },
      {
        id: 'jQpidyu7inCULo4PfR7z',
        name: 'Gebäude-, Versorgungs-, Sicherheitstechnik',
      },
      {
        id: '5Y2OvJ5Uebrv5zY5K9YI',
        name: 'Vermessungstechnik',
      },
      {
        id: 'C3fqaaSF9KWBNnUMjTCk',
        name: 'Fahrzeug- & Verkehrstechnik',
      },
      {
        id: 'nAF2noOD3bkqLjdYD5H8',
        name: 'Luft- & Raumfahrt',
      },
      {
        id: 'Sh06WQFzRTL33EVWUSln',
        name: 'Schiffs- & Meerestechnik',
      },
      {
        id: 'wOXkpTBTEDNkZNPYPcGP',
        name: 'Werkstoff- & Materialwissenschaften',
      },
      {
        id: 'frOAWHQYBQJQfDsWgLXc',
        name: 'Holztechnik',
      },
      {
        id: 'Aq7ZLN3iAHjeQ5V29OJb',
        name: 'Kunststofftechnik',
      },
      {
        id: 't4RwK7sibiQ8O9d5ruHx',
        name: 'Textiltechnik',
      },
      {
        id: '5sEwuELtoxlne1F2NhUw',
        name: 'Gießereitechnik',
      },
      {
        id: '32Jh4PeTvWKcgOcA9xem',
        name: 'Metalltechnik',
      },
      {
        id: 'KxI7Hyg86iEFJThPyhDL',
        name: 'Papiertechnik',
      },
      {
        id: 'UUSnnuSIDwveaPJoHyj2',
        name: 'Druck, Medien & Verpackung',
      },
      {
        id: 'unMZC2qXFyUhxH3IrVW6',
        name: 'Glas & Keramik',
      },
      {
        id: '2z2Y2Z27PxTjXEbh37BH',
        name: 'Forschung & Lehre',
      },
      {
        id: 'aHhPOv47KvqHv7wj01KZ',
        name: 'Management & Verwaltung',
      },
      {
        id: 'HYmxFyWIwqcRXos135Vj',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'UKVpglOktfQHHxPC72Wa',
    name: 'Architektur & Design',
    items: [
      {
        id: 'I8euZPK1MG9XinmbP9eB',
        name: 'Architektur',
      },
      {
        id: 'fS8IkEY4kRllzzGc1kIj',
        name: 'Design',
      },
      {
        id: '4ctKaHCk9FxPJTwluaXG',
        name: 'Stadt-, Raum- & Regionalplanung',
      },
      {
        id: 'Qu2PxNehwCpgZuGAJQec',
        name: 'Landschaftsarchitektur',
      },
      {
        id: 'deKjeCAv1GWAlnoUSDkf',
        name: 'Innenarchitektur',
      },
      {
        id: 'AhyPPyb8skSlRQXEbwTr',
        name: 'Raumgestaltung',
      },
      {
        id: 'nWajLqTfdtpE0Y1tKP52',
        name: 'Grafik- & Kommunikationsdesign',
      },
      {
        id: 'DOwqZd9tl626SlKN9Cfv',
        name: 'Medien-, Screen-, Webdesign',
      },
      {
        id: 'F4449drFLLwk97zsNtj1',
        name: 'Modedesign, Schmuckdesign',
      },
      {
        id: 'XyBbC7F8f4LhirAr9Or3',
        name: 'Produktdesign, Industriedesign',
      },
      {
        id: '7vzudkT9urGLlvHxqMhr',
        name: 'Forschung & Lehre',
      },
      {
        id: 'UTBIctQoVCRi57mIJYp2',
        name: 'Management & Verwaltung',
      },
      {
        id: 'PWQwulXGVUbJ5j3gGOzP',
        name: 'Assistenz',
      },
      {
        id: 'cksXizbbO6j1Rw1McpA9',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'tgLbspdffuBLt8fIUOOE',
    name: 'Medien, Kunst & Kultur',
    items: [
      {
        id: 'sLQrhrfXG1zWHYdXJRC0',
        name: 'Medien, Medienwissenschaften',
      },
      {
        id: 'FjrKyc6PGwudXt1CwZiW',
        name: 'Kunst, Kunstwissenschaften',
      },
      {
        id: 'DybZKHPxRFQSDJGmfeHK',
        name: 'Kultur, Kulturwissenschaften',
      },
      {
        id: '2p0DR1FuZ2GcviFtKvkN',
        name: 'Fernsehen',
      },
      {
        id: 'Gf9vq527SW6I949hRDr1',
        name: 'Rundfunk',
      },
      {
        id: 'vJ6FBxyiyes5VmSIdI7A',
        name: 'Printmedien',
      },
      {
        id: 'gpl823KfCEzJ8vlSNMQ2',
        name: 'Onlinemedien',
      },
      {
        id: 'OmmiOZD5U6yjsB0posSL',
        name: 'Film, Video',
      },
      {
        id: '1ZhSxlJKgVYrw1XMYkvd',
        name: 'Oper',
      },
      {
        id: 'rv7pTz5Edjv9nlWinpkN',
        name: 'Theater',
      },
      {
        id: 'RmTzzi5bQFVaHFh6EyKP',
        name: 'Musical',
      },
      {
        id: 'loEkOcR9sgaVx0Ugsrvs',
        name: 'Journalismus, Redaktion',
      },
      {
        id: 'G8yYVE6o95jWUmeEyOCP',
        name: 'Kommunikationswissenschaften',
      },
      {
        id: 'GRYKorVmu0P81fKjLHBv',
        name: 'Moderation',
      },
      {
        id: '11Fk0Z0xH7yFyebPic6c',
        name: 'Regie, Dramaturgie',
      },
      {
        id: '3DBlgFSnGHccXiVggLGt',
        name: 'Kamera',
      },
      {
        id: 'HdXMoLiIg7QhG6wePTPQ',
        name: 'Produktion',
      },
      {
        id: 'h4VvWsmBuXKge174QSlW',
        name: 'Programm',
      },
      {
        id: 'sbK2yi2kOq2yqNigU1Lm',
        name: 'Schauspiel',
      },
      {
        id: 'rA4CFKumcDeQGVlDD7q0',
        name: 'Gesang',
      },
      {
        id: 'SlvPt3Jack2fFo5z35t5',
        name: 'Musik',
      },
      {
        id: '0VGXuy1L87A3KqR4iMvD',
        name: 'Tontechnik, Musikproduktion',
      },
      {
        id: 'QmdEQPC3gmpPWkwDlFdc',
        name: 'Orchester',
      },
      {
        id: 'CMLuGlDI4bubBf2zA3ck',
        name: 'Dirigieren',
      },
      {
        id: 'EeKzpn1G8d4qQHFD0KHC',
        name: 'Komponieren',
      },
      {
        id: '4JXLgtNjPMVGBywChB3z',
        name: 'Tanz',
      },
      {
        id: 'Fv2CKXIX10nQvi8Q3F7Q',
        name: 'Choreographie',
      },
      {
        id: 'SkEqI7hzbXQWn39pokfN',
        name: 'Kabarett, Comedy',
      },
      {
        id: '7Iew0DbGVLF5D5W73Szh',
        name: 'Bühnenbild',
      },
      {
        id: 'jZXVgkKD7qtikHpwgxzo',
        name: 'Maske',
      },
      {
        id: 'LvoOCsvNKHgOvPnJk0Lu',
        name: 'Kostüme',
      },
      {
        id: 'bZhUiyNXOwXWev7Ceyvs',
        name: 'Museum',
      },
      {
        id: 'HExPY2p4CBeU5mfKO9QG',
        name: 'Malerei',
      },
      {
        id: 'wwDN76ssltvH7OvEwxLq',
        name: 'Bildhauerei, Plastik',
      },
      {
        id: 'yi6UgS4fPn3crXRaNmo9',
        name: 'Kunsthandwerk',
      },
      {
        id: '8VQeRWgPRpAwo0ihe9uI',
        name: 'Restaurierung',
      },
      {
        id: 'rWtlsH9hgpohtUJ74pfE',
        name: 'Fotografie',
      },
      {
        id: 'Q0MmIL09r6TsWfmT22YR',
        name: 'Schriftstellerei, Literatur',
      },
      {
        id: 'XYUGz6u4Akq7WhzSSIaq',
        name: 'Hörspiel',
      },
      {
        id: '8OHMdjHRWwR2pTRmgqDP',
        name: 'Zirkus, Artistik',
      },
      {
        id: 'hQb7G46NkN4MYrEAxNCg',
        name: 'Forschung & Lehre',
      },
      {
        id: 'Wqpo2WmWFjVOFdFgURgt',
        name: 'Management & Verwaltung',
      },
      {
        id: 'yPdSrq6Ubu1i8LdcfFqq',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'sgkSEcuk4GM9ai0Jn0AL',
    name: 'Bildung & Soziales',
    items: [
      {
        id: 'nTO0Zbv8WR7Ipd5TzT1M',
        name: 'Pädagogik & Erziehung',
      },
      {
        id: 'cvSaqrjCWc1jRnMkFbEl',
        name: 'Bildung',
      },
      {
        id: 'CKdmY8wIxYVBzU9MT2zA',
        name: 'Sozialwissenschaften',
      },
      {
        id: 'xn6Ty4k2RDRW1xSugZm0',
        name: 'Kindergarten, KiTa, Vorschule',
      },
      {
        id: 'K4YDo0OaUQnAuz3KsYu7',
        name: 'Kinderbetreuung & Tagesmutter',
      },
      {
        id: 'YZJ6Ne4q8WJbL9ykfW5c',
        name: 'Lehramt Primarstufe',
      },
      {
        id: 'QBIrUgCJ9A1PqALhHTH9',
        name: 'Lehramt Sekundarstufen',
      },
      {
        id: 'EQFliInVoUrwZQ0A6yWq',
        name: 'Lehramt Berufsschule',
      },
      {
        id: 'B7Cp6nU7X8fuKYJSSrzB',
        name: 'Lehramt Sprachen',
      },
      {
        id: 'zC5HKllpSLE68BNSxRQZ',
        name: 'Lehramt Naturwissenschaften',
      },
      {
        id: 'RlqbAdmjIECH99pF7dD3',
        name: 'Lehramt Mathematik',
      },
      {
        id: '2ejkidI6iJPGqYCvwFyh',
        name: 'Lehramt Sport',
      },
      {
        id: 'AtyXk7EipW42BPFTFZ6X',
        name: 'Lehramt Kunst, Musik, Gestalten',
      },
      {
        id: 'mjlj6UHgThN3zYPdQ1Ew',
        name: 'Lehramt Erdkunde, Geografie, Geologie',
      },
      {
        id: 'xYXtK4D3RclMhpmIXhqs',
        name: 'Lehramt Religion',
      },
      {
        id: 'GdeGF76RqIV48tw2f1vR',
        name: 'Lehramt Geschichte',
      },
      {
        id: 'ieDeYOfcSWXwLMG2xARF',
        name: 'Lehramt Ethik, Philosophie',
      },
      {
        id: 'ELZrvbVAM8uE2uMVSaJv',
        name: 'Lehramt Informatik',
      },
      {
        id: '6jH18teVmKKOl1hRZEU0',
        name: 'Lehramt Politik, Gemeinschaftskunde',
      },
      {
        id: 'YgRGjy8IOOgjE2TQvr0W',
        name: 'Lehramt Ernährung, Hauswirtschaft',
      },
      {
        id: 'liFqxizwJDyRKUbDxRsR',
        name: 'Lehramt Pädagogik, Erziehung',
      },
      {
        id: '1O31JV58RU4AAC1jLoSs',
        name: 'Lehramt Wirtschaft',
      },
      {
        id: 'U9IVdSXID6ScrXfMXHoK',
        name: 'Lehramt Technik',
      },
      {
        id: 'uKZCrUlMqS24fy7HA1EA',
        name: 'Lehramt Gesundheit, Pflege',
      },
      {
        id: '3rH6Y4VJhTAxldIja3s9',
        name: 'Lehramt Agrarwirtschaft',
      },
      {
        id: 'Jn5b5MxHFNOR6449uCXl',
        name: 'Lehramt sonstiges',
      },
      {
        id: 'FTMqCJYYzUuHiWt7Lz3U',
        name: 'Bibliothek, Archiv',
      },
      {
        id: 'F9a5aDHWBDd0kv4XxT7b',
        name: 'Studentenwerk',
      },
      {
        id: 'pD5tUIwDPBdH19hjhjPp',
        name: 'Tutoren',
      },
      {
        id: 'feIKmWAPCJa9MQ8ikzWW',
        name: 'Erwachsenenbildung',
      },
      {
        id: 'RxrLjTgluxwKCMS3Crc3',
        name: 'Hauswirtschaft',
      },
      {
        id: 'nEuriU4RSOuzMrrdxHvz',
        name: 'Ehrenamt',
      },
      {
        id: 'hccQXIClT6P82i0DPCcF',
        name: 'Gemeinnützigkeit',
      },
      {
        id: '0nwleA4wPoDUQMisxlWE',
        name: 'Sozialarbeit & Beratung',
      },
      {
        id: 'gbLqmVxDY30UimOm0wMV',
        name: 'Alten- & Behindertenbetreuung',
      },
      {
        id: 'qKxZVTzPwLjYtPHGmrg3',
        name: 'Obdachlosendienste',
      },
      {
        id: 'jevrI77bUap606snP7dU',
        name: 'Forschung & Lehre',
      },
      {
        id: '20Rd3UepANDQo3URwibL',
        name: 'Management & Verwaltung',
      },
      {
        id: 'h2d9UmugCvzxQvzqGDkE',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'BOTCmzYn7ushobjT4G6i',
    name: 'Öffentlicher Dienst & Politik',
    items: [
      {
        id: 'RvvsLkXpYerHnwj9xV2I',
        name: 'Bundesebene',
      },
      {
        id: 'Re7qAr0lUN7LrzBFAuFd',
        name: 'Landesebene',
      },
      {
        id: '9glwSIXeVDeikkq3JhSr',
        name: 'Lokalebene',
      },
      {
        id: 'iPabvqViQxHNGJtK2lM8',
        name: 'Innere Verwaltung',
      },
      {
        id: 'h7IVs2733jY4Wu1IHRR8',
        name: 'Auswärtiger Dienst',
      },
      {
        id: '8BwSWvdq6rG9sEroh5w7',
        name: 'Finanzverwaltung, Zoll',
      },
      {
        id: 'jPODlicl0Sh0Samq3QOO',
        name: 'Zentralbankwesen',
      },
      {
        id: 'zrsPMY7LY8JXxFfDlvxs',
        name: 'Polizei',
      },
      {
        id: 'arNLzRkH8rxQwjWOZE06',
        name: 'Vollzugsdienst',
      },
      {
        id: 'EwSNLuKUdTJNY8X4R8Kn',
        name: 'Nachrichtendienste',
      },
      {
        id: 'T9gJQFEpufFr36WWQHTv',
        name: 'Arbeitsvermittlung',
      },
      {
        id: 'aGz2Ai8yLrkZKCP8Nggs',
        name: 'Sozialversicherung',
      },
      {
        id: 'sfLdux7HvbBbhtlmMvdg',
        name: 'Heer',
      },
      {
        id: '2pC91jPCvAijUPVKWzVo',
        name: 'Marine',
      },
      {
        id: 'qeDhet2QOxlQKIYKZyxs',
        name: 'Luftwaffe',
      },
      {
        id: 'nB3erRajLoA5ZOumJpx0',
        name: 'Wehrverwaltung',
      },
      {
        id: 'pxuglI6EpuLbPMjh0Zpl',
        name: 'Feuerwehr, Technisches Hilfswerk',
      },
      {
        id: 'HfMBIdGazIlUT5S46XxY',
        name: 'Politische Tätigkeit',
      },
      {
        id: '8A9EKfDtYo1b5A3SzZ8F',
        name: 'Kammern, Verbände',
      },
      {
        id: 'JnfO1tbwwljAbvvJ0bdT',
        name: 'Management & Verwaltung',
      },
      {
        id: 'LzAH20fKxKNSLdwm2KBk',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'mfWdcZVCpOcpEHiM3oow',
    name: 'Beauty, Sport & Freizeit',
    items: [
      {
        id: 'vMJabQgzanIe875CcfxC',
        name: 'Beauty, Kosmetik',
      },
      {
        id: 'rsU9xlagSoEXJgP8jO5P',
        name: 'Friseur/in',
      },
      {
        id: 'hWE1mSyyLZ7k4baUmWgL',
        name: 'Wellness',
      },
      {
        id: 'yHTTUeKn2LvwtUGqjMoj',
        name: 'Sport',
      },
      {
        id: '1Mq8PbGFPI6h3yZBY4KR',
        name: 'Fitness',
      },
      {
        id: 'DxTPB7IAYAkiJwTrTjrA',
        name: 'Training, Unterricht',
      },
      {
        id: 'f3MKJKgYEnXDSiJOt9hN',
        name: 'Bäderbetrieb',
      },
      {
        id: 'DNdmzjvEgQ25jy5J8HsD',
        name: 'Vereine, Verbände',
      },
      {
        id: '1BArjz4eaz1abSf8OE94',
        name: 'Veranstaltungen, Events',
      },
      {
        id: 'hc3y36OzHlJbPZZgV3MI',
        name: 'Management & Verwaltung',
      },
      {
        id: 'jZ4lN8HdM2Nf61DIQC1f',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'XzLUJ5RscF6jgoStCT4K',
    name: 'Gastronomie & Tourismus',
    items: [
      {
        id: 'IMConvO2TkmJLeft5pwr',
        name: 'Gastronomie',
      },
      {
        id: 'hIGZysFOmmQMrKAjVZmx',
        name: 'Tourismus',
      },
      {
        id: 'tk9xj8wAlnjQgWXjCG2u',
        name: 'Hotel',
      },
      {
        id: 'n9Towzrg8v625fJVHHYT',
        name: 'Kochen',
      },
      {
        id: 'clnwzePOFm3g9gECbFn2',
        name: 'Küchenhilfe',
      },
      {
        id: 'OiSIXq8FODKJkAbVsvjJ',
        name: 'Bedienung, Service',
      },
      {
        id: 'uuE7wvsrqaqR0ktLOkuu',
        name: 'Bar',
      },
      {
        id: 'LUip08u5WhvwLaUBT06H',
        name: 'Rezeption',
      },
      {
        id: 'XN1UqcQofxQxGsTQZWCe',
        name: 'Zimmer',
      },
      {
        id: 'HZ9u4ykp3Tlk2J15s0Je',
        name: 'Animation',
      },
      {
        id: 'Uc42ucZlYfF5NFXueeLX',
        name: 'Reiseveranstaltung',
      },
      {
        id: 'EaupEzgcEHCQmo6C7Sqf',
        name: 'Reisebüro',
      },
      {
        id: 'GpldUbqpBk7u4c4Ck0Nx',
        name: 'Reiseleitung',
      },
      {
        id: 'YjGzvZzcxB4r4AUfD6l8',
        name: 'Veranstaltungen, Events',
      },
      {
        id: 'WtA5vq4BPYIE3pnjsHzp',
        name: 'Catering',
      },
      {
        id: 'yrSdb2WSXOTpmxQ9vZ5K',
        name: 'Management & Verwaltung',
      },
      {
        id: 'LnRFj6YWLcUbEm8m6svO',
        name: 'Sonstiges',
      },
    ],
  },
  {
    id: 'md3ntV0E4Gfuiw5MWfjs',
    name: 'Pflanzen, Tiere & Umwelt',
    items: [
      {
        id: 'hCMKzC81LbcN1h8hO7vO',
        name: 'Agrarwissenschaften',
      },
      {
        id: 'weBXyPOd36fzl5hNIgky',
        name: 'Forst- & Holzwirtschaft',
      },
      {
        id: 'ptNkgHJCVHyDIBUHQplw',
        name: 'Ackerbau',
      },
      {
        id: 'pv7el91nzi432D2TbFrV',
        name: 'Gartenbau',
      },
      {
        id: 'jnaFdoDIKeamRDjHoSbG',
        name: 'Floristik',
      },
      {
        id: 'FtLghJR0jmHL3mSusDCW',
        name: 'Winzerei & Weinbau',
      },
      {
        id: 'wayg2PpElUFaxLScGY7y',
        name: 'Tierhaltung',
      },
      {
        id: '7uTlAdpS5LEGB3bwErk2',
        name: 'Fischerei',
      },
      {
        id: 'StJNhpkXRStjau2ooUOF',
        name: 'Botanik',
      },
      {
        id: 'gSroic7DnZRGDRYBGEq1',
        name: 'Pflanzentechnologie',
      },
      {
        id: 'bi5vObUfS7NPIqpwX3gk',
        name: 'Umwelt- & Naturschutz, Ökologie',
      },
      {
        id: 'AQFOPfMrjhM8Opj1AN0z',
        name: 'Tierschutz',
      },
      {
        id: 'hRkm5aeCHvha2A1fyLiW',
        name: 'Jagd',
      },
      {
        id: 'W9a4Agl7NzJPvGFHNfIB',
        name: 'Nachhaltigkeit',
      },
      {
        id: 'KH9ZsnIWtUOx6RaA3wf1',
        name: 'Tierzucht',
      },
      {
        id: 'RJZyCTq0kG3lkEv8JFvh',
        name: 'Zoos & Parks',
      },
      {
        id: 'odMem3jcnMkVBfiQTaES',
        name: 'Tierpflege',
      },
      {
        id: 'Tb3b84oCza2oleIrK2VI',
        name: 'Hundeschule, -pension',
      },
      {
        id: 'kx0WivbCDbhGLblY7obM',
        name: 'Tierheim',
      },
      {
        id: 'WqALj63eDhNKTI1mgTzW',
        name: 'Management & Verwaltung',
      },
      {
        id: 'sJDNRclgboNJuS35SGaV',
        name: 'Sonstiges',
      },
    ],
  },
];

export const areaCategories = (): AreaCategoryDto[] => {
  return rawAreaCategories.map((cat) => ({
    ...cat,
    items: cat.items.map((item) => ({
      ...item,
      areaCategory: { ...cat, items: [] },
    })),
  }));
};
