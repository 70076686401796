import * as Country from './country';
import * as Area from './area';

import firebase from 'firebase/app';
import { AnyKeyObject } from '.';
import { notEmpty } from '../utils';
import { allValid, anyValid } from '../utils/validation';
export enum ExperienceType {
  PRACTICAL_EXPERIENCE = 'practicalExperience',
  OTHER_EXPERIENCE = 'otherExperience',
}
export interface ExperienceDto {
  activity?: string;
  areas: Area.AreaItemDto[];
  countries?: Country.CountryDto[];
  level?: string[];
  timeStart?: firebase.firestore.Timestamp | null;
  timeEnd?: firebase.firestore.Timestamp | null;
  title?: string;
  type?: ExperienceType;
  durationYears?: number;
  allOtherCountries?: boolean;
}

const fromData = (data: AnyKeyObject) => {
  const practicalExperience: ExperienceDto = {
    activity: data.activity ?? null,
    areas: data.areas ? data.areas.map(Area.fromData).filter(notEmpty) : [],
    countries: data.countries ? data.countries.map(Country.fromData) : [],
    level: data.level ?? null,
    timeStart: data.timeStart ?? null,
    timeEnd: data.timeEnd ?? null,
    title: data.title ?? null,
    type: data.type ?? null,
    durationYears: data.durationYears ?? null,
    allOtherCountries: !!data.allOtherCountries,
  };

  return practicalExperience;
};

export const fromFirestore = (doc: AnyKeyObject) => {
  const data = doc.data();
  if (!doc || !data) throw new Error('Could not parse experience');

  return fromData({ ...data, id: doc.id });
};

export const fromDataSet = (dataSet: AnyKeyObject[]) => {
  return dataSet?.map(fromData).filter(isValidExperience) ?? [];
};

const isValidExperience = (item: AnyKeyObject) =>
  Object.keys(item)
    .filter((k) => k !== 'type')
    .some((k) => {
      if (Array.isArray(item[k])) {
        return item[k].length > 0;
      } else {
        return item[k];
      }
    });

export const experienceHasRequiredData = (
  experience: ExperienceDto,
  isBusiness: boolean
) => {
  const { title, activity, type, areas, level, allOtherCountries, countries } =
    experience;

  if (isBusiness) {
    if (type === ExperienceType.PRACTICAL_EXPERIENCE) {
      return allValid([areas, level]);
    } else {
      return anyValid([allOtherCountries, countries]);
    }
  } else {
    if (type === ExperienceType.PRACTICAL_EXPERIENCE) {
      return allValid([title, areas, level, activity]);
    } else {
      return allValid([title, countries]);
    }
  }
};
