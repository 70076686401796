import { auth, FieldValue } from '@/firebase';
import * as UserCollection from '@/api/collections/users';
import * as NotificationSettingsCollection from '@/api/collections/notificationSettings';
import {
  NotificationCategory,
  NotificationType,
} from '@/types/notificationSettings';

const updateNotificationSetting = (
  type: NotificationType,
  category: NotificationCategory,
  enabled: boolean
) => {
  const uid = auth.currentUser?.uid;
  if (!uid) return;

  if (enabled) {
    return NotificationSettingsCollection.enableNotification(
      uid,
      category,
      type
    );
  } else {
    return NotificationSettingsCollection.disableNotification(
      uid,
      category,
      type
    );
  }
};

const addFcmToken = async (token: string) => {
  const uid = auth.currentUser?.uid;
  if (!uid) return;

  return NotificationSettingsCollection.setFcmToken(uid, token);
};
const removeFcmToken = async (token: string) => {
  const uid = auth.currentUser?.uid;
  if (!uid) return;

  return NotificationSettingsCollection.removeFcmToken(uid, token);
};

const fetchSettings = async () => {
  const uid = auth.currentUser?.uid;
  if (!uid) return;

  return NotificationSettingsCollection.fetchSettings(uid);
};

const setAnonymous = async (active: boolean) =>
  UserCollection.update({ id: auth.currentUser?.uid, anonymous: active });

const addWhitelistedCompany = async (companyId: string) => {
  const uid = auth.currentUser?.uid;
  if (!uid) return;
  return UserCollection.addToWhitelistedCopmanies(uid, companyId);
};
export const UserSettingsApi = () => ({
  updateNotificationSetting,
  fetchSettings,
  removeFcmToken,
  addFcmToken,
  setAnonymous,
  addWhitelistedCompany,
});
