import { AreaCategory } from '@/types/area';

import { rawAreaCategories } from '../../../functions/src/assets/selections/areas';

export const areaCategories = (): AreaCategory[] => {
  return rawAreaCategories.map((cat) => ({
    ...cat,
    items: cat.items.map((item) => ({
      ...item,
      areaCategory: { ...cat, items: [] },
    })),
  }));
};
