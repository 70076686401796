import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import { user } from './user';
import { candidate } from './candidate';
import { business } from './business';
import { faq } from './faq';
import { messages } from './messages';

Vue.use(Vuex);
const debug = window.location.hostname === 'localhost';

export const store = new Vuex.Store({
  modules: {
    user,
    candidate,
    business,
    faq,
    messages,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
