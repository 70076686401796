import { countries } from '@/assets/selections/countries';
import { CountryDto } from '../../functions/src/types/country';
export interface Country {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
}

export const getCountryByCode = (code: string) => {
  return countries.find((country) => country.alpha2 === code);
};

export const fromDto = (dto: CountryDto): Country => ({
  ...dto,
});

export const toDto = (country: Country): CountryDto => ({
  ...country,
});
