<template lang="pug">

.px-md-8.py-8
  v-alert.mx-4.mb-4(v-if='error', color='red', dark='', dismissible='')
    span(v-if='error' v-html='error' )
  .px-4.mb-8(flat color="transparent")
    
      h2 Login
      p.switch-auth-link
        | Du hast noch kein Kundenkonto?
        router-link(to="/register" style='text-decoration: none;') Jetzt registrieren
  v-form.mt-2(
    ref='form',
    v-model='valid',
    @submit.prevent='login',
    lazy-validation=''
  )
    v-card-text
      v-row
        v-col.py-0(cols='12')
          v-text-field(
            v-model='email',
            label='E-Mail Adresse',
            type='text',
            outlined,
            required='',
            :rules='emailRules'
          )
        v-col.py-0(cols='12')
          v-text-field#password(
            v-model='password',
            outlined,
            :rules='loginPasswordRules',
            label='Passwort',
            type='password',
            required=''
          )

      v-btn.py-6.text-capitalize.mt-5(
        color='accent',
        rounded='',
        depressed,
        block,
        :disabled='!valid || loading',
        :loading='loading',
        type='submit'
      ) Login
      ForgotPasswordDialog


</template>

<script>
import FormRules from '@/utilities/FormRules';
import Footer from '@/components/navigation/Footer.vue';
import ForgotPasswordDialog from '@/components/settings/ForgotPasswordDialog.vue';
export default {
  components: {
    Footer,
    ForgotPasswordDialog,
  },
  data() {
    return {
      ...FormRules,
      valid: true,
      password: '',
      email: '',
      checkbox: false,
      lazy: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    error() {
      return this.$store.getters['user/error'];
    },
    loading() {
      return this.$store.getters['user/loading'];
    },
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('user/login', {
          email: this.email.trim(),
          password: this.password,
        });
      }
    },
    resetPassword() {
      this.$store.dispatch('user/resetPassword', {
        email: this.email.trim(),
      });
    },
  },
};
</script>
