import * as CompaniesCollection from '@/api/collections/companies';
import { companyStorageRef } from '@/firebase';
import * as Company from '@/types/company';

const get = (id: string) => CompaniesCollection.get(id).then(Company.fromDto);

const update = (company: Partial<Company.Company>) =>
  CompaniesCollection.update(Company.toDto(company));

const create = (company: Company.Company) =>
  CompaniesCollection.create(Company.toDto(company)).then(Company.fromDto);

const uploadCompanyLogo = async (companyId: string, blob: Blob) => {
  const currentTimestamp = Date.now();

  const imgRef = companyStorageRef.child(
    `${companyId}/logo-${currentTimestamp}.png`
  );

  await imgRef.put(blob);
  const photoURL = (await imgRef.getDownloadURL()) as string;
  return photoURL;
};

export const CompanyApi = () => ({
  get,
  update,
  create,
  uploadCompanyLogo,
});
