import { timestampToISOString } from '@/firebase';
import * as Area from './area';
import * as City from './city';
import { ConditionsDto } from '../../functions/src/types/conditions';
import { timestampFromString } from '@/utilities/Formats';
export interface Conditions {
  jobType: string[];
  startDate?: string | null;
  workingTime: string[];
  duration: string[];
  nationality?: string;
  city?: City.City;
  level: string[];
  desiredSalary?: number;
  vacationDays?: number;
  benefits?: string[];
  areas: Area.AreaItem[];
}

export const fromDto = (dto: ConditionsDto): Conditions => ({
  ...dto,
  startDate: dto.startDate ? timestampToISOString(dto.startDate) : undefined,
  areas: dto.areas ? dto.areas.map(Area.fromDto) : [],
  city: dto.city ? City.fromDto(dto.city) : undefined,
  level: dto.level ?? [],
  vacationDays: dto.vacationDays && dto.vacationDays > 3 ? dto.vacationDays : 0,
});

export const toDto = (conditions: Conditions): ConditionsDto => ({
  ...conditions,
  city: conditions.city ? City.toDto(conditions.city) : undefined,
  areas: conditions.areas?.map(Area.toDto),
  startDate: timestampFromString(conditions.startDate),
  vacationDays:
    conditions.vacationDays && conditions.vacationDays > 3
      ? conditions.vacationDays
      : 0,
});
