import { AnyKeyObject } from '.';
import { rawAreaCategories } from '../assets/selections/areas';

export interface AreaCategoryDto {
  id: string;
  name: string;
  items: AreaItemDto[];
}

export interface AreaItemDto {
  id: string;
  name: string;
  durationDays?: number;
  areaCategory?: AreaCategoryDto;
}

export const getAreaById = (id: string) => {
  const areas = rawAreaCategories
    .map((areaCategory) => areaCategory.items)
    .flat(1);
  return areas.find((area) => area.id === id);
};
export const getAreaCategoryById = (id: string) => {
  return rawAreaCategories.find((area) => area.id === id);
};

export const fromData = (data: AnyKeyObject) => {
  // check if area is present in current selection
  // in case the selection options were changed after user
  // selected an area
  const area = getAreaById(data.id);
  if (!area) return;

  // check if area category is present in current selection
  // in case the selection options were changed after user
  // selected an area in a category
  const areaCategory = getAreaCategoryById(data.areaCategory.id);
  if (!areaCategory) return;

  const areaItem: AreaItemDto = {
    id: data.id,
    name: area.name, // always use the current name defined in the possible selections
    durationDays: data.durationDays,
    areaCategory: { ...data.areaCategory, name: areaCategory?.name }, // always use the current name defined in the possible selections
  };

  return areaItem;
};
