import { auth, messagesCollection, serverTimestamp } from '@/firebase';

import * as Message from '../../../functions/src/types/message';

export const list = (matchId: string) =>
  messagesCollection(matchId)
    .where('userIds', 'array-contains', auth.currentUser?.uid)
    .orderBy('sentAt', 'asc')
    .get()
    .then((querySnapshot) => querySnapshot.docs.map(Message.fromFirestore));

export const subscribe = (
  matchId: string,
  callback: (messages: Message.MessageDto[]) => void
) =>
  messagesCollection(matchId)
    .where('userIds', 'array-contains', auth.currentUser?.uid)
    .orderBy('sentAt', 'asc')
    .onSnapshot((querySnapshot) =>
      callback(querySnapshot.docs.map(Message.fromFirestore))
    );

export const add = (matchId: string, message: Partial<Message.MessageDto>) =>
  messagesCollection(matchId)
    .add({
      ...message,
      userIds: [message.senderId],
      sentAt: serverTimestamp,
      notifiedRecipient: false,
      seen: false,
    })
    .then((ref) => get(matchId, ref.id));

export const update = (
  matchId: string,
  messageId: string,
  message: Partial<Message.MessageDto>
) =>
  messagesCollection(matchId)
    .doc(messageId)
    .update({ ...message, updatedAt: serverTimestamp })
    .then(() => get(matchId, messageId));

export const get = (matchId: string, messageId: string) =>
  messagesCollection(matchId).doc(messageId).get().then(Message.fromFirestore);
