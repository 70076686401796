import { FieldValue, settingsNotificationsDoc } from '@/firebase';
import {
  NotificationCategory,
  NotificationType,
} from '@/types/notificationSettings';
import * as NotificationSettings from '../../../functions/src/types/notificationSettings';

export const fetchSettings = async (uid: string) =>
  settingsNotificationsDoc(uid)
    .get()
    .then(NotificationSettings.fromFirestore);
export const enableNotification = (
  uid: string,
  category: NotificationCategory,
  type: NotificationType
) => {
  return settingsNotificationsDoc(uid)
    .set(
      {
        [`${type}`]: FieldValue.arrayUnion(category),
      },
      { merge: true }
    )
    .then(() => fetchSettings(uid));
};
export const disableNotification = (
  uid: string,
  category: NotificationCategory,
  type: NotificationType
) =>
  settingsNotificationsDoc(uid)
    .set(
      {
        [`${type}`]: FieldValue.arrayRemove(category),
      },
      { merge: true }
    )
    .then(() => fetchSettings(uid));

export const setFcmToken = (uid: string, token: string) =>
  settingsNotificationsDoc(uid).set(
    { fcmTokens: FieldValue.arrayUnion(token) },
    { merge: true }
  );

export const removeFcmToken = (uid: string, token: string) =>
  settingsNotificationsDoc(uid).set(
    { fcmTokens: FieldValue.arrayRemove(token) },
    { merge: true }
  );
