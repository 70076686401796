import { RouteConfig } from 'vue-router';

export const routesBusiness: Array<RouteConfig> = [
  {
    path: '/b/login',
    name: 'LoginBusiness',
    component: () =>
      import(
        /* webpackChunkName: "register" */ '../views/auth/business/Login.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: 'auth',
      userRole: 'business',
    },
  },
  {
    path: '/b/register',
    name: 'registerBusiness',
    component: () =>
      import(
        /* webpackChunkName: "register" */ '../views/auth/business/Register.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: 'auth',
      userRole: 'business',
    },
  },
  {
    path: '/b/verify',
    name: 'verifyBusiness',
    component: () =>
      import(
        /* webpackChunkName: "verify" */ '../views/auth/business/Verify.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: 'auth',
      userRole: 'business',
    },
  },
  {
    path: '/b/dashboard',
    name: 'DashboardBusiness',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '../views/business/Dashboard.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/onboarding',
    name: 'OnboardingBusiness',
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */ '../views/business/Onboarding.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'clear',
      userRole: 'business',
    },
  },
  {
    path: '/b/matches',
    name: 'MatchesBusiness',
    component: () =>
      import(/* webpackChunkName: "matches" */ '../views/business/Matches.vue'),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/matches/:mid',
    name: 'MatchBusiness',
    component: () =>
      import(
        /* webpackChunkName: "match" */ '../views/business/MatchDetails.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/nachrichten/:mid',
    name: 'MessageMatchBusiness',
    component: () =>
      import(
        /* webpackChunkName: "match" */ '../views/business/MatchDetails.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/wir-bieten',
    name: 'OfferingsBusiness',
    component: () =>
      import(
        /* webpackChunkName: "offerings" */ '../views/business/Offerings.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/wir-suchen',
    name: 'ConditionsBusiness',
    component: () =>
      import(
        /* webpackChunkName: "conditions" */ '../views/business/LookingFor.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/nachrichten',
    name: 'MessagesBusiness',
    component: () =>
      import(
        /* webpackChunkName: "messages" */ '../views/business/Messages.vue'
      ),
    meta: {
      requiresAuth: true,
      layout: 'secure',
      userRole: 'business',
    },
  },
  {
    path: '/b/agb',
    name: 'TermsBusiness',
    beforeEnter() {
      window.open('https://stairship.com/agb-unternehmen', '_blank');
    },
    meta: {
      userRole: 'default',
    },
  },
  {
    path: '/b/faq',
    name: 'FaqBusiness',
    beforeEnter() {
      window.open('https://stairship.com/faq-unternehmen', '_blank');
    },
    meta: {
      userRole: 'default',
    },
  },
];
